import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }; // Update state to trigger error handling UI
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        });
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ hasError: false, error: null, errorInfo: null });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div style={styles.container}>
                        <div style={styles.card}>
                            <h1 style={styles.title}>Something Went Wrong</h1>
                            <p style={styles.message}>
                                We're sorry, but something went wrong while loading the page. Please try refreshing the page or come back later.
                            </p>
                            <p style={styles.supportMessage}>
                                If the problem persists, please contact our support team at <a href="mailto:support@ezybill.in">support@ezybill.in</a>.
                            </p>
                            <button style={styles.button} onClick={() => window.location.href = "/"}>
                                Return to Home
                            </button>
                        </div>
                    </div>
                </>
            )
        }

        // If no error, render children normally
        return this.props.children;
    }
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '0 20px',
    },
    card: {
        backgroundColor: '#fff',
        padding: '40px 60px',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        width: '100%',
        maxWidth: '500px',
        border: '1px solid #ddd',
    },
    title: {
        fontSize: '32px',
        color: '#333',
        marginBottom: '20px',
    },
    message: {
        fontSize: '18px',
        color: '#555',
        marginBottom: '30px',
    },
    link: {
        textDecoration: 'none',
    },
    button: {
        padding: '12px 24px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    supportMessage: {
        marginBottom: '10px'
    }
};

export default withRouter(ErrorBoundary);
