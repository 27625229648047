import { Grid, useTheme } from "@material-ui/core";
import AppIcon from "../../UI/Controls/AppIcons";
import { PageHeader } from "../../UI/Controls/Controls";
import AdvancePaymentReturn from "../AdvancePaymentReturn";

export default function AdvanceRefund({ referenceType }) {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                    <PageHeader heading={`Return ${referenceType} Advance`}>
                        <AppIcon name="RupeeIcon" color={theme.palette.primary.main} />
                    </PageHeader>
                </Grid>
            </Grid>
            <AdvancePaymentReturn referenceType={referenceType} returnOrderId={0} isRefund={false} />
        </>
    )
};
