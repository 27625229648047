import create from 'zustand'
import { getFilterDate, getFinancialEndDate, getFinancialStartDate, getISODate, getStartOfMonth, getTodaysDate } from './Utils';

const initialState = {
    notificationCount: 0,
    pushNotification: 0,
    waiting: false,
    isDialogOpen: false,
    currentDialog: '',
    dialogData: null,
    dialogResponse: null,
    appVersionCheck: false,
    alertOpen: false,
    alertMessage: '',
    alertType: 'error',
    alertPosition: 'bottom',
    couponSpecialCode: "",
    alertDelay: 6000,
    alertLink: '',
    alertLinkCaption: 'Click here',
    isHelpDrawerOpen: false,
    isSearchDrawerOpen: false,
    isInfoDrawerOpen: false,
    isSettingsDrawerOpen: false,
    isMobileDrawerOpen: false,
    isQuickAddOrderOpen: false,
    isInvoiceFullScreenToggle: false,
    displayDataGrid: false,
    companyProfileTab: false,
    selectedOrder: {},
    selectedInvoice: {},
    selectedQuotation: {},
    selectedPartyName: {},
    selectedParty: {},
    paymentData: {},
    selectedBill: {},
    selectedReturn: {},
    selectedEntity: {},
    selectedDeliveryChallan: {},
    showPayment: false,
    showSalesOffer: false,
    selectedSalesOrder: {},
    selectedPurchaseOrder: {},
    inventoryByDateWarehouse: {},
    paymentLinkFilter: { fromDate: getStartOfMonth(), toDate: getTodaysDate() },
    orderFullFillMentFilter: { fromDate: getStartOfMonth(), toDate: getTodaysDate() },
    salesByEmployeeFilter: { fromDate: getStartOfMonth(), toDate: getTodaysDate() },
    pickListFilter: { fromDate: getTodaysDate(), toDate: getTodaysDate() },
    searchFilter: { fromDate: getFinancialStartDate(), toDate: getISODate(), productId: 0, serialNumber: "", orderNumber: "", productCode: "", productDescription: "" },
    filter: { fromDate: getStartOfMonth(), toDate: getISODate(), productId: 0, productCategoryId: 0 },
    razorScriptLoaded: false,
    ordersByProductFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate(), productId: 0, productCategoryId: 0, warehouseId: 0 },
    invoiceFilter: { fromDate: null, toDate: null },
    salesByGroupFilter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    paymentsInFilter: { fromDate: getFilterDate(-30), toDate: getISODate(), referenceId: 0 },
    paymentsOutFilter: { fromDate: getFilterDate(-30), toDate: getISODate(), referenceId: 0 },
    quotationFilter: { fromDate: null, toDate: null },
    deliveryChallanFilter: { fromDate: null, toDate: null },
    purchaseFilter: { fromDate: null, toDate: null },
    purchaseOrderFilter: { fromDate: null, toDate: null },
    returnsFilter: { fromDate: null, toDate: null },
    salesOrderFilter: { fromDate: null, toDate: null },
    ewayFilter: { fromDate: null, toDate: null },
    eInvoiceFilter: { fromDate: null, toDate: null },
    expenseFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    incomeFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    salesRegisterFilter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    purchaseRegisterFilter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    itemWiseSalesFilter: { fromDate: getStartOfMonth(), toDate: getISODate(), referenceId: 0, referenceType: 'Customer' },
    itemWisePurchaseFilter: { fromDate: getStartOfMonth(), toDate: getISODate(), referenceId: 0, referenceType: 'Supplier' },
    gstr1Filter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    stockAdjustmentFilter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    accountChartFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    tradingAccountFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    balanceSheetFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    transactionFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate() },
    salesByProductFilter: { fromDate: getStartOfMonth(), toDate: getISODate() },
    customerLedgerFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate(), referenceId: 0 },
    supplierLedgerFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate(), referenceId: 0 },
    salesByCustomerFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate(), referenceId: 0 },
    purchaseBySupplierFilter: { fromDate: getFinancialStartDate(), toDate: getFinancialEndDate(), referenceId: 0 },
    customerTransactionsFilter: { fromDate: getFilterDate(-30), toDate: getISODate(), referenceId: 0 },
    supplierTransactionsFilter: { fromDate: getFilterDate(-30), toDate: getISODate(), referenceId: 0 },
    orderListFilter: {
        invoiceFromDate: getStartOfMonth(), invoiceToDate: getISODate(), invoiceReferenceId: 0, invoiceEmployeeId: 0, invoiceProjectId: 0,
        quotationFromDate: getStartOfMonth(), quotationToDate: getISODate(), quotationReferenceId: 0,
        salesOrderFromDate: getStartOfMonth(), salesOrderToDate: getISODate(), salesOrderReferenceId: 0,
        deliveryChallanFromDate: getStartOfMonth(), deliveryChallanToDate: getISODate(), deliveryChallanReferenceId: 0,
        creditNoteFromDate: getStartOfMonth(), creditNoteToDate: getISODate(), creditNoteReferenceId: 0,
        purchaseBillFromDate: getStartOfMonth(), purchaseBillToDate: getISODate(), purchaseBillReferenceId: 0, purchaseBillEmployeeId: 0, purchaseBillProjectId: 0,
        purchaseOrderFromDate: getStartOfMonth(), purchaseOrderToDate: getISODate(), purchaseOrderReferenceId: 0,
        debitNoteFromDate: getStartOfMonth(), debitNoteToDate: getISODate(), debitNoteReferenceId: 0,
    },
    selectedUser: {},
    hsnCodeListData: {},
    selectedUOM: {},
    auditDetails: null,
    isAuditDrawerOpen: false,
    populateOriginalOrderItems: false,
    orderFilterValues: null,
    barcodeValues: {
        templateId: 0,
        barcodeTitle: '',
        barcodeNote: '',
        selectedProduct: null
    },
    customFieldModule: ""
}
const useStore = create((set, get) => ({
    ...initialState,
    setShowSalesOffer: (val) => set(state => ({ showSalesOffer: val })),
    setShowPayment: (val) => set(state => ({ showPayment: val })),
    setPopulateOriginalOrderItems: (val) => set(state => ({ populateOriginalOrderItems: val })),
    setNotificationCount: (val) => set(state => ({ notificationCount: val })),
    setCouponSpecialCode: (val) => set(state => ({ couponSpecialCode: val })),
    setPushNotification: (val) => set(state => ({ pushNotification: val })),
    setAppVersionCheck: (val) => set(state => ({ appVersionCheck: val })),
    setWaiting: (val) => set(state => ({ waiting: val })),
    openDialog: (dialogName = '') => set(state => ({ isDialogOpen: true, currentDialog: dialogName })),
    closeDialog: () => set(state => ({ isDialogOpen: false, currentDialog: '' })),
    onDialogClose: (responseData) => (e, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            return;
        }
        set({ dialogResponse: responseData })
    },
    setDialogData: (req) => set(state => ({ dialogData: req })),
    setDialogResponse: (responseData) => set(state => ({ dialogResponse: responseData })),
    showAlert: (message, severity, position = 'top', delay = 6000, link = '', linkCaption = '') => set(state => ({ alertOpen: true, alertMessage: message, severity: severity, alertPosition: position, alertDelay: delay, alertLink: link, alertLinkCaption: linkCaption })),
    closeAlert: (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        set({ alertOpen: false })
    },
    toggleHelpDrawer: (isOpen) => set(state => ({ isHelpDrawerOpen: isOpen })),
    toggleSearchDrawer: (isOpen) => set(state => ({ isSearchDrawerOpen: isOpen })),
    toggleInfoDrawer: (isOpen) => set(state => ({ isInfoDrawerOpen: isOpen })),
    toggleSettingsDrawer: (isOpen) => set(state => ({ isSettingsDrawerOpen: isOpen })),
    toggleMobileDrawer: (isOpen) => set(state => ({ isMobileDrawerOpen: isOpen })),
    toggleQuickAddOrderDrawer: (isOpen) => set(state => ({ isQuickAddOrderOpen: isOpen })),
    toggleInvoiceScreen: (isOpen) => set(state => ({ isInvoiceFullScreenToggle: isOpen })),
    toggleDisplayDataGrid: (isOpen) => set(state => ({ displayDataGrid: isOpen })),
    toggleCompanyProfileTab: (isOpen) => set(state => ({ companyProfileTab: isOpen })),
    setSelectedOrder: (order) => set(state => ({ selectedOrder: order })),
    setSelectedInvoice: (invoice) => set(state => ({ selectedInvoice: invoice })),
    setSelectedQuotation: (quotation) => set(state => ({ selectedQuotation: quotation })),
    setSelectedSalesOrder: (salesorder) => set(state => ({ selectedSalesOrder: salesorder })),
    setSelectedPurchaseOrder: (purchaseorder) => set(state => ({ selectedPurchaseOrder: purchaseorder })),
    setInventoryByDateWarehouse: (warehouse) => set(state => ({ selectedInventoryByDateWarehouse: warehouse })),
    setSelectedPartyName: (partyName) => set(state => ({ selectedPartyName: partyName })),
    setSelectedParty: (party) => set(state => ({ selectedParty: party })),
    disposeStore: () => set({}, true), // clears the entire store, actions included    
    setPaymentData: (payment) => set(state => ({ paymentData: payment })),
    setSelectedBill: (bill) => set(state => ({ selectedBill: bill })),
    setSelectedReturn: (returnDetail) => set(state => ({ selectedReturn: returnDetail })),
    setSelectedEntity: (entity) => set(state => ({ selectedEntity: entity })),
    setSelectedDeliveryChallan: (dc) => set(state => ({ selectedDeliveryChallan: dc })),

    setSalesByEmployeeFilter: (f) => set(state => ({ salesByEmployeeFilter: f })),
    setPaymentLinkFilter: (f) => set(state => ({ paymentLinkFilter: f })),
    setOrderFullFillMentFilter: (f) => set(state => ({ orderFullFillMentFilter: f })),
    setPickListFilter: (f) => set(state => ({ pickListFilter: f })),
    setSearchFilter: (f) => set(state => ({ searchFilter: f })),
    setFilter: (f) => set(state => ({ filter: f })),
    setRazorScriptLoaded: (isLoaded) => set(state => ({ razorScriptLoaded: isLoaded })),
    setOrdersByProductFilter: (f) => set(state => ({ ordersByProductFilter: f })),
    setOrderListFilter: (f) => set(state => ({ orderListFilter: f })),
    setInvoiceFilter: (f) => set(state => ({ invoiceFilter: f })),
    setSalesByGroupFilter: (f) => set(state => ({ salesByGroupFilter: f })),
    setPaymentsInFilter: (f) => set(state => ({ paymentsInFilter: f })),
    setPaymentsOutFilter: (f) => set(state => ({ paymentsOutFilter: f })),
    setQuotationFilter: (f) => set(state => ({ quotationFilter: f })),
    setDeliveryChallanFilter: (f) => set(state => ({ deliveryChallanFilter: f })),
    setPurchaseFilter: (f) => set(state => ({ purchaseFilter: f })),
    setPurchaseOrderFilter: (f) => set(state => ({ purchaseOrderFilter: f })),
    setReturnsFilter: (f) => set(state => ({ returnsFilter: f })),
    setSalesOrderFilter: (f) => set(state => ({ salesOrderFilter: f })),
    setEwayFilter: (f) => set(state => ({ ewayFilter: f })),
    setEInvoiceFilter: (f) => set(state => ({ eInvoiceFilter: f })),
    setExpenseFilter: (f) => set(state => ({ expenseFilter: f })),
    setIncomeFilter: (f) => set(state => ({ incomeFilter: f })),
    setSalesRegisterFilter: (f) => set(state => ({ salesRegisterFilter: f })),
    setPurchaseRegisterFilter: (f) => set(state => ({ purchaseRegisterFilter: f })),
    setItemWiseSalesFilter: (f) => set(state => ({ itemWiseSalesFilter: f })),
    setItemWisePurchaseFilter: (f) => set(state => ({ itemWisePurchaseFilter: f })),
    setGSTR1Filter: (f) => set(state => ({ gstr1Filter: f })),
    setStockAdjustmentFilter: (f) => set(state => ({ stockAdjustmentFilter: f })),
    setAccountChartFilter: (f) => set(state => ({ accountChartFilter: f })),
    setTradingAccountFilter: (f) => set(state => ({ tradingAccountFilter: f })),
    setBalanceSheetFilter: (f) => set(state => ({ balanceSheetFilter: f })),
    setTransactionFilter: (f) => set(state => ({ transactionFilter: f })),
    setSalesByProductFilter: (f) => set(state => ({ salesByProductFilter: f })),
    setCustomerLedgerFilter: (f) => set(state => ({ customerLedgerFilter: f })),
    setSupplierLedgerFilter: (f) => set(state => ({ supplierLedgerFilter: f })),
    setSalesByCustomerFilter: (f) => set(state => ({ salesByCustomerFilter: f })),
    setPurchaseBySupplierFilter: (f) => set(state => ({ purchaseBySupplierFilter: f })),
    setCustomerTransactionsFilter: (f) => set(state => ({ customerTransactionsFilter: f })),
    setSupplierTransactionsFilter: (f) => set(state => ({ supplierTransactionsFilter: f })),
    setSelectedUser: (val) => set(state => ({ selectedUser: val })),
    setHSNCodeListData: (val) => set(state => ({ hsnCodeListData: val })),
    setSelectedUOM: (val) => set(state => ({ selectedUOM: val })),
    setAuditDetails: (val) => set(state => ({ auditDetails: val })),
    toggleAuditDrawer: (isOpen) => set(state => ({ isAuditDrawerOpen: isOpen })),
    setOrderFilterValues: (val) => set(state => ({ orderFilterValues: val })),
    setBarcodeValues: (val) => set(state => ({ barcodeValues: val })),
    setCustomFieldModule: (m) => set(state => ({ customFieldModule: m })),

    // Resets the store to initial values, called when logout is clicked
    resetStore: () => set(state => (
        initialState
    ))
}));

export default useStore;
