import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AuthContext from '../Auth/AuthContext';
import { APP_MODULE_BankAccounts, APP_MODULE_Bills, APP_MODULE_CompositeItem, APP_MODULE_CreditNotes, APP_MODULE_CustomerGroups, APP_MODULE_Customers, APP_MODULE_DebitNotes, APP_MODULE_DeliveryChallans, APP_MODULE_Employee, APP_MODULE_Expenses, APP_MODULE_Invoices, APP_MODULE_ItemGroup, APP_MODULE_Items, APP_MODULE_Payables, APP_MODULE_PurchaseOrders, APP_MODULE_Quotations, APP_MODULE_Receivables, APP_MODULE_SalesOrders, APP_MODULE_StockInventory, APP_MODULE_Suppliers } from '../Base/Common';
import UCIconButton from '../UI/Controls/UCIconButton';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

import ScanQR from '../../images/QRQuickAdd.png';
import AccountRecive from '../../images/Accounts-Receivable-quickadd.png';
import AccountPayable from '../../images/Account-Payable-quickadd.png';
import DialogWindow from '../UI/Controls/DialogWindow';
import NewPaymentLink from '../Payments/PaymentLinks/NewPaymentLink';
import useStore from '../Base/Store';
import shallow from 'zustand/shallow';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        textAlign: 'center',
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    card: {
        width: 45,
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: theme.palette.primary.main, // Adjust this to a suitable color
    },
    name: {
        marginTop: theme.spacing(1),
        fontFamily: 'roboto',
        fontSize: '11px',
        fontWeight: 'bold',
        color: theme.palette.secondary.light, // Ensure text is readable against the background
    },
    mutedTextList: {
        color: theme.palette.text.secondary,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subsectionContainer: {
        padding: '10px 0px 10px 0px',
        margin: '10px 0px',
        borderRadius: '10px',
    }
}));

const QuickAddMobile = ({ isPathChangeCloseDialog }) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const history = useHistory();

    function menuHeadingClickHandler(e) {
        if (e.currentTarget.attributes.path.value === "requestPayment") {
            openDialog('requestPaymentDialog');

        } else {
            history.push("/" + e.currentTarget.attributes.path.value);
            isPathChangeCloseDialog();
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='body1' style={{ padding: '0px 0px 15px 5px', color: 'white' }}>
                        SALES
                    </Typography>
                    <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newinvoice"
                                    iconName="InvoiceIcon"
                                    iconColor="#0FCF82"
                                    caption="Invoice"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Invoices).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="customer"
                                    iconName="CustomerIcon"
                                    iconColor="#0FCF82"
                                    caption="Customer"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Customers).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newquotation"
                                    iconName="QuotationIcon"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(0, 206, 209,.18)"
                                    caption="Quotation"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Quotations).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newsalesorder"
                                    iconName="SalesOrder"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(0, 206, 209,.18)"
                                    caption="Sales Order"
                                    disabled={!authContext.moduleAccess(APP_MODULE_SalesOrders).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop="10px"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="selectparty/Customer"
                                    iconName="RupeeIcon"
                                    iconColor="#4CAF50"
                                    iconBackground="rgba(76, 175, 80,.18)"
                                    caption="Receive Payment"
                                    isImage={true}
                                    imageName={AccountRecive}
                                    disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="xl"
                                    iconMarginTop="10px"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="receiveadvance"
                                    iconName="RupeeIcon"
                                    iconColor="#4CAF50"
                                    iconBackground="rgba(76, 175, 80,.18)"
                                    caption="Receive Advance"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop="10px"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="requestPayment"
                                    iconName=""
                                    iconColor="#4CAF50"
                                    iconBackground="rgba(76, 175, 80,.18)"
                                    caption="Payment Request"
                                    isImage={true}
                                    imageName={ScanQR}
                                    disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                    iconSize="cc"
                                    handleClick={menuHeadingClickHandler}
                                    iconMarginTop="10px"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newdeliverychallan"
                                    iconName="DeliveryChallan"
                                    iconColor="#00CED1"
                                    caption="Delivery challan"
                                    disabled={!authContext.moduleAccess(APP_MODULE_DeliveryChallans).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant='body1' style={{ padding: '0px 0px 15px 5px', color: 'white' }}>
                        PURCHASE
                    </Typography>
                    <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newpurchasebill"
                                    iconName="PurchaseIcon"
                                    iconColor="#1ABCFD"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption=" Purchase bill"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Bills).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="supplier"
                                    iconName="Supplier"
                                    iconColor="#1ABCFD"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Vendor/ Supplier"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Suppliers).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newpurchaseorder"
                                    iconName="PurchaseOrderIcon"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(0, 206, 209,.18)"
                                    caption="Purchase Order"
                                    disabled={!authContext.moduleAccess(APP_MODULE_PurchaseOrders).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="expense"
                                    iconName="Expense"
                                    iconColor="#F44336"
                                    iconBackground="rgba(244, 67, 54,.18)"
                                    caption="Expense"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Expenses).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="selectparty/Supplier"
                                    iconName="RupeeIcon"
                                    iconColor="#F44336"
                                    iconBackground="rgba(244, 67, 54,.18)"
                                    caption="Make Payment"
                                    isImage={true}
                                    imageName={AccountPayable}
                                    disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop="10px"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="payadvance"
                                    iconName="RupeeIcon"
                                    iconColor="#F44336"
                                    iconBackground="rgba(244, 67, 54,.18)"
                                    caption="Pay Advance"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop="10px"
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant='body1' style={{ padding: '0px 0px 15px 5px', color: 'white' }}>
                        STOCK/INVENTORY
                    </Typography>
                    <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="product"
                                    iconName="ProductsIcon"
                                    iconColor="#A259FF"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Item/ Product"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Items).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="productgroup"
                                    iconName="ItemGroup"
                                    iconColor="#A259FF"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Item Group"
                                    disabled={!authContext.moduleAccess(APP_MODULE_ItemGroup).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop='9px'
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="compositeItem"
                                    iconName="CompositeItem"
                                    iconColor="#A259FF"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Composite Item"
                                    disabled={!authContext.moduleAccess(APP_MODULE_CompositeItem).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="stockadjustment"
                                    iconName="Adjustments"
                                    iconColor="#FF7261"
                                    iconBackground="rgba(255, 140, 0,.18)"
                                    caption=" Stock adjustment"
                                    disabled={!authContext.moduleAccess(APP_MODULE_StockInventory).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' style={{ padding: '0px 0px 15px 5px', color: 'white' }}>
                        MASTERDATA
                    </Typography>
                    <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="bankaccount"
                                    iconName="Bank"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Bank Account"
                                    disabled={!authContext.moduleAccess(APP_MODULE_BankAccounts).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="CustomerGroup"
                                    iconName="CustomerGroup"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Customer Group"
                                    disabled={!authContext.moduleAccess(APP_MODULE_CustomerGroups).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop='9px'
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="expensetype"
                                    iconName="Expense"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="Expense Type"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Expenses).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="Employee"
                                    iconName="Employee"
                                    iconColor="#00CED1"
                                    iconBackground="rgba(32,91,212,.18)"
                                    caption="New Employee"
                                    disabled={!authContext.moduleAccess(APP_MODULE_Employee).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                    iconSize="cc"
                                    iconMarginTop='9px'
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' style={{ padding: '0px 0px 15px 5px', color: 'white' }}>
                        RETURNS
                    </Typography>
                    <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newcreditnote"
                                    iconName="CreditNote"
                                    iconColor="#FF7261"
                                    caption="Credit Note"
                                    disabled={!authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="newdebitnote"
                                    iconName="DebitNote"
                                    iconColor="#FF7261"
                                    caption="Debit Note"
                                    disabled={!authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="customerrefund"
                                    iconName="RupeeIcon"
                                    iconColor="#FF7261"
                                    caption="Return Customer Advance"
                                    disabled={!authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ flexBasis: '25%' }}>
                                <UCIconButton
                                    path="supplierrefund"
                                    iconName="RupeeIcon"
                                    iconColor="#FF7261"
                                    caption="Return Supplier Advance"
                                    disabled={!authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd}
                                    handleClick={menuHeadingClickHandler}
                                />
                            </Grid> */}
                        </div>
                    </Grid>
                </Grid>

            </Grid>
            <DialogWindow name="requestPaymentDialog" open={isDialogOpen}
                width="sm" component={<NewPaymentLink paymentData={null} isCustomerPayment={true} />} />
        </>
    );
};

export default QuickAddMobile;
