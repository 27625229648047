// quotation image 
import ClassicQuotationsFormate from '../../images/Quotation_pdf_formate/classic_qu_1.jpg';
import ClassicWithLogoQuotationsFormate from '../../images/Quotation_pdf_formate/classic_with_logo.jpg';
import CompactQuotationsFormate from '../../images/Quotation_pdf_formate/compact_quo.jpg';
import ElegenetQuotationsFormate from '../../images/Quotation_pdf_formate/elegent_quo.jpg';
import ElegenetWithLogoQuotationsFormate from '../../images/Quotation_pdf_formate/elegent_with_logo.jpg';
import ModernQuotationsFormate from '../../images/Quotation_pdf_formate/modern.jpg';
import ModernLogoRetailQuotationsFormate from '../../images/Quotation_pdf_formate/modern_logo_retail.jpg';
import ModernRetailQuotationsFormate from '../../images/Quotation_pdf_formate/modern_retail.jpg';
import ModernWithLogoQuotationsFormate from '../../images/Quotation_pdf_formate/modern_with_logo.jpg';
import ModernNewLogo from "../../images/Quotation_pdf_formate/custom_1.jpg";

// invoice image

import InvoiceClassic from '../../images/invoice/invoice_classic.jpg';
import InvoiceClassicCompact from '../../images/invoice/classic_compact_invoice.jpg';
import InvoiceClassicWithLogo from "../../images/invoice/invoice_classic_with_logo.jpg"
import InvoiceComfort from '../../images/invoice/comfact_invoice.jpg';
import InvoiceComfortWithLogo from '../../images/invoice/comfort_with_logo_invoice.jpg';
import InvoiceElegent from '../../images/invoice/elegent.jpg';
import InvoiceElegentWithLogo from '../../images/invoice/elegent_with_logo.jpg';
import ModernInvoice from '../../images/invoice/modern_invoice.jpg';
import ModernWithLogoInvoice from '../../images/invoice/modern_with_logo.jpg';
import ModernRetails from '../../images/invoice/modern_retail_invoice.jpg';
import ModernRetailsWithLogo from '../../images/invoice/modern_retail_with_logo.jpg';
import ThermalPrinter from '../../images/invoice/retail_pos_thermal_printer_80mm.jpg';
import ThermalPrinterWithLogo from '../../images/invoice/retail_pos_thermal_printer_80mm_with_logo.jpg';
import ThermalPrinterWithLogoAddress from '../../images/invoice/retail_pos_thermal_printer_80mm_with_logo_address.jpg';
import Professional from '../../images/invoice/Professional.jpg';



// Discount Types
export const DISCOUNT_BEFORE_TAX = 1;
export const DISCOUNT_AFTER_TAX = 2;
export const DATE_BETWEEN = 15

// TaxRegistrationType constants
export const TAX_REG_REGULAR = 1;
export const TAX_REG_COMPOSITION_SCHEME = 2;
export const TAX_REG_UNREGISTERED = 3;
export const TAX_REG_CONSUMER = 4;
export const TAX_REG_OVERSEAS = 5;
export const TAX_REG_SEZ = 6;
export const TAX_REG_DEEMED_EXPORT = 7;
export const TAX_REG_TAX_DEDUCTOR = 8;
export const TAX_REG_SEZ_DEVELOPER = 9;

export const APPLICATION_ID_GANIT = 1;
export const OPTIONS_LIMIT = 100;

// Entity constants
export const ADDRESS_ENTITY = "Address";
export const COST_CENTER_ENTITY = "CostCenter";
export const CREDIT_NOTE_ENTITY = "CreditNote";
export const CUSTOMER_ENTITY = 'Customer';
export const DEBIT_NOTE_ENTITY = "DebitNote";
export const DELIVERY_CHALLAN_ENTITY = "DeliveryChallan";
export const INVOICE_ENTITY = 'Invoice';
export const SALES_ORDER_ENTITY = 'SalesOrder';
export const ITEM_ENTITY = 'Item';
export const MASTER_DATA_ENTITY = 'MasterData';
export const PRODUCT_ENTITY = 'Product';
export const PURCHASE_BILL_ENTITY = 'PurchaseBill';
export const PURCHASE_ORDER_ENTITY = 'PurchaseOrder';
export const QUOTATION_ENTITY = 'Quotation';
export const STOCK_ADJUSTMENT_ENTITY = 'StockAdjustment';
export const SUBSCRIBER_ENTITY = 'Subscriber';
export const SUPPLIER_ENTITY = 'Supplier';
export const USERS_ENTITY = 'Users';
export const WAREHOUSE_ENTITY = 'Warehouse';
export const EXPENSE_ENTITY = 'Expenses';
export const EXPENSE_TYPE_ENTITY = 'ExpensesTypes';
export const BANK_DETAILS_ENTITY = 'BankDetails';
export const REPORT_ENTITY = 'Reports';
export const INVENTORY_ENTITY = 'Inventory';
export const ALETRS_ENTITY = 'Alerts';
export const CHEQUE_TEMPLATE_ENTITY = 'ChequeTemplate';
export const GENERIC_TEMPLATE_ENTITY = 'GenericTemplate';
export const COUPONS_ENTITY = 'Coupons';
export const PAYMENT_LINK_ENTITY = "PaymentLink";

export const RECEIVABLES_ENTITY = 'Receivables';
export const PAYABLES_ENTITY = 'Payables';
export const PAYMENT_ENTITY = 'Payments';
export const EMPLOYEE_ENTITY = 'Employee';
export const GL_ENTITY = "GL"
export const TRANSACTION_ENTITY = "Transactions"

export const PROJECT_ENTITY = "Project";

export const REGEX_EMAIL_MULTI = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-\.]))))*$/gm;
export const REGEX_PHONE_MULTI = /^[0-9, ]*$/gm;
export const REGEX_POSTIVE_NUMBER = /^[0-9]*$/gm;
export const REGEX_ALPHA_NUMERIC = /^[a-zA-Z0-9, ]*$/gm;
export const REGEX_DECIMAL_NUMBER = /^\d+(\.\d{1,2})?$/gm;
export const REGEX_URL = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})";
export const REGEX_VALID_DECIMAL = /^\d*(\.\d{0,3})?$/;

export const INVOICE_COPY_HEADINGS = ['(ORIGINAL FOR RECIPIENT)', '(DUPLICATE FOR TRANSPORTER)', '(DUPLICATE FOR SUPPLIER)', '(EXTRA COPY)', '(EXTRA COPY)'];
export const COLOR_PRIMARY = '#3F51B5';

export const ORDER_TYPE_QUOTATION = 1;
export const ORDER_TYPE_SALES_ORDER = 2;
export const ORDER_TYPE_INVOICE = 3;
export const ORDER_TYPE_CR_NOTE = 4;
export const ORDER_TYPE_DELIVERY_CHALLAN = 5;
export const ORDER_TYPE_PURCHASE_ORDER = 6;
export const ORDER_TYPE_PURCHASE_BILL = 7;
export const ORDER_TYPE_DR_NOTE = 8;



export const CURRENCY_MAX_VALUE = 9999999999.99;

export const PAYMENT_CATEGORY_MANUAL = "MANUAL";
export const PAYMENT_CATEGORY_OPENING_BAL = "OPENING BALANCE";
export const PAYMENT_CATEGORY_ADVANCE = "ADVANCE";
export const PAYMENT_CATEGORY_ADJUST_ADVANCE = "ADJUST ADVANCE";
export const PAYMENT_CATEGORY_REFUND = "REFUND";
export const PAYMENT_CATEGORY_ADVANCE_REFUND = "ADVANCE RETURN";
export const PAYMENT_CATEGORY_SYS_PROC = "";



// PDF output type
export const PDF_OUTPUT_URL = 'url';
export const PDF_OUTPUT_BUFFER = 'buffer';
export const PDF_OUTPUT_DOWNLOAD = 'download';
export const PDF_OUTPUT_PRINT = 'print';

// Linked types
export const LINKED_TYPE_PRODUCT_CATEGORY = 'ProductCategory';

//Report names
export const REPORT_ORDERS_PRINT = 'ORDERS';
export const REPORT_STATEMENT_PRINT = 'STATEMENT';
export const REPORT_PENDING_STATEMENT_PRINT = 'PENDING_STATEMENT_PRINT';
export const REPORT_CUSTOMER_GROUP_STATEMENT_PRINT = 'PENDING_STATEMENT_PRINT';
export const REPORT_PAYMENT_RECEIPT_PRINT = 'PAYMENT_RECEIPT_PRINT';
export const REPORT_EXPENSE_RECEIPT_PRINT = 'EXPENSE_RECEIPT_PRINT';


//Alert types
export const ALERT_TYPE_LOW_STOCK = 1;
export const ALERT_TYPE_OUT_OF_STOCK = 2;
export const ALERT_TYPE_CUSTOMER_PAYMENT_DUE = 3;
export const ALERT_TYPE_SUPPPLIER_PAYMENT_DUE = 4;
export const ALERT_TYPE_CREDIT_LIMIT = 5;
export const ALERT_TYPE_CREDIT_DAYS = 6;
export const ALERT_TYPE_ITEMS_EXPIRING = 7;
export const ALERT_TYPE_ITEMS_EXPIRED = 8;
export const ALERT_TYPE_CUSTOM = 9;

export const SUPPLIER_SUB_TYPE_DESC_CODE = "8";

// None = 0,
// LowStock = 1,
// OutOfStock = 2,
// CustomerPaymentDue = 3,
// SupplierPaymentDue = 4,
// CreditLimit = 5,
// CreditDays = 6,
// ItemsExpiring = 7,
// ItemsExpired = 8,
// MyAlerts = 9


//Errors
export const ERROR_INTERNAL_SERVER = 'Failed to process the selected action. Please try again or contact support.';
export const ERROR_NO_RETURNS_FOUND = 'There were no returns found on the selected item/Refund can only be applied on returns (i.e Credit/Debit notes). Please verify your selection.';
export const ERROR_CANNOT_DELETE_BATCH = 'Cannot delete batch payment.';
export const ERROR_INVALID_DECIMAL = 'Invalid value entered. Only upto 3 decimal places allowed.';
export const ERROR_UX_UNHANDLED = 'An un-expected error occurred. Please refresh your screen and retry.';


//Invoice formats
export const INVOICE_FORMAT_CLASSIC = "1";
export const INVOICE_FORMAT_CLASSIC_LOGO = "2";
export const INVOICE_FORMAT_CLASSIC_COMPACT = "12";


export const INVOICE_FORMAT_ELEGANT_LOGO = "3";
export const INVOICE_FORMAT_ELEGANT = "4";

export const INVOICE_FORMAT_RETAIL = "9";
export const INVOICE_FORMAT_RETAIL_LOGO = "10";

export const INVOICE_FORMAT_RETAIL_POS = "13";
export const INVOICE_FORMAT_RETAIL_POS_LOGO = "14"

export const INVOICE_FORMAT_MODERN = "15"
export const INVOICE_FORMAT_MODERN_LOGO = "16"

export const INVOICE_FORMAT_COMFORT_LOGO = "17";
export const INVOICE_FORMAT_COMFORT = "18";

export const INVOICE_FORMAT_RETAIL_POS_NOHEADER = "19";
export const INVOICE_FORMAT_RETAIL_POS_NOHEADER_LOGO = "20";

export const INVOICE_FORMAT_CUSTOM_1 = "21";

export const INVOICE_FORMAT_PROFESSIONAL = "22";

// TO IMPLEMENT
export const INVOICE_FORMAT_DEFAULT = "5";
export const INVOICE_FORMAT_DEFAULT_LOGO = "6";
export const INVOICE_FORMAT_STANDARD = "7";
export const INVOICE_FORMAT_STANDARD_LOGO = "8";
// export const INVOICE_FORMAT_SERVICE = "11";

// App Modules

export const APP_MODULE_Sales = 1;
export const APP_MODULE_Items = 2;
export const APP_MODULE_Purchases = 3;
export const APP_MODULE_MasterData = 4;
export const APP_MODULE_Miscellaneous = 5;
export const APP_MODULE_Accounting = 6;
export const APP_MODULE_Reports = 7;
export const APP_MODULE_Dashboard = 8;
export const APP_MODULE_Customers = 10;
export const APP_MODULE_Quotations = 11;
export const APP_MODULE_Invoices = 12;
export const APP_MODULE_DeliveryChallans = 13;
export const APP_MODULE_CreditNotes = 14;
export const APP_MODULE_Receivables = 15;
export const APP_MODULE_PaymentsReceived = 16;
export const APP_MODULE_SalesOrders = 17;

export const APP_MODULE_EwayBill = 18;
export const APP_MODULE_EInvoicing = 19;

export const APP_MODULE_ItemList = 20;
export const APP_MODULE_StockInventory = 21;
export const APP_MODULE_StockAdjustments = 22;
export const APP_MODULE_ItemGroup = 23;
export const APP_MODULE_CompositeItem = 24;

export const APP_MODULE_Suppliers = 30;
export const APP_MODULE_PurchaseOrders = 31;
export const APP_MODULE_Bills = 32;
export const APP_MODULE_Expenses = 33;
export const APP_MODULE_DebitNotes = 34;
export const APP_MODULE_Payables = 35;
export const APP_MODULE_PaymentsMade = 36;
export const APP_MODULE_BankAccounts = 40;
export const APP_MODULE_CustomerGroups = 41;
export const APP_MODULE_ExpenseTypes = 42;
export const APP_MODULE_ImportMasterData = 43;
export const APP_MODULE_UnitConversion = 44;
export const APP_MODULE_ManageWarehouse = 45;
export const APP_MODULE_MultiUser = 47;

export const APP_MODULE_PrintCheque = 50;
export const APP_MODULE_AccountsChart = 60;
export const APP_MODULE_TradingProfitLoss = 61;

export const APP_MODULE_Employee = 48;


export const PartyBusinessTypeRetail = 1;
export const PartyBusinessTypeWholesale = 2;
export const PartyBusinessTypeExport = 3;

export const MM_TO_POINTS_CONVERSION_FACTOR = 2.835;

export const EWAY_DOC_TYPE_INVOICE = 'INV';
export const EWAY_DOC_TYPE_BILL_OF_SUPPLY = 'BIL';
export const EWAY_DOC_TYPE_BILL_OF_DELIVERY_CHALLAN = 'CHL';


// Inventory types
export const INVENTORY_TYPE_ItemsInStock = 1;
export const INVENTORY_TYPE_LowStock = 2;
export const INVENTORY_TYPE_OutOfStock = 3;
export const INVENTORY_TYPE_BatchWiseStock = 4;
export const INVENTORY_TYPE_BatchesExpired = 5;
export const INVENTORY_TYPE_All = 6;

export const invoiceFormats = [
    {
        name: 'Professional',
        value: INVOICE_FORMAT_PROFESSIONAL,
        image: Professional
    },
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
    {
        name: 'Classic (Compact)',
        value: INVOICE_FORMAT_CLASSIC_COMPACT,
        image: InvoiceClassicCompact
    },
    {

        name: 'Comfort',
        value: INVOICE_FORMAT_COMFORT,
        image: InvoiceComfort
    },
    {

        name: 'Comfort (With logo)',
        value: INVOICE_FORMAT_COMFORT_LOGO,
        image: InvoiceComfortWithLogo
    },
    {

        name: 'Elegant',
        value: INVOICE_FORMAT_ELEGANT,
        image: InvoiceElegent
    },
    {

        name: 'Elegant (With logo)',
        value: INVOICE_FORMAT_ELEGANT_LOGO,
        image: InvoiceElegentWithLogo
    },
    {

        name: 'Modern',
        value: INVOICE_FORMAT_MODERN,
        image: ModernInvoice
    },
    {

        name: 'Modern (With logo)',
        value: INVOICE_FORMAT_MODERN_LOGO,
        image: ModernWithLogoInvoice
    },
    {

        name: 'Modern Retail',
        value: INVOICE_FORMAT_RETAIL,
        image: ModernRetails
    },
    {
        name: 'Modern Retail (With logo)',
        value: INVOICE_FORMAT_RETAIL_LOGO,
        image: ModernRetailsWithLogo
    },
    {
        name: 'Retail - POS Thermal Printer 80mm',
        value: INVOICE_FORMAT_RETAIL_POS,
        image: ThermalPrinter
    },
    {
        name: 'Retail - POS Thermal Printer 80mm (With logo)',
        value: INVOICE_FORMAT_RETAIL_POS_LOGO,
        image: ThermalPrinterWithLogo
    },
    {
        name: 'POS Thermal Printer 80mm (Logo with address)',
        value: INVOICE_FORMAT_RETAIL_POS_NOHEADER,
        image: ThermalPrinterWithLogoAddress
    },
    {
        name: 'Modern (With logo) Custom 1',
        value: INVOICE_FORMAT_CUSTOM_1,
        image: ModernNewLogo
    }
]


export const quotationFormats = [
    {
        name: 'Professional',
        value: INVOICE_FORMAT_PROFESSIONAL,
        image: Professional
    },
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: ClassicQuotationsFormate
    },
    {

        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: ClassicWithLogoQuotationsFormate
    },
    {

        name: 'Classic (Compact)',
        value: INVOICE_FORMAT_CLASSIC_COMPACT,
        image: CompactQuotationsFormate
    },
    {

        name: 'Elegant',
        value: INVOICE_FORMAT_ELEGANT,
        image: ElegenetQuotationsFormate
    },
    {

        name: 'Elegant (With logo)',
        value: INVOICE_FORMAT_ELEGANT_LOGO,
        image: ElegenetWithLogoQuotationsFormate
    },
    {

        name: 'Modern',
        value: INVOICE_FORMAT_MODERN,
        image: ModernQuotationsFormate
    },
    {

        name: 'Modern (With logo)',
        value: INVOICE_FORMAT_MODERN_LOGO,
        image: ModernLogoRetailQuotationsFormate
    },
    {

        name: 'Modern Retail',
        value: INVOICE_FORMAT_RETAIL,
        image: ModernRetailQuotationsFormate
    },
    {
        name: 'Modern Retail (With logo)',
        value: INVOICE_FORMAT_RETAIL_LOGO,
        image: ModernWithLogoQuotationsFormate
    },
    {
        name: 'Modern (With logo) Custom 1',
        value: INVOICE_FORMAT_CUSTOM_1,
        image: ModernNewLogo
    }
]


export const purchaseOrderFormats = [
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
];

export const purchaseBillFormats = [
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
];

export const salesOrderFormats = [
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
];
export const creditNotesFormats = [
    {
        name: 'Professional',
        value: INVOICE_FORMAT_PROFESSIONAL,
        image: Professional
    },
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
];

export const debitNotesFormats = [
    {
        name: 'Professional',
        value: INVOICE_FORMAT_PROFESSIONAL,
        image: Professional
    },
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
]

export const deliveryChallanFormats = [
    {
        name: 'Classic',
        value: INVOICE_FORMAT_CLASSIC,
        image: InvoiceClassic
    },
    {
        name: 'Classic (With logo)',
        value: INVOICE_FORMAT_CLASSIC_LOGO,
        image: InvoiceClassicWithLogo
    },
]

export const DEFAULT_VOUCHER_AVAILABLE_COLUMN_DEFINITION = [
    {
        title: 'Including Tax', field: 'unitPriceIncludingTax', width: 'auto', align: 'right', displayName: 'Unit Price Including', type: "currency", sequence: 7
    },
    {
        title: 'Cess Amount', field: 'cessAmount', width: 'auto', align: 'right', displayName: 'cessAmount', type: "currency", sequence: 12
    },
    {
        title: 'PTR', field: 'ptr', width: 'auto', align: 'right', displayName: 'PTS', type: "currency", sequence: 17
    },
    {
        title: 'PTS', field: 'pts', width: 'auto', align: 'right', displayName: 'PTR', type: "currency", sequence: 18
    },   
    {
        title: 'Mfg. dt', field: 'mfgDate', width: 'auto', align: 'left', displayName: 'Manufacturing Date ', sequence: 13
    },
    {
        title: 'Exp. dt', field: 'expiryDate', width: 'auto', align: 'left', displayName: 'Expiry Date', sequence: 14
    },
    {
        title: 'Batch #', field: 'batchNumber', width: 'auto', align: 'left', displayName: 'Batch Number', sequence: 15
    },
    {
        title: 'Disc.', field: 'discountPercentDisplay', width: 'auto', align: 'center', displayName: 'discountPercentDisplay', sequence: 19
    },
    {
        title: 'MRP', field: 'maximumRetailPrice', width: 'auto', align: 'right', displayName: 'maximumRetailPrice', sequence: 20
    },
    {
        title: 'Item code', field: 'productCode', width: 'auto', align: 'right', displayName: 'productCode', sequence: 21
    },
    {
        title: 'Qty Desc.', field: 'quantityDescription', width: 'auto', align: 'right', displayName: 'quantityDescription', sequence: 22
    },
    {
        title: 'Free Qty.', field: 'freeQuantity', width: 'auto', align: 'right', displayName: 'freeQuantity', sequence: 23
    }
];


export const DEFAULT_VOUCHER_COLUMN_DEFINITION = [
    {
        title: '#', field: 'index', displayName: 'Searial Number', width: 'auto', align: 'left', sequence: 1
    },
    {
        title: 'Name of Product / Service', field: 'description', displayName: "Item Description", align: 'left', sequence: 2,
        secondaryField: 'orderDetailInfoText', width: '*', bold: true, tertiaryField: 'serialNumbers'
    },
    {
        title: 'HSN / SAC', field: 'hsnCode', displayName: 'HSN Code', width: 'auto', align: 'left', sequence: 3
    },
    {
        title: 'Qty', field: 'quantity', width: 'auto', displayName: 'Item Quantity', align: 'center', sequence: 4
    },
    {
        title: 'Unit', field: 'unitOfMeasurement', width: 'auto', displayName: 'Unit Of Measurement', align: 'center', sequence: 5
    },
    {
        title: 'Rate', field: 'unitPrice', width: 'auto', align: 'right', displayName: 'Unit Price Excluding', type: "currency", sequence: 6
    },

    {
        title: 'Taxable Value', field: 'taxableAmount', width: 'auto', displayName: 'Taxable Amount', align: 'right', type: "currency", sequence: 8,
        bold: true
    },

    {
        title: 'GST', field: 'taxRateDisplay', width: 'auto', align: 'center', sequence: 9, displayName: 'Tax Rate Display'
    },
    {
        title: 'Tax Amount', field: 'taxAmount', width: 'auto', align: 'right', type: "currency", sequence: 10, displayName: 'Tax Amount'
    },
    {
        title: 'Total', field: 'lineTotal', width: 'auto', displayName: 'Line Total', align: 'right', type: "currency", sequence: 11,
        bold: true
    },
];

export const DEFAULT_SCREEN = [
    {
        id: 0,
        displayName: "",
        path: ''
    },
    {
        id: 1,
        displayName: "New Invoice",
        path: 'newinvoice'
    },
    {
        id: 2,
        displayName: "New Quotation",
        path: 'newquotation'
    },
    {
        id: 3,
        displayName: "New Sales Order",
        path: 'newsalesorder'
    },
    {
        id: 4,
        displayName: "New Purchase Bill",
        path: 'newpurchasebill'
    },
    {
        id: 5,
        displayName: "Dashboard",
        path: '/'
    },
    {
        id: 6,
        displayName: "Reports",
        path: 'reports'
    },

]

export const DEFAULT_VOUCHER_SETTINGS = {
    "logoWidth": 90,
    "borderColor": "#00259E",
    "borderThickness": 1,
    "fontBold": true,
    "fontMainHeading1": 18,
    "fontMainHeading1Color": "#00259E",
    "fontMainHeading2": 16,
    "fontMainHeading2Color": "#00259E",
    "fontSubHeading1": 14,
    "fontSubHeading1Color": "black",
    "fontSubHeading2": 12,
    "fontSubHeading2Color": "black",
    "fontRegular": 10,
    "fontRegularColor": "black",
    "fontHyperLink": 12,
    "fontHyperLinkColor": "blue",
    "backgroundFillColor": "#e8f3fd",
    "drawPageOutlineBorder": false,
    "pageOrientation": "portrait",
    "itemSize": 10,
    "hideFooter": false,
    "hideHeader": false,
    "headerMargin": 30,
    "footerMargin": 270,
    "repeatFooter": true,
}


export const DEFAULT_QUOTATION_VOUCHER_SETTINGS = {
    "logoWidth": 90,
    "borderColor": "#00259E",
    "borderThickness": 1,
    "fontBold": true,
    "fontMainHeading1": 18,
    "fontMainHeading1Color": "#00259E",
    "fontMainHeading2": 16,
    "fontMainHeading2Color": "#00259E",
    "fontSubHeading1": 14,
    "fontSubHeading1Color": "black",
    "fontSubHeading2": 12,
    "fontSubHeading2Color": "black",
    "fontRegular": 10,
    "fontRegularColor": "black",
    "fontHyperLink": 12,
    "fontHyperLinkColor": "blue",
    "backgroundFillColor": "#e8f3fd",
    "drawPageOutlineBorder": false,
    "pageOrientation": "portrait",
    "itemSize": 10,
    "hideFooter": false,
    "hideHeader": false,
    "headerMargin": 30,
    "footerMargin": 270,
    "repeatFooter": true,

}



export const DEFAULT_CREDITNOTE_VOUCHER_SETTINGS = {
    "logoWidth": 90,
    "borderColor": "#00259E",
    "borderThickness": 1,
    "fontBold": true,
    "fontMainHeading1": 18,
    "fontMainHeading1Color": "#00259E",
    "fontMainHeading2": 16,
    "fontMainHeading2Color": "#00259E",
    "fontSubHeading1": 14,
    "fontSubHeading1Color": "black",
    "fontSubHeading2": 12,
    "fontSubHeading2Color": "black",
    "fontRegular": 10,
    "fontRegularColor": "black",
    "fontHyperLink": 12,
    "fontHyperLinkColor": "blue",
    "backgroundFillColor": "#ffc8cb",
    "drawPageOutlineBorder": false,
    "pageOrientation": "portrait",
    "itemSize": 10,
    "hideFooter": false,
    "hideHeader": false,
    "headerMargin": 30,
    "footerMargin": 270,
    "repeatFooter": true,

}


export const DEFAULT_DEBITNOTE_VOUCHER_SETTINGS = {
    "logoWidth": 90,
    "borderColor": "#00259E",
    "borderThickness": 1,
    "fontBold": true,
    "fontMainHeading1": 18,
    "fontMainHeading1Color": "#00259E",
    "fontMainHeading2": 16,
    "fontMainHeading2Color": "#00259E",
    "fontSubHeading1": 14,
    "fontSubHeading1Color": "black",
    "fontSubHeading2": 12,
    "fontSubHeading2Color": "black",
    "fontRegular": 10,
    "fontRegularColor": "black",
    "fontHyperLink": 12,
    "fontHyperLinkColor": "blue",
    "backgroundFillColor": "#ffc8cb",
    "drawPageOutlineBorder": false,
    "pageOrientation": "portrait",
    "itemSize": 10,
    "hideFooter": false,
    "hideHeader": false,
    "headerMargin": 30,
    "footerMargin": 270,
    "repeatFooter": true,
}