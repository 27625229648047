import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { DateFilter, PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { Form, Formik } from 'formik';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import * as yup from 'yup';
import MuiTable from '../../UI/Controls/MuiTable';
import { ORDER_TYPE_SALES_ORDER, PRODUCT_ENTITY, REPORT_ENTITY } from '../../Base/Common';
import { getDataList } from '../../Base/DataProvider';
import AuthContext from '../../Auth/AuthContext';
import { getLocaleDate, isNullOrUndefined, safeBool, safeInt, safeString } from '../../Base/Utils';
import WebAPI from '../../Base/WebAPI';
import useStyles from '../../../styles';
import { useHistory } from 'react-router-dom';
import { safeFloat } from './../../Base/Utils';
import axios from 'axios';
import { useParams } from "react-router-dom";

const OrderFullfillmentStatus = ({ orderTypeId = ORDER_TYPE_SALES_ORDER, isList = true, selectedOrder = null }) => {
    const [orderFullFillMentFilter, setOrderFullFillMentFilter] = useStore(state => [state.orderFullFillMentFilter, state.setOrderFullFillMentFilter], shallow);
    const [selectedOption, setSelectedOption] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [dublicateTableData, setdublicateTableData] = useState([]);

    const [fieldValues, setFieldValues] = useState({
        fromDate: orderFullFillMentFilter.fromDate,
        toDate: orderFullFillMentFilter.toDate,
    });
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    const [tableData, setTableData] = useState([]);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const showAlert = useStore(state => state.showAlert);

    const pageInfo = {
        pageHeading: 'Order Fullfillment Report',
        pageIcon: 'ReportsIcon',
        cancelButtonCaption: 'Close',
        BackButtonCaption: 'Back',
        entityName: PRODUCT_ENTITY
    }
    const getList = async (values, token = null) => {
        try {
            setWaiting(true);
            setOrderFullFillMentFilter({ fromDate: values.fromDate, toDate: values.toDate });
            let filter = null
            filter = { fromDate: values.fromDate, toDate: values.toDate, orderTypeId: orderTypeId }
            if (!isList) {
                filter = { orderTypeId: orderTypeId, orderId: safeInt(selectedOrder.id) }
            }
            const response = await getDataList(REPORT_ENTITY, isList ? "ListOrderFullfillmentStatus" : "GetOrderFullfillmentStatus", filter, authContext, token, 'put');
            if (!isNullOrUndefined(response)) {
                if (safeInt(response.code) > 0) {
                    showAlert(response.message, 'error');
                } else {
                    setTableData(response);
                    setdublicateTableData(response);
                    if (selectedOption > 0) {
                        const filteredData = response.filter(item => item.referenceId === safeInt(selectedOption));
                        setTableData(filteredData);
                    }
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Report: request cancelled.');
            } else {
                console.log(error);
                showAlert('Failed to load Report. Internal sever error occured.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            await getList(values);
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            let apiUrl = '/Supplier/GetSupplierList/';
            if (orderTypeId === ORDER_TYPE_SALES_ORDER) {
                apiUrl = '/Customer/GetCustomerList/';
            }
            const response = await WebAPI.get(apiUrl + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
            if (!!response.data) {
                if (!!response.data.code && response.data.code > 0) {
                    showAlert(response.data.message, 'error');
                    return;
                }
                setGroupList(response.data);
            }
            await getList(fieldValues, source.token);
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);

    const handleSelectChange = async (event, values) => {
        setTableData([]);
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue === 'all') {
            setTableData(dublicateTableData);
        } else {
            const filteredData = dublicateTableData.filter(item => item.referenceId === safeInt(selectedValue));
            setTableData(filteredData);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={8}>
                    <PageHeader heading={pageInfo.pageHeading}>
                        <AppIcon name={pageInfo.pageIcon} color="primary" />
                    </PageHeader>
                </Grid>
            </Grid>
            <div className={classes.page}>
                {
                    safeBool(isList) ?
                        <>
                            <Grid container alignContent="left">
                                <Grid item xs={12}>
                                    <Formik
                                        initialValues={fieldValues}
                                        validationSchema={yup.object({
                                            fromDate: yup.date().default(new Date()),
                                            toDate: yup
                                                .date()
                                                .when("fromDate", (eventStartDate, schema) => eventStartDate && schema.min(eventStartDate, 'To date cannot be less than from date'))
                                        })}
                                        enableReinitialize={true}
                                        onSubmit={async (values) => { await submitFormData(values) }}
                                    >
                                        {({ values, setValues, setFieldValue, isSubmitting }) => (
                                            <>
                                                <Form>
                                                    <Grid container spacing={2}>
                                                        {!isNullOrUndefined(safeString(orderTypeId)) && (
                                                            <Grid item xs={12} sm={3}>
                                                                <FormControl variant="outlined" fullWidth size='small' style={{ marginTop: '7px' }}>
                                                                    <InputLabel id="demo-simple-select-outlined-label">Select {orderTypeId === ORDER_TYPE_SALES_ORDER ? "Customer" : "Supplier"}</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={selectedOption}
                                                                        onChange={(e) => handleSelectChange(e, values)}
                                                                        label={`Select ${orderTypeId === ORDER_TYPE_SALES_ORDER ? "Customer" : "Supplier"}`}
                                                                    >
                                                                        <MenuItem value="all">All</MenuItem>
                                                                        {groupList?.map(item => (
                                                                            <MenuItem key={item.id} value={item.id}>
                                                                                {orderTypeId === ORDER_TYPE_SALES_ORDER ? item.legalName : item.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        )}

                                                        <Grid item xs={12} sm={9}>
                                                            <DateFilter />
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </> : null
                }


                <Grid container spacing={1} className={`${classes.mt1}`}>
                    <Grid item xs={12} >
                        <MuiTable columns={[
                            { title: 'Party Name', field: 'referenceName', disableClick: true },
                            { title: 'Order Number', field: 'orderNumber', disableClick: true },
                            {
                                title: 'Order Date', field: 'orderDate', disableClick: true,
                                render: rowData => <span>{getLocaleDate(rowData.orderDate)}</span>,
                                customSort: (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime()
                            },
                            { title: 'Product Description', field: 'productDescription', disableClick: true },
                            {
                                title: 'Order Quantity', align: "right", field: 'orderQuantity', disableClick: false, render: rowData => (
                                    <span style={{ color: "blue", fontWeight: "600" }}>{rowData.orderQuantity.toFixed(2) + " " + rowData.orderUOM}</span>
                                )
                            },
                            {
                                title: 'Invoiced Quantity', align: "right", field: 'invoicedQuantity', disableClick: false, render: rowData => (
                                    <span style={{ color: "green", fontWeight: "600" }}>{rowData.invoicedQuantity.toFixed(2) + " " + rowData.invoicedUOM}</span>
                                )
                            },
                            {
                                title: 'Pending Quantity', align: "right", field: 'pendingQuantity', disableClick: false, render: rowData => (

                                    safeFloat(rowData.pendingQuantity) > 0 ? <span style={{ color: "red", fontWeight: "600" }}>{rowData.pendingQuantity.toFixed(2) + " " + rowData.orderUOM}</span>
                                        : <span>{"--"}</span>


                                )
                            },
                        ]} data={tableData} headerStyle="info" sorting={true}
                            toolbar={true} search={true} exportButton={true} paging={true} exportFileName={'Report list'}
                        />
                    </Grid>
                    {
                        safeBool(isList) ?
                            <>
                                <Grid item xs={12}>
                                    <Button variant="outlined" disabled={waiting}
                                        onClick={() => history.go(-1)}>{pageInfo.BackButtonCaption}</Button>
                                </Grid>
                            </> : null
                    }

                </Grid>
            </div>
        </>
    );
}

export default OrderFullfillmentStatus;
