import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import WebAPI from "../Base/WebAPI";
import AppIcon from "../UI/Controls/AppIcons";
import useStyles from "../../styles";
import { CurrencyInput, DateInput, TextInput, SelectInput } from "../UI/Controls/InputFields";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../Auth/AuthContext";
import { useHistory, useParams } from "react-router";

import * as Utility from '../Base/Utils';
import * as Common from '../Base/Common';
import * as DataProvider from '../Base/DataProvider';
import * as OrderHelper from '../Shared/OrderHelper';
import useStore from "../Base/Store";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import shallow from "zustand/shallow";
import DialogWindow from "../UI/Controls/DialogWindow";
import BankAccount from "../BankAccounts/BankAccount";
import { useConfirm } from "material-ui-confirm";

export default function AdvancePaymentReturn({ referenceType, isRefund, returnOrderId, actualOrderId }) {
    const confirm = useConfirm();
    const classes = useStyles();
    const { id } = useParams();
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const paymentModes = authContext.masterData.filter(md => md.type === 'PaymentMode');
    const showAlert = useStore(state => state.showAlert);

    const [bankList, setBankList] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [dialogResponse, setDialogResponse] = useStore(state => [state.dialogResponse, state.setDialogResponse], shallow);

    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const currencyCode = Utility.safeString(authContext.currentSubscriber.currencyCode);

    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "makeFullPayment": false,
        "referenceId": 0,
        "referenceType": referenceType,
        "referenceName": "",
        "amount": 0,
        "amountToAccount": 0,
        "modeOfPayment": "",
        "paymentDate": Utility.getISODate(),
        "paymentDueDate": Utility.getMinDate(),
        "orderId": 0,
        "orderNumber": "",
        "category": Common.PAYMENT_CATEGORY_ADVANCE,
        "narrative": "",
        "bankId": 0,
        "bankName": '',
        "active": true
    });

    const popuplateData = async (entityName, token) => {
        try {
            setWaiting(true);
            var response = await DataProvider.getEntityList(entityName, authContext, token);
            if (!!response) {
                if (!!response.code && response.code > 0) {
                    showAlert(response.message, 'error');
                } else {
                    switch (entityName) {
                        case Common.CUSTOMER_ENTITY:
                            setParties(response);
                            if (!Utility.isNullOrUndefined(id) && id > 0) {
                                setSelectedParty(response.find(p => p.id == id));
                            }
                            break;
                        case Common.SUPPLIER_ENTITY:
                            setParties(response);
                            if (!Utility.isNullOrUndefined(id) && id > 0) {
                                setSelectedParty(response.find(p => p.id == id));
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                showAlert('Failed to load ' + entityName + ' list. Internal sever error occured.', 'error');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }

    }
    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            var apiUrl = '/Payments/CreatePayment';
            var orderTypeId = 0;

            if (isRefund) {
                if (referenceType === Common.CUSTOMER_ENTITY) {
                    orderTypeId = Common.ORDER_TYPE_CR_NOTE;
                }
                else {
                    orderTypeId = Common.ORDER_TYPE_DR_NOTE;
                }
            }
            else {
                if (referenceType === Common.CUSTOMER_ENTITY) {
                    orderTypeId = Common.ORDER_TYPE_INVOICE;
                }
                else {
                    orderTypeId = Common.ORDER_TYPE_PURCHASE_BILL;
                }
            }

            const request = {
                "id": 0,
                "referenceId": Utility.safeInt(selectedParty.id),
                "referenceType": referenceType,
                "amount": Utility.safeFloat(values.amount),
                "amountToAccount": Utility.safeFloat(values.amount),
                "modeOfPayment": values.modeOfPayment,
                "bankId": Utility.safeInt(values.bankId),
                "paymentDate": values.paymentDate,
                "orderId": isRefund ? Utility.safeInt(actualOrderId) : 0,
                "orderTypeId": orderTypeId,
                "category": Common.PAYMENT_CATEGORY_ADVANCE_REFUND,
                "narrative": values.narrative,
                "active": true
            };
            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                // Print cheque
                if (values.modeOfPayment === 'Cheque' && referenceType === Common.SUPPLIER_ENTITY) {
                    await printCheque(values);
                }

                showAlert('Payment saved successfully.', 'success');
                if (referenceType == "Customer") {
                    history.push("/paymentHistory/" + Utility.safeString(selectedParty.id));
                } else {
                    history.push("/payablesHistory/" + Utility.safeString(selectedParty.id));
                }
            }
        } catch (error) {
            showAlert("Failed to save payment. Internal server error occured." + error.response, "error");
        } finally {
            setWaiting(false);
        }
    }
    useEffect(() => {
        async function init() {
            let source = axios.CancelToken.source();
            var bankListResponse = await OrderHelper.getBankList(authContext);
            setBankList(bankListResponse);

            await popuplateData(referenceType, source.token);
            if (isRefund) {
                var orderType = referenceType === Common.CUSTOMER_ENTITY ? Common.INVOICE_ENTITY : Common.PURCHASE_BILL_ENTITY;
                var order = await DataProvider.getData(orderType, 'Get' + orderType + 'LiteById', authContext, null, "/" + returnOrderId);
                let dueAmount = Utility.safeFloat(order.amountDue)
                if (dueAmount < 0) {
                    let tempValues = { ...fieldValues };
                    tempValues.amount = Math.abs(dueAmount);
                    setFieldValues(tempValues);
                } else {
                    showAlert('No refund is required.', 'warning');
                }
            }
        }
        init();
    }, []);
    const handleDialogClosed = (e, setFieldValue) => {
        try {
            if (dialogResponse != null) {
                bankList.push(dialogResponse);
                setSelectedBank(dialogResponse);
                setFieldValue('bankId', dialogResponse.id);
                setFieldValue('bankName', dialogResponse.bankName);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
        }

    };
    const printCheque = async (values) => {
        try {
            setWaiting(true);
            var template = await DataProvider.getData(Common.CHEQUE_TEMPLATE_ENTITY, "GetChequeTemplateById", authContext, null, selectedBank.chequeTemplate);
            if (!Utility.isNullOrUndefined(template) && Utility.safeInt(template.code) > 0) {
                showAlert(template.error, 'error');
            } else {
                var printData = {
                    amount: values.amount,
                    date: values.paymentDate,
                    payee: selectedParty.name,
                    template: template
                }
                await OrderHelper.printAdhocCheque(printData, (data) => { });
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }
    const isBankRequired = (values) => {
        if (values.modeOfPayment === 'Cash' || values.modeOfPayment === 'Other') {
            return false;
        }
        return true;
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                amount: yup.number().moreThan(0, 'Invalid amount (cannot be negative)').typeError('Invalid amount'),
                modeOfPayment: yup.string().required()
            })}
            onSubmit={async (values) => {
                if (isBankRequired(values) && values.bankId == "0") {
                    showAlert('Please select bank.', 'error');
                    Utility.setInputFocus('bankId');
                } else {
                    if (new Date(values.paymentDate) < new Date(Utility.getISODate())) {
                        confirm(Utility.getConfirmOptions('Payment date warning!', 'Payment date is set in the past, do you want to continue?')).then(
                            async () => {
                                await submitFormData(values);
                            });
                    } else if (new Date(values.paymentDate) > new Date(Utility.getISODate())) {
                        if (values.modeOfPayment === 'Cheque') {
                            confirm(Utility.getConfirmOptions('Payment date warning!', 'Payment date is set in the future, do you want to continue?')).then(
                                async () => {
                                    await submitFormData(values);
                                });
                        } else {
                            showAlert('Payment date cannot be in the future for this payment mode.', 'error');
                        }

                    } else {
                        await submitFormData(values);
                    }
                    // await submitFormData(values);

                }
            }}
        >
            {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    fullWidth
                                    filterOptions={Utility.filterOptions}
                                    size="small"
                                    id="referenceId"
                                    name="referenceId"
                                    disabled={isRefund}
                                    options={parties}
                                    getOptionLabel={(option) => !!option ? (referenceType === 'Customer' ? (option.legalName + ' ' + option.shortName) : option.name) : ''}
                                    value={selectedParty}
                                    onChange={(event, val) => {
                                        setSelectedParty(val);
                                    }}
                                    renderInput={(props) =>
                                        <TextInput fullWidth name="referenceName"  {...props} label={referenceType} variant="outlined" required
                                            className={classes.inputGroupField} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CurrencyInput label="Payment amount" name="amount" placeholder="" required
                                    disabled={isRefund} currencyCode={currencyCode}
                                    inputProps={{ maxLength: 15 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateInput label="Payment date" name="paymentDate" placeholder="" required />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SelectInput
                                    label="Payment mode"
                                    name="modeOfPayment"
                                    required
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        if (val === 'Cash') {
                                            setSelectedBank(null);
                                            setFieldValue('bankId', 0);
                                            setFieldValue('bankName', "");
                                        }
                                        setFieldValue('modeOfPayment', val);
                                    }}
                                >
                                    <option key="0" value=""></option>
                                    {paymentModes.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.description}
                                        </option>
                                    ))}
                                </SelectInput>
                                {
                                    (values.modeOfPayment === 'Cheque' && referenceType === Common.SUPPLIER_ENTITY) &&
                                    <Button variant="text" startIcon={<AppIcon name="Print" size="small" />}
                                        onClick={() => printCheque(values)}
                                    >Print cheque</Button>
                                }
                            </Grid>
                            <Grid container item xs={12} sm={4}>
                                <Grid item xs={10} sm={10}>
                                    <Autocomplete
                                        filterOptions={Utility.filterOptions}
                                        size="small"
                                        name="bankId"
                                        disabled={values.modeOfPayment === 'Cash'}
                                        options={bankList}
                                        getOptionLabel={(option) => !!option ? option.bankName : ''}
                                        value={selectedBank}
                                        onChange={(e, value) => {
                                            if (!Utility.isNullOrUndefined(value)) {
                                                setSelectedBank(value);
                                                setFieldValue('bankId', value.id);
                                                setFieldValue('bankName', value.bankName);
                                            }
                                        }}
                                        renderInput={(props) =>
                                            <TextInput {...props} name="bankName" label="Bank" className={!props.isModal ? classes.inputGroupField : null} variant="outlined" />
                                        }
                                    >
                                    </Autocomplete>
                                </Grid>
                                <Grid item container xs={2} sm={2} className={`${classes.inputGroup}`} alignContent="center" justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Tooltip title="Add new bank">
                                            <IconButton disabled={values.modeOfPayment === 'Cash'}
                                                onClick={(e) => openDialog('bankAccountDialog')} className={classes.mr1}>
                                                <AppIcon name="Add" color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Notes" name="narrative" placeholder="" />
                            </Grid>
                            <Grid item container xs={12} sm={12}>
                                <Button className={classes.successButton} type="submit" variant="contained" color="primary" size="small" disabled={isSubmitting} startIcon={<AppIcon name="RupeeIcon" />}>Add payment</Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} size="small" onClick={() => history.go(-1)}>Back</Button>
                            </Grid>
                        </Grid>
                        <DialogWindow name="bankAccountDialog" open={isDialogOpen}
                            onExited={e => handleDialogClosed(e, setFieldValue)}
                            width="md" component={<BankAccount isModal={true} />} />
                    </div>
                </Form>

            )}
        </Formik >
    )
};
