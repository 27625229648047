import React, { useEffect, useRef, useState, useContext } from 'react';
import { Accordion, Button, ButtonBase, Grid, Switch, Typography } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import useStyles from "../../styles";
import { EBCheckBox, LockIcon, PageHeader } from "../UI/Controls/Controls";
import { CheckboxInput, TextInput, SelectInput, DateInput, CurrencyInput } from "../UI/Controls/InputFields";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory, useParams } from "react-router";
import AppIcon from "../UI/Controls/AppIcons";
import AuthContext from "../Auth/AuthContext";
import WebAPI from "../Base/WebAPI";
import useStore from "../Base/Store";
import axios from "axios";
import shallow from 'zustand/shallow';
import * as Common from '../Base/Common';
import * as Utility from "../Base/Utils";
import { useConfirm } from "material-ui-confirm";
import { getData } from "../Base/DataProvider";
import { setAuditInformation } from "../Base/Support";
import QRCode from "qrcode.react";
import SwitchButton from '../UI/Controls/SwitchButton';

export default function BankAccount(props) {
    const confirm = useConfirm();
    const entityName = Common.BANK_DETAILS_ENTITY;
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [chequeTemplateList, setChequeTemplateList] = useState([]);
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const moduleAccess = authContext.moduleAccess(Common.APP_MODULE_BankAccounts);
    const [canExecute, setCanExecute] = useState(false);
    const { id } = useParams();
    const showAlert = useStore(state => state.showAlert);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const accountTypes = authContext.masterData.filter(md => md.type === 'AccountType');
    const [auditDetails, setAuditDetails] = useStore(state => [state.auditDetails, state.setAuditDetails], shallow);
    const currencyCode = Utility.safeString(authContext.currentSubscriber.currencyCode);
    const qrCodeRef = useRef(null);
    const [checked, setChecked] = React.useState(false);
    const [maintainedBalance, setMaintainedBalance] = useState([]);


    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "accountNumber": "",
        "bankName": Utility.safeString(props.prefillName),
        "branchName": "",
        "ifscCode": "",
        "micrCode": "",
        "swiftCode": "",
        "chequeTemplate": 0,
        "chequeBookStart": "",
        "chequeBookEnd": "",
        "accountType": "",
        "openingBalance": 0,
        "openingDate": Utility.getFinancialStartDate(),
        "overdraftLimit": 0,
        "isPrimary": false,
        "qrCode": "",
        "qrCodePreview": "",
        "accountName": Utility.safeString(authContext.currentSubscriber.legalName),
        "upiId": "",
        "overridePrimary": true,
        "active": true,
        "isOpeningBalanceCredit": false

    });

    const handleChange = () => {
        setChecked(prev => !prev);
    };


    const deleteBank = async () => {
        confirm(Utility.getConfirmOptions('Delete ' + entityName, 'Are you sure, you want to delete ' + entityName + '?'))
            .then(async () => {
                try {
                    setWaiting(true);
                    var response = await WebAPI.put('/' + entityName + '/Delete' + entityName, id, { headers: { "AccessToken": authContext.token } });
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                    } else {
                        showAlert(entityName + ' deleted successfully.', 'success');
                        history.push('/bankaccounts');
                    }
                } catch (error) {
                    showAlert(entityName + ' delete failed. Internal server error.', 'error');
                } finally {
                    setWaiting(false);
                }
            }).catch(() => { });
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        setCanExecute((Utility.safeInt(id) > 0 && moduleAccess.allowEdit) || (Utility.safeInt(id) === 0 && moduleAccess.allowAdd));
        async function init() {
            if (authContext.token != null) {
                await getChequeTemplateList(source.token);
                await fetchData(source);
            }
        }

        init();
        return () => {
            setAuditDetails(null);
            source.cancel();
        };
    }, []);

    const getChequeTemplateList = async (token) => {
        try {
            setWaiting(true);
            var response = await getData(Common.CHEQUE_TEMPLATE_ENTITY, "GetChequeTemplateList", authContext, token, authContext.currentSubscriber.id);
            if (!Utility.isNullOrUndefined(response) && Utility.safeInt(response.code) > 0) {
                showAlert(response.error, 'error');
            } else {
                setChequeTemplateList(response);
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled.');
            } else {
                console.log(error);
                showAlert('Failed to get cheque template list. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const fetchData = async (source) => {
        try {
            if (!props.isModal && !!id && id > 0) {
                setWaiting(true);
                const response = await WebAPI.get('/' + entityName + '/Get' + entityName + 'ById/' + id, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                if (!!response.data) {
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                        return;
                    }
                    setAuditInformation(response.data, setAuditDetails);
                    var bankAccount = { ...fieldValues };
                    let request = {
                        referenceId: 0,
                        referenceType: Common.GL_ENTITY,
                        bankId: Utility.safeInt(id),
                        accountNumber: ""
                    }
                    const maintainedBalance = await WebAPI.put('/GL/GetGLAccountOpeningBalanceList/', request, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                    if (!!maintainedBalance.data) {
                        if (!!maintainedBalance.data.code && maintainedBalance.data.code > 0) {
                            showAlert(maintainedBalance.data.message, 'error');
                            return;
                        }
                        if (maintainedBalance.data.length > 1) {
                            setMaintainedBalance(maintainedBalance.data);
                        }
                    }

                    Object.getOwnPropertyNames(bankAccount).map(prop => {
                        bankAccount[prop] = response.data[prop];
                    });
                    bankAccount.isOpeningBalanceCredit = Utility.safeBool(bankAccount.isOpeningBalanceCredit);

                    setChecked(Utility.safeBool(bankAccount.isOpeningBalanceCredit));

                    bankAccount.qrCodePreview = bankAccount.qrCode;
                    // Reformat date properties
                    bankAccount.openingDate = bankAccount.openingDate.slice(0, 10);
                    setFieldValues(bankAccount);
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled.');
            } else {
                showAlert('Failed to get ' + entityName + '. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const generateQRCodeAndConvert = () => {
        if (qrCodeRef.current) {
            const canvas = qrCodeRef.current.querySelector('canvas');
            if (canvas) {
                const base64QRCode = canvas.toDataURL('image/png');
                console.log('Base64 QR Code:', base64QRCode);
                return base64QRCode;
            } else {
                console.error('Canvas not found inside QRCodeRef');
                return ""; // Return empty string if canvas is not found
            }
        } else {
            console.error('QR Code ref not available');
            return ""; // Return empty string if qrCodeRef is not available
        }
    };




    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            var apiUrl = '/' + entityName + '/Create' + entityName;
            if (!props.isModal && !!id && id > 0) {
                apiUrl = '/' + entityName + '/Update' + entityName;
            }

            let request = {
                "id": !!id ? parseInt(id) : 0,
                "accountNumber": values.accountNumber,
                "bankName": values.bankName,
                "branchName": values.branchName,
                "ifscCode": values.ifscCode,
                "micrCode": values.micrCode,
                "swiftCode": values.swiftCode,
                "chequeTemplate": Utility.safeInt(values.chequeTemplate),
                "isPrimary": values.isPrimary,
                "chequeBookStart": values.chequeBookStart,
                "chequeBookEnd": values.chequeBookEnd,
                "accountType": values.accountType,
                "openingBalance": values.openingBalance,
                "openingDate": values.openingDate,
                "overdraftLimit": values.overdraftLimit,
                "upiId": Utility.safeString(values.upiId),
                "qrCode": generateQRCodeAndConvert(),
                "accountName": Utility.safeString(values.accountName),
                "overridePrimary": Utility.safeBool(values.isPrimary),
                "isOpeningBalanceCredit": Utility.safeBool(checked),
                "active": true,
            };

            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });

            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                if (props.isModal === true) {
                    setDialogResponse(response.data);
                    closeDialog();
                } else {
                    history.push("/bankaccounts");
                }
                showAlert(entityName + ' saved successfully.', 'success');
            }
        } catch (error) {
            showAlert("Failed to save " + entityName + ". Internal server error occurred." + error.response, "error");
        } finally {
            setWaiting(false);
        }
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                accountNumber: yup.string().matches(Common.REGEX_POSTIVE_NUMBER, 'Invalid value'),
                ifscCode: yup.string().matches(Common.REGEX_ALPHA_NUMERIC, 'Invalid value'),
                upiId: yup.string()
                    .matches(
                        /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/,
                        'Invalid UPI ID'
                    ),
            })}
            onSubmit={async (values) => {
                await submitFormData(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    <PageHeader heading={(!!id && id > 0) ? 'Bank a/c details' : 'New bank a/c'}>
                        <AppIcon name="Bank" color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            {/* Other form fields */}
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Bank name" name="bankName" placeholder="" required
                                    inputProps={{ autoFocus: true, minLength: 1, maxLength: 100 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Account number" name="accountNumber" placeholder="" required
                                    inputProps={{ minLength: 8, maxLength: 16 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Branch name" name="branchName" placeholder="" required
                                    inputProps={{ minLength: 3, maxLength: 100 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="IFSC code" name="ifscCode" placeholder="" required
                                    inputProps={{ minLength: 11, maxLength: 11 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="UPI Id" name="upiId" placeholder=""
                                    inputProps={{ maxLength: 100 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Account Name" name="accountName" placeholder="" required
                                    inputProps={{ minLength: 3, maxLength: 200 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SelectInput label="Account type" name="accountType">
                                    <option key="0" value="" />
                                    {!!accountTypes && accountTypes.map((item) => (
                                        <option key={item.code} value={item.code}>{item.description}</option>
                                    ))}
                                </SelectInput>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CurrencyInput label="Opening balance" name="openingBalance" placeholder="" required currencyCode={currencyCode}
                                    inputProps={{}} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateInput label="Opening balance as on" name="openingDate" placeholder="" required
                                    inputProps={{}} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SelectInput label="Cheque template" name="chequeTemplate">
                                    <option key="0" value="" />
                                    {!Utility.isNullOrUndefined(chequeTemplateList) && chequeTemplateList.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </SelectInput>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput label="Swift code" name="swiftCode" placeholder=""
                                    inputProps={{ maxLength: 11 }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SwitchButton
                                    labelLeft="Debit"
                                    labelRight="Credit"
                                    name={"isOpeningBalanceCredit"}
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <EBCheckBox name="isPrimary" caption="Is primary account?" infotext="Set as default and print on quotations, invoices etc." checked={values.isPrimary} />
                            </Grid>

                            {maintainedBalance && maintainedBalance.length > 1 && id > 0 ? (
                                <table style={{
                                    width: '50%',
                                    borderCollapse: 'collapse',
                                    marginTop: '1rem',
                                    margin: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #000'
                                }}>
                                    <thead>
                                        <tr>

                                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#F0F8FF', textAlign: 'center' }}>Date</th>
                                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#F0F8FF', textAlign: 'center' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {maintainedBalance.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.valueDate.slice(0, 10)}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                null
                            )}


                            <Grid item xs={12}>
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        id="qrCodeUploadHeader"
                                        className={classes.panelHeader}
                                    // expandIcon={<AppIcon name="ExpandMore" />}
                                    >
                                        <Typography variant="subtitle1" color="primary">Bank/UPI QR Code</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <Grid item container alignContent='center' alignItems='center'>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Grid item container alignContent='center' alignItems='center'>
                                                                    <Grid item xs={12}>
                                                                        <div ref={qrCodeRef}>
                                                                            {Utility.isNullOrEmpty(values.upiId) ? (
                                                                                <p>No UPI ID available, unable to generate UPI QR code.</p>
                                                                            ) : (
                                                                                <QRCode
                                                                                    value={`upi://pay?pa=${encodeURIComponent(values.upiId)}&pn=${encodeURIComponent(values.accountName)}`}
                                                                                    size={200}
                                                                                    logoWidth={60}
                                                                                    logoHeight={60}
                                                                                    logoOpacity={0.6}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item container xs={10} sm={8}>
                                <Button type="submit" disabled={isSubmitting || waiting || !canExecute}
                                    variant="contained" color="primary" endIcon={!canExecute && <LockIcon />}
                                >{(!!id && id > 0 && !props.isModal) ? 'Update' : 'Save'}</Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => props.isModal ? closeDialog() : history.goBack()}>{props.isModal ? 'Cancel' : 'Back'}</Button>
                            </Grid>

                            {(!Utility.safeBool(props.isModal) && Utility.safeInt(id) > 0 && canExecute) && moduleAccess.allowDelete &&
                                <Grid item container xs={2} sm={4} className={classes.pullRight} alignItems="center">
                                    <ButtonBase title="Delete bank a/c" onClick={deleteBank}>
                                        <AppIcon color="error" name="Delete" size="medium" />
                                    </ButtonBase>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
