import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, ButtonBase, Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextInput, PhoneInput, EmailInput, PasswordInput, MobilePasswordInput } from '../UI/Controls/InputFields';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import useStyles from '../../styles';
import AuthContext from './AuthContext';
import AppIcon from '../UI/Controls/AppIcons';
import shallow from 'zustand/shallow';
import useStore from '../Base/Store';
import config from '../../config.json';
import LoginImageOne from '../../images/login_image.png'
import * as Utility from '../Base/Utils';

import { getFinancialEndDate, getFinancialStartDate, isNullOrUndefined, safeInt, safeString, setInputFocus } from '../Base/Utils';
import { saveSubscriber } from '../User/Subscriber';
import { useConfirm } from 'material-ui-confirm';
import { ERROR_INTERNAL_SERVER } from '../Base/Common';

import { isFreeTrial } from '../Shared/OrderHelper';
import APP_LOGO from '../../images/ezybill_logo.png';

export default function Login(props) {
    const { id } = useParams();
    const confirm = useConfirm();
    const theme = useTheme();

    let deferredPrompt;
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));


    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const classes = useStyles();
    const [couponSpecialCode, setCouponSpecialCode] = useStore(state => [state.couponSpecialCode, state.setCouponSpecialCode], shallow);

    const authContext = useContext(AuthContext);
    const [loginMode, setLoginMode] = useState(true);
    const history = useHistory();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
    const [fieldValues, setFieldValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const showAlert = useStore(state => state.showAlert);

    useEffect(() => {
        if (safeInt(id) === 1 || safeString(id) === 'signup') {
            setLoginMode(false);
            setTimeout(() => {
                setInputFocus("phone");
            });
        } else {
            setTimeout(() => {
                setInputFocus("email");
            });
        }
    }, []);


    function getSourceId() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            let dealerCode = urlParams.get('dc');
            if (!isNullOrUndefined(dealerCode)) {
                return safeInt(dealerCode);
            }
            return 0;
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    const yupSchema = yup.object({
        firstName: !loginMode && yup.string().max(20, 'Name must not exceed 50 characters').required('Please provide a first name'),
        lastName: !loginMode && yup.string().max(20, 'Name must not exceed 50 characters').required('Please provide a last name'),
        phone: !loginMode && yup.string().matches(phoneRegExp, 'Invalid mobile number.').min(10, 'Mobile number should have atleast 10 digits').max(12, 'Invalid mobile number.').required('Please provide a mobile no.'),
        email: yup.string().email('Invalid email address').required('Please enter email address'),
        password: !loginMode && yup.string().min(8, 'Password must be at least 8 characters long').required('Please enter password'),
        confirmPassword: !loginMode && yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("password")],
                "Passwords does not match"
            )
        })
    });

    const authenticateUser = async (values) => {
        try {
            let dealerCode = getSourceId();
            const currentUrl = window.location.href; // Get the current page URL
            const urlObj = new URL(currentUrl);
            const offerCode = urlObj.searchParams.get("cc");
            if (offerCode !== "" && !isNullOrUndefined(offerCode)) {
                setCouponSpecialCode(offerCode)
            } else {
                setCouponSpecialCode('');
            }
            history.replace("");
            setWaiting(true);

            if (loginMode === true) {
                let loginResponse = await authContext.login(values.email, values.password);
                if (safeInt(id) === 2 || safeString(id) === "upgradenow") {
                    if (isFreeTrial(loginResponse?.subscriberDetails)) {
                        history.push("/packages");
                    } else {
                        history.push("/renewSubscription");
                    }
                } else {
                    if (authContext.currentUser.subscriberId === 0) {
                        history.push("/user-profile");
                    }
                }
            } else {
                const accessToken = await authContext.register(
                    {
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "emailAddress": values.email,
                        "password": values.password,
                        "mobile": values.phone,
                        "applicationId": config.APPLICATION_ID,
                        "sourceId": safeInt(dealerCode)
                    }
                );
                if (!isNullOrUndefined(accessToken)) {
                    const requestValues = {
                        legalName: 'YOUR COMPANY',
                        shortName: '',
                        taxIdNumber: '000000000000000',
                        companyMobile: values.phone,
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        pinCode: '',
                        description: '',
                        website: '',
                        businessTypeId: 403,
                        stateId: 0,
                        contactEmail: values.email,
                        contactPhone: values.phone,
                        fiscalStartDate: getFinancialStartDate(),
                        fiscalEndDate: getFinancialEndDate(),
                        logo: '',
                        taxRegistrationTypeId: 1,
                        currencyCode: 'INR',
                        importExportCode: '',
                        fssaiLicense: '',
                        drugLicense20B: '',
                        drugLicense21B: '',
                        additionalDetails: '',
                        isPrimary: true,
                        sourceId: Utility.safeInt(dealerCode)
                    }
                    const response = await saveSubscriber(0, 0, requestValues, { token: accessToken }, setWaiting, confirm, showAlert);
                    if (!isNullOrUndefined(response) && !isNullOrUndefined(response.data)) {
                        if (safeInt(response.data.code) > 0) {
                            showAlert(response.data.message, 'error');
                        } else {
                            authContext.setSubscriberSessionData(response);
                            authContext.updateContextState();
                            history.push("/dashboard");
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
            showAlert(ERROR_INTERNAL_SERVER, 'error');
        } finally {
            setWaiting(false);
        }
    }

    const handleCloseHelpVideo = () => {
        setIsHelpDialogOpen(false);
    }

    const handleOpenHelpVideo = (e) => {
        setIsHelpDialogOpen(true);
    }

    useEffect(() => {
        // Add event listener to 'beforeinstallprompt' event
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleBeforeInstallPrompt = (event) => {
        // Prevent the default behavior (installation prompt)
        event.preventDefault();
        // Store the deferred prompt for later use
        deferredPrompt = event;
    };

    const addToHomeScreen = () => {
        if (deferredPrompt) {
            // Show the installation prompt when the button is clicked
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the installation prompt.');
                } else {
                    console.log('User dismissed the installation prompt.');
                }
                // Reset the deferred prompt
                deferredPrompt = null;
            });
        }
    };

    useEffect(() => {
        // Add event listener to 'appinstalled' event
        window.addEventListener('appinstalled', handleAppInstalled);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const handleAppInstalled = (event) => {
        console.log('App installed on desktop!');
    };

    return (
        <>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                validationSchema={yupSchema}
                onSubmit={
                    async (values) => {
                        if (waiting === true) {
                            return; //To avoid duplicate 
                        }
                        await authenticateUser(values);
                    }
                }>
                {({ isSubmitting, values }) => (
                    <Form>
                        <div className={`${classes.loginBackground}`} >
                            {
                                !mobileDevice &&
                                <>
                                    <div className={classes.circleLeft}></div>
                                    <div className={classes.circleRight}></div>
                                </>
                            }
                            <Grid container spacing={2} justifyContent="center"
                                alignItems="center" alignContent="center">
                                <Grid item container xs={10} md={8} lg={8} xl={6} spacing={1} className={`${classes.loginCard}`}>
                                    <Grid item xs={12} md={6} style={{ boxShadow: '2px 5px 5px 0px #f0f0f0', borderRadius: "5px", border: '1px solid #f0f0f0' }}>
                                        <Grid item container spacing={2} className={`${classes.p2}`}>
                                            <Grid container item xs={12} justifyContent="center" alignContent='center' alignItems='center'>
                                                <Grid item xs={12} className={classes.pullCenter}>
                                                    <img src={APP_LOGO} alt='ezybill' width="50%" />
                                                    {/* <h1>
                                                        <span style={{
                                                            fontWeight: 'bold', fontSize: '45px', width: '72px', cursor: 'pointer', textAlign: 'center'
                                                        }}>

                                                            <span style={{ color: '#17A2B8' }}>ezy</span><span style={{ color: '#00C853' }}>bill</span>

                                                        </span>
                                                    </h1> */}
                                                </Grid>
                                                {/* <Grid item xs={12} className={classes.pullCenter}>
                                                    <Typography style={{ fontStyle: 'italic', color: 'slategrey', marginTop: '0px', fontSize: 'large' }}>
                                                        Ezy Bhi! Sahi Bhi!
                                                    </Typography>
                                                </Grid> */}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PhoneInput name="phone" label="Mobile" placeholder="Enter mobile" hidden={loginMode} required />
                                            </Grid>
                                            <Grid container item xs={12} alignContent="space-between">
                                                <Grid item xs={6}>
                                                    <TextInput name="firstName" label="First name" placeholder="Enter first name"
                                                        className={classes.pr1} hidden={loginMode} required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextInput name="lastName" label="Last name" placeholder="Enter last name" hidden={loginMode} required />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EmailInput name="email" label="email" placeholder="Enter email" required autoComplete="username" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    mobileDevice ? <MobilePasswordInput name="password" label="Password" placeholder="Enter password" required isnew={loginMode ? "false" : "true"} /> : <PasswordInput name="password" label="Password" placeholder="Enter password" required isnew={loginMode ? "false" : "true"} />
                                                }

                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    mobileDevice ? <MobilePasswordInput name="confirmPassword" label="Confirm password" placeholder="Re-enter password" required hidden={loginMode} /> : <PasswordInput name="confirmPassword" label="Confirm password" placeholder="Re-enter password" required hidden={loginMode} />
                                                }

                                            </Grid>
                                            {
                                                !loginMode &&
                                                <label className={classes.tncText}>By clicking on Sign up button below, you hereby agree to the <a href='https://ezybill.in/terms.html' rel="noreferrer" target='_blank'>Terms &amp; Conditions</a> and <a href='https://ezybill.in/privacy-policy.html' rel="noreferrer" target='_blank'>Privacy policy</a> of use.</label>

                                            }
                                            <Grid item xs={12}>
                                                <Button disabled={isSubmitting || waiting} className={!loginMode ? `${classes.successButton}` : null} type="submit"
                                                    variant={'contained'}
                                                    color={loginMode ? 'secondary' : 'secondary'}
                                                    fullWidth
                                                    startIcon={<AppIcon name="Login" />}>
                                                    {loginMode ? 'Login' : 'Sign up'}
                                                </Button>
                                            </Grid>
                                            <Grid container justifyContent="space-between" className={`${classes.ml1} ${classes.mr1}`}>
                                                <Grid item hidden={!loginMode} >
                                                    <Button type="button" variant="text" color="secondary"
                                                        className={classes.link} title="Click to reset your password"
                                                        onClick={() => history.push("/resetpassword")}>Forgot password?</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button type="button" variant="text" className={loginMode ? `${classes.successColor} ${classes.link}` : `${classes.link} ${classes.pullCenter}`}
                                                        title={loginMode ? 'New to ezybiil? Click to Signup and get started' : 'Existing user/already signed up? Click to login.'}
                                                        onClick={() => setLoginMode(!loginMode)}
                                                    >
                                                        {loginMode ? 'Signup/Create account' : 'Login to existing account'}
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container justifyContent="center" style={{ padding: 0, margin: 0 }}>
                                                <Button className={`${classes.link} ${classes.pullCenter}`} type="button" variant="text" color='primary' startIcon={<AppIcon color="error" name="YouTube" />} onClick={handleOpenHelpVideo}>Getting started</Button>
                                            </Grid>
                                            <Grid container item xs={12} justifyContent="center">
                                                <Grid item xs={12} className={classes.pullCenter}>
                                                    <span style={{ color: 'gray', fontFamily: 'sans-serif' }}>App Version: <span style={{ fontWeight: 'bold' }}>{config.VERSION}</span></span>
                                                    <span style={{ textAlign: 'center', fontSize: '10px', marginTop: '3px', marginLeft: '16px' }} >{' \u00A9' + new Date().getFullYear() + ' EMERSYS LLP'}</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        mobileDevice ? null : <Grid item xs={12} md={6} style={{ marginTop: loginMode ? '30px' : '70px' }}>
                                            <span className={classes.pullCenter}>
                                                <img src={LoginImageOne} alt="ezybill" width={'100%'} />
                                            </span>
                                        </Grid>
                                    }

                                </Grid>

                            </Grid>
                        </div>
                    </Form >
                )
                }
            </Formik >

            <Dialog
                open={isHelpDialogOpen}
                onClose={handleCloseHelpVideo}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0,0,0,0.83)',
                        width: '100%'
                    },
                }}
            >
                <ButtonBase className={classes.pullRight} style={{ color: 'red', paddingRight: 10, paddingTop: 5, paddingBottom: 0, margin: 0, fontSize: '18px', fontWeight: 'bold' }} onClick={handleCloseHelpVideo}>&#10006;</ButtonBase>
                <DialogContent style={{ padding: 0, margin: 5, borderRadius: '2px' }}>
                    <div className={classes.iframeContainer}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/GTioH4ER4L8" title="Getting started with ezybill" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
};

