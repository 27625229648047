import React from 'react';
import { Typography, Grid } from '@material-ui/core';

const SwitchButton = ({ labelLeft, labelRight, checked, onChange, name }) => {
    return (
        <div>
            <Typography variant="caption" component="div" style={{ textAlign: 'left' }}>
                Specify opening balance (Dr/Cr)?
            </Typography>

            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{labelLeft}</Grid>
                    <Grid item>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={onChange}
                                name={name}
                            />
                            <span className="slider round"></span>
                        </label>
                    </Grid>
                    <Grid item>{labelRight}</Grid>
                </Grid>
            </Typography>

            <style jsx>{`
        .switch {
          position: relative;
          display: inline-block;
          width: 34px;
          height: 20px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 20px;
        }
        .slider:before {
          position: absolute;
          content: '';
          height: 14px;
          width: 14px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
        input:checked + .slider {
          background-color: #2196F3;
        }
        input:checked + .slider:before {
          transform: translateX(14px);
        }
      `}</style>
        </div>
    );
};

export default SwitchButton;
