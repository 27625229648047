import React, { useContext, Suspense } from "react";
import { Route, Switch } from "react-router";
import AuthContext from "../components/Auth/AuthContext";
import Login from "../components/Auth/Login";
import Home from "../components/Home/Home";
import Layout from "../components/UI/Layout/Layout";
import Waiting from "../components/UI/Background/Waiting/Waiting";

import useStore from "../components/Base/Store";
import Alert from "../components/UI/Controls/Alert";

import { ConfirmProvider } from 'material-ui-confirm';


// import Reports from "../components/Reports/Reports";
import CustomerOrderList from './../components/Party/Customer/CustomerList';
import SupplierOrderList from './../components/Party/Supplier/SupplierList';

import { LoadingBackdrop } from "../components/UI/Controls/Controls";
import { isNullOrUndefined, safeBool } from "../components/Base/Utils";
import { APP_MODULE_Dashboard, CREDIT_NOTE_ENTITY, CUSTOMER_ENTITY, DEBIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY, SUPPLIER_ENTITY } from "../components/Base/Common";
import { useMediaQuery, useTheme } from "@material-ui/core";
import OrdersListView from "../components/Orders/OrdersListView";
import DeliverychallansSummary from "../components/Shared/Orders/Summary/DeliverychallansSummary";
import SalesOrderSummary from "../components/Shared/Orders/Summary/SalesOrderSummary";
import OnlineStatusIndicator from "../components/UI/useOnlineStatus/OnlineStatusIndicator";
import JournalEntries from "../components/Transaction/JournalEntries/JournalEntries";
import ErrorBoundary from "./ErrorBoundary";
import AdvanceRefund from "../components/Payments/Receivables/AdvanceReturn";
/* import ChatbotScreen from "../components/EB_Bot/EBBot"; */
// import OnlineStatusIndicato../components/Shared/Orders/Summary/DeliverychallansSummarysIndicator";
const UpdateInvoice = React.lazy(() => import('../components/Orders/Invoice/UpdateInvoice'));
// const ManageCustomer = React.lazy(() => import('../components/Customer/ManageCustomer'));
const CustomField = React.lazy(() => import('../components/User/Settings/CustomField/CustomField'));
const CustomFieldList = React.lazy(() => import('../components/User/Settings/CustomField/CustomFieldList'));
const PurchaseOrderFullfillmentStatus = React.lazy(() => import('../components/Reports/FullfillmentStatus/PurchaseOrderFullfillmentStatus'));
const SalesOrderFullfillmentStatus = React.lazy(() => import('../components/Reports/FullfillmentStatus/SalesOrderFullfillmentStatus'));
const AddStockConversion = React.lazy(() => import('../components/StockConversion/AddStockConversion'));
const Employee = React.lazy(() => import('../components/Employee/Employee'));
const Employees = React.lazy(() => import('../components/Employee/Employees'));
const StockConversion = React.lazy(() => import('../components/StockConversion/StockConversion'));
const RecurringList = React.lazy(() => import('../components/Sales/Invoice/RecurringList'));
const ShopifyInventorySync = React.lazy(() => import('../components/Inventory/ShopifyInventorySync'));
const GSTR2A = React.lazy(() => import('../components/Reports/GST/GSTR2A'));
const BarcodeTemplates = React.lazy(() => import('../components/Product/Barcode/BarcodeTemplates'));
const BarcodeTemplateSettings = React.lazy(() => import('../components/Product/Barcode/TemplateSettings'));
const GSTR3B = React.lazy(() => import('../components/Reports/GST/GSTR3B'));
const InvoiceListByEmployee = React.lazy(() => import('../components/Sales/Invoice/InvoiceListByEmployee'));
const InvoicePickList = React.lazy(() => import('../components/Sales/Invoice/PickList'));
const GenerateBarcode = React.lazy(() => import('../components/Product/Barcode/GenerateBarcode'));
const TrackReturns = React.lazy(() => import('../components/Reports/GST/TrackReturns'));
const BundleList = React.lazy(() => import('../components/Product/CompositeItem/BundleList'));
const ProductBundle = React.lazy(() => import('../components/Product/CompositeItem/ProductBundle'));
const PaymentsOutList = React.lazy(() => import('../components/Payments/Payables/PaymentsOutList'));
const PaymentsInList = React.lazy(() => import('../components/Payments/Receivables/PaymentsInList'));
const CompositeItem = React.lazy(() => import('../components/Product/CompositeItem/CompositeItem'));
const CompositeItemList = React.lazy(() => import('../components/Product/CompositeItem/CompositeItemList'));
const PurchaseSummary = React.lazy(() => import('../components/Shared/Orders/Summary/PurchaseSummary'));
const InvoiceSummary = React.lazy(() => import('../components/Shared/Orders/Summary/InvoiceSummary'));
const QuotationSummary = React.lazy(() => import('../components/Shared/Orders/Summary/QuotationSummary'));
const PurchaseByUser = React.lazy(() => import('../components/Reports/User/PurchaseByUser'));
const SalesByUser = React.lazy(() => import('../components/Reports/User/SalesByUser'));
const SalesByProductAndCategory = React.lazy(() => import('../components/Reports/Product/SalesByProductAndCategory'));
const PurchaseByProductCategory = React.lazy(() => import('../components/Reports/Product/PurchaseByProductCategory'));
const PurchaseByProduct = React.lazy(() => import('../components/Reports/Product/PurchaseByProduct'));
const SalesByProductCategory = React.lazy(() => import('../components/Reports/Product/SalesByProductCategory'));
const SalesByProduct = React.lazy(() => import('../components/Reports/Product/SalesByProduct'));
const ProductGroupList = React.lazy(() => import('../components/Product/ProductGroupList'));
const ProductGroup = React.lazy(() => import('../components/Product/ProductGroup'));
const Packages = React.lazy(() => import('../components/User/Subscription/Packages'));
const LinkedTypeList = React.lazy(() => import('../components/Shared/LinkedTypes/LinkedTypeList'));
const UnitConversionList = React.lazy(() => import('../components/Shared/UOM/UnitConversionList'));
const EInvoiceList = React.lazy(() => import('../components/Sales/Invoice/EInvoice/EInvoiceList'));
const EwayBillList = React.lazy(() => import('../components/Sales/Invoice/Eway/EwayBillList'));
const PurchaseBySupplier = React.lazy(() => import('../components/Reports/OrdersByParty/PurchaseBySupplier'));
const ItemWisePurchaseData = React.lazy(() => import('../components/Reports/ItemWiseData/ItemWisePurchaseData'));
const ItemWiseSalesData = React.lazy(() => import('../components/Reports/ItemWiseData/ItemWiseSalesData'));
const UserNotAuthorised = React.lazy(() => import('../components/Shared/UserNotAuthorised'));
const SalesOrder = React.lazy(() => import('../components/Sales/Orders/SalesOrder'));

const NewSalesOrder = React.lazy(() => import('../components/Sales/Orders/NewSalesOrder'));
const CreateSalesOrder = React.lazy(() => import('../components/Orders/SalesOrder/CreateSalesOrder'));

const SalesOrderList = React.lazy(() => import('../components/Sales/Orders/SalesOrderList'));
const User = React.lazy(() => import('../components/User/Manage/User'));
const SalesByCustomer = React.lazy(() => import('../components/Reports/OrdersByParty/SalesByCustomer'));
const UOM = React.lazy(() => import('../components/Shared/UOM/UOM'));
const UOMList = React.lazy(() => import('../components/Shared/UOM/UOMList'));
const ExpenseType = React.lazy(() => import('../components/Expenses/ExpenseType'));
const ExpenseTypeList = React.lazy(() => import('../components/Expenses/ExpenseTypeList'));
const Subscription = React.lazy(() => import('../components/User/Subscription/Subscription'));
const GSTR1 = React.lazy(() => import('../components/Reports/GST/GSTR1'));
const BankStatement = React.lazy(() => import('../components/BankAccounts/BankStatement'));
const AccountTransactionHistory = React.lazy(() => import('../components/Accounting/AccountCharts/AccountTransactionHistory'));
// const ChartOfAccounts = React.lazy(() => import('../components/Accounting/AccountCharts/ChartOfAccounts'));
const NewChartOfAccounts = React.lazy(() => import('../components/Accounting/AccountCharts/NewChartOfAccount'));

const ExpensesThisMonth = React.lazy(() => import('../components/Expenses/ExpensesThisMonth'));
const ExpensesToday = React.lazy(() => import('../components/Expenses/ExpensesToday'));
const SalesToday = React.lazy(() => import('../components/Sales/Invoice/SalesToday'));
const CustomerPendingStatement = React.lazy(() => import('../components/Reports/PendingStatement/CustomerPendingStatement'));
const SupplierPendingStatement = React.lazy(() => import('../components/Reports/PendingStatement/SupplierPendingStatement'));
const InvoiceListByCustomerGroup = React.lazy(() => import('../components/Sales/Invoice/InvoiceListByCustomerGroup'));
// const TradingAccountStatement = React.lazy(() => import('../components/Accounting/TradingAccountStatement'));
const ChequePrintSettings = React.lazy(() => import('../components/Payments/Cheque/ChequePrintSettings'));
const PrintCheque = React.lazy(() => import('../components/Payments/Cheque/PrintCheque'));
const OutOfStockItems = React.lazy(() => import('../components/Inventory/OutOfStockItems'));
const LowStockItems = React.lazy(() => import('../components/Inventory/LowStockItems'));
const NotificationsList = React.lazy(() => import('../components/User/NotificationsList'));
const InvoiceDeletedList = React.lazy(() => import('../components/Sales/Invoice/InvoiceDeletedList'));
const PurchaseBillNumber = React.lazy(() => import('../components/Shared/PurchaseBillNumber'));
const LinkedType = React.lazy(() => import('../components/Shared/LinkedTypes/LinkedType'));
const PayAdvance = React.lazy(() => import('../components/Payments/Payables/PayAdvance'));
const ReceiveAdvance = React.lazy(() => import('../components/Payments/Receivables/ReceiveAdvance'));
const StockAdjustmentList = React.lazy(() => import('../components/Inventory/StockAdjustmentList'));
const StockAdjustment = React.lazy(() => import('../components/Inventory/StockAdjustment'));
const RefundAdvance = React.lazy(() => import('../components/Payments/RefundAdvance'));
const SelectRefundType = React.lazy(() => import('../components/Payments/SelectRefundType'));
const PurchaseBillListByProductId = React.lazy(() => import('../components/Reports/PurchaseBillListByProductId'));
const InvoiceListByProductId = React.lazy(() => import('../components/Reports/InvoiceListByProductId'));
const Refund = React.lazy(() => import('../components/Payments/Refund'));
const ReceivablesHistory = React.lazy(() => import('../components/Payments/Receivables/ReceivablesHistory'));
const PayablesHistory = React.lazy(() => import('../components/Payments/Payables/PayablesHistory'));
const MakePayment = React.lazy(() => import('../components/Payments/Payables/MakePayment'));
const ReceivePayment = React.lazy(() => import('../components/Payments/Receivables/ReceivePayment'));
const SelectParty = React.lazy(() => import('../components/Shared/SelectParty'));
const PurchaseRegister = React.lazy(() => import('../components/Reports/Register/PurchaseRegister'));
const SalesRegister = React.lazy(() => import('../components/Reports/Register/SalesRegister'));
const SupplierTransactionStatement = React.lazy(() => import('../components/Reports/TransactionStatement/SupplierTransactionStatement'));
const CustomerTransactionStatement = React.lazy(() => import('../components/Reports/TransactionStatement/CustomerTransactionStatement'));
const SupplierTransactionHistory = React.lazy(() => import('../components/Reports/TransactionHistory/SupplierTransactionHistory'));
const CustomerTransactionHistory = React.lazy(() => import('../components/Reports/TransactionHistory/CustomerTransactionHistory'));
const BatchDetail = React.lazy(() => import('../components/Payments/BatchDetail'));
const ResetPassword = React.lazy(() => import('../components/Auth/ResetPassword'));
const ImportMasterData = React.lazy(() => import('../components/Shared/ImportMasterData'));
const AdvancePayment = React.lazy(() => import('../components/Payments/AdvancePayment'));
const DeliveryAddress = React.lazy(() => import('../components/Address/DeliveryAddress'));
const BatchPayment = React.lazy(() => import('../components/Payments/BatchPayment'));
const PriceList = React.lazy(() => import('../components/Product/PriceList'));
const Inventory = React.lazy(() => import('../components/Inventory/Inventory'));
const InvoiceListByCustomer = React.lazy(() => import('../components/Sales/Invoice/InvoiceListByCustomer'));
const BillPayment = React.lazy(() => import('../components/Payments/Payables/BillPayment'));
const InvoicePayment = React.lazy(() => import('../components/Payments/Receivables/InvoicePayment'));
const PayablesList = React.lazy(() => import('../components/Payments/Payables/PayablesList'));
const ReceivablesList = React.lazy(() => import('../components/Payments/Receivables/ReceivablesList'));
const PaymentLinks = React.lazy(() => import('../components/Payments/PaymentLinks/PaymentLinks'));
// const SubscriberBranchList = React.lazy(() => import('./../components/SubscriberBranch/SubscriberBranchList'));

// const NewSubscriberBranch = React.lazy(() => import('./../components/SubscriberBranch/NewSubscriberBranch'));
const Dashboard = React.lazy(() => import('../components/Home/Dashboard'));
const NewReport = React.lazy(() => import('../components/Reports/NewReport'));
const Documents = React.lazy(() => import('../components/Docs/Documents'));
const CreateInvoice = React.lazy(() => import('../components/Orders/Invoice/CreateInvoice'));
const BankAccount = React.lazy(() => import('../components/BankAccounts/BankAccount'));
const BankAccountList = React.lazy(() => import('../components/BankAccounts/BankAccountList'));
const Expense = React.lazy(() => import('../components/Expenses/Expense'));
const ExpenseList = React.lazy(() => import('../components/Expenses/ExpenseList'));

const NewDebitNote = React.lazy(() => import('../components/Purchase/DebitNotes/NewDebitNote'));
const CreateDebitNote = React.lazy(() => import('../components/Orders/DebitNote/CreateDebitNote'));

const DebitNoteList = React.lazy(() => import('../components/Purchase/DebitNotes/DebitNoteList'));
const DebitNote = React.lazy(() => import('../components/Purchase/DebitNotes/DebitNote'));

const NewCreditNote = React.lazy(() => import('../components/Sales/CreditNotes/NewCreditNote'));
const CreateCreditNote = React.lazy(() => import('../components/Orders/CreditNote/CreateCreditNote'));

const CreditNoteList = React.lazy(() => import('../components/Sales/CreditNotes/CreditNoteList'));
const CreditNote = React.lazy(() => import('../components/Sales/CreditNotes/CreditNote'));

const NewDeliveryChallan = React.lazy(() => import('../components/Sales/DeliveryChallans/NewDeliveryChallan'));
const CreateDeliveryChallan = React.lazy(() => import('../components/Orders/DeliveryChallan/CreateDeliveryChallan'));

const DeliveryChallanList = React.lazy(() => import('../components/Sales/DeliveryChallans/DeliveryChallanList'));
const DeliveryChallan = React.lazy(() => import('../components/Sales/DeliveryChallans/DeliveryChallan'));
const Quotation = React.lazy(() => import('../components/Sales/Quotation/Quotation'));
const NewQuotation = React.lazy(() => import('../components/Sales/Quotation/NewQuotation'));
const CreateQuotation = React.lazy(() => import('../components/Orders/Quotation/CreateQuotation'));
const Invoice = React.lazy(() => import('../components/Sales/Invoice/Invoice'));
const InvoiceList = React.lazy(() => import('../components/Sales/Invoice/InvoiceList'));
const NewInvoice = React.lazy(() => import('../components/Sales/Invoice/NewInvoice'));
const QuotationList = React.lazy(() => import('../components/Sales/Quotation/QuotationList'));
const CustomerGroupList = React.lazy(() => import('../components/Customer/CustomerGroupList'));
const CustomerGroup = React.lazy(() => import('../components/Customer/CustomerGroup'));
const CustomerList = React.lazy(() => import('../components/Customer/CustomerList'));
const Customer = React.lazy(() => import('../components/Customer/Customer'));

const NewPurchaseBill = React.lazy(() => import('../components/Purchase/Bills/NewPurchaseBill'));
const CreatePurchaseBill = React.lazy(() => import('../components/Orders/PurchaseBill/CreatePurchaseBill'));

const PurchaseBillList = React.lazy(() => import('../components/Purchase/Bills/PurchaseBillList'));
const PurchaseBill = React.lazy(() => import('../components/Purchase/Bills/PurchaseBill'));

const NewPurchaseOrder = React.lazy(() => import('../components/Purchase/Orders/NewPurchaseOrder'));
const CreatePurchaseOrder = React.lazy(() => import('../components/Orders/PurchaseOrder/CreatePurchaseOrder'));

const PurchaseOrderList = React.lazy(() => import('../components/Purchase/Orders/PurchaseOrderList'));
const PurchaseOrder = React.lazy(() => import('../components/Purchase/Orders/PurchaseOrder'));
const WarehouseList = React.lazy(() => import('../components/Warehouse/WarehouseList'));
const Warehouse = React.lazy(() => import('../components/Warehouse/Warehouse'));
const ProductList = React.lazy(() => import('../components/Product/ProductList'));
const Product = React.lazy(() => import('../components/Product/Product'));
const SupplierList = React.lazy(() => import('../components/Supplier/SupplierList'));
const Supplier = React.lazy(() => import('../components/Supplier/Supplier'));
const ChangePassword = React.lazy(() => import('../components/Auth/ChangePassword'));
const UserProfile = React.lazy(() => import('../components/User/UserProfile'));
const UserList = React.lazy(() => import('../components/User/Manage/UserList'));
const FileGSTReturns = React.lazy(() => import('../components/Reports/GST/GSTR1/FileGSTReturns'));
const QtyBasedPricingList = React.lazy(() => import('../components/Product/QtyBasedPricing/QtyBasedPricingList'));
// const DigitalInvoice = React.lazy(() => import('../components/Docs/DigitalInvoices/DigitalInvoice'));
const GLAccountList = React.lazy(() => import('../components/GLAccount/GLAccountList'));
const AddQtyBasedPricing = React.lazy(() => import('./../components/Product/QtyBasedPricing/AddQtyBasedPricing'));
const ProductPriceList = React.lazy(() => import('./../components/PriceList/ProductPriceList'));
const TransactionList = React.lazy(() => import('./../components/Transaction/TransactionList'));
const CreateProductPriceList = React.lazy(() => import('./../components/PriceList/CreateProductPriceList'));
const IncomeList = React.lazy(() => import('../components/Income/IncomeList'));
const Income = React.lazy(() => import('../components/Income/Income'));
const IncomeType = React.lazy(() => import('../components/Income/IncomeType'));
const UpdateDeliveryChallan = React.lazy(() => import('../components/Orders/DeliveryChallan/UpdateDeliveryChallan'));
const UpdateSalesOrder = React.lazy(() => import('../components/Orders/SalesOrder/UpdateSalesOrder'));
const UpdatePurchaseBill = React.lazy(() => import('../components/Orders/PurchaseBill/UpdatePurchaseBill'));
const UpdatePurchaseOrder = React.lazy(() => import('../components/Orders/PurchaseOrder/UpdatePurchaseOrder'));
const UpdateCreditNote = React.lazy(() => import('../components/Orders/CreditNote/UpdateCreditNote'));
const UpdateDebitNote = React.lazy(() => import('../components/Orders/DebitNote/UpdateDebitNotes'));
const UpdateQuotation = React.lazy(() => import('../components/Orders/Quotation/UpdateQuotation'));
const BalanceSheetList = React.lazy(() => import('./../components/FinancialStatemenet/BalanceSheetList'));
const IncomeTypeList = React.lazy(() => import('../components/Income/IncomeTypeList'));
const BulkUploadSteper = React.lazy(() => import('../components/Shared/BulkUploadSteper/BulkUploadSteper'));
const CustomerWisePendingInvoices = React.lazy(() => import('../components/Reports/PendingOrders/CustomerWisePendingInvoices'));
const SupplierWisePendingBills = React.lazy(() => import('../components/Reports/PendingOrders/SupplierWisePendingBills'));

const NetWiseSalesReport = React.lazy(() => import('../components/Reports/NetWiseReport.js/NetWiseSalesReport'));
const NetWisePurcahseReport = React.lazy(() => import('../components/Reports/NetWiseReport.js/NetWisePurcahseReport'));

const PurchaseByProductAndCategory = React.lazy(() => import('../components/Reports/Product/PurchaseByProductAndCategory'));
const ProjectWiseTransactionSummary = React.lazy(() => import('../components/Projects/ProjectWiseTransactionSummary'));

const ProfitAndLossStatement = React.lazy(() => import('../components/Reports/ProfitAndLossStatement'));

const ProjectList = React.lazy(() => import('./../components/Projects/ProjectList'));

const Project = React.lazy(() => import('../components/Projects/Project'));


const GetDeliveryChallanListByProductId = React.lazy(() => import('../components/Orders/DeliveryChallan/GetDeliveryChallanListByProductId'));

const Contra = React.lazy(() => import('./../components/Contra/Contra'));


const RenewSubscription = React.lazy(() => import('../components/User/Subscription/RenewSubscription'));

const OrderList = React.lazy(() => import('./../components/Orders/OrderList'));

const DayBookSummary = React.lazy(() => import('./../components/Reports/DayBookSummary/DayBookSummary'));

const SupplierOrderAdjustmentList = React.lazy(() => import('../components/Reports/OrderAdjustmentsReport/SupplierOrderAdjustmentList'));

const CustomerOrderAdjustmentList = React.lazy(() => import('../components/Reports/OrderAdjustmentsReport/CustomerOrderAdjustmentList'));

const CreateTransaction = React.lazy(() => import('./../components/Transaction/CreateTransaction'));
const Company = React.lazy(() => import('../components/User/Company'));
const ProductImageController = React.lazy(() => import('./../components/Product/Barcode/ProductImageController'));
const InventoryByDate = React.lazy(() => import('../components/Inventory/InventoryByDate'));

const InventoryByProductBetwenDates = React.lazy(() => import('../components/Inventory/InventoryByProductBetwenDates'));

const CustomerBalanceList = React.lazy(() => import('../components/Reports/Balance/CustomerBalanceList'));

const SupplierBalanceList = React.lazy(() => import('../components/Reports/Balance/SupplierBalanceList'));

const ProfitMargin = React.lazy(() => import("../components/Reports/ProfitMargin/ProfitMargin"));

/* const TransferOrder = React.lazy(() => import("../components/Orders/TransferOrder/TransferOrder")); */

const ScheduleDrugsReport = React.lazy(() => import("../components/Reports/Farma/ScheduleDrugsReport"));


export default function App(props) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const alertOpen = useStore(state => state.alertOpen);
  const displayDataGrid = useStore(state => state.displayDataGrid);
  const waiting = useStore(state => state.waiting);

  if (!authContext.isLoggedIn) {
    return (
      <>
        <LoadingBackdrop open={waiting} />
        {alertOpen && <Alert />}
        <Switch>
          <Route path="/docs/:key">
            <Suspense fallback={<Waiting />}>
              <Documents />
            </Suspense>
          </Route>
          <Route path="/docs">
            <Suspense fallback={<Waiting />}>
              <Documents />
            </Suspense>
          </Route>
          <Route path="/resetpassword">
            <Suspense fallback={<Waiting />}>
              <ResetPassword />
            </Suspense>
          </Route>
          <Route path="/:id">
            <Login />
          </Route>
          <Route>
            <Login />
          </Route>
        </Switch>
      </>

    );
  } else if (isNullOrUndefined(authContext.currentSubscriber) || (Object.keys(authContext.currentSubscriber).length === 0 && Object.getPrototypeOf(authContext.currentSubscriber) === Object.prototype)) {
    return (
      <>
        {alertOpen && <Alert />}
        <LoadingBackdrop open={waiting} />
        <Layout>
          <ConfirmProvider>
            <Suspense fallback={<Waiting />}>
              <UserProfile />
            </Suspense>
          </ConfirmProvider>
        </Layout>
      </>
    )

  } else if (safeBool(authContext.isLoggedIn) && !isNullOrUndefined(authContext.currentUser) && safeBool(authContext.currentUser.changePasswordAtLogon) === true) {
    return (
      <>
        <Layout>
          <Suspense fallback={<Waiting />}>
            <ChangePassword />
          </Suspense>
        </Layout>
      </>
    )
  } else {
    return (
      <>
        {alertOpen && <Alert />}
        <LoadingBackdrop open={waiting} />
        <OnlineStatusIndicator />
        <Layout>
          <ErrorBoundary>
            <Switch>
              <Route path={"/balancesheet"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BalanceSheetList />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/customfields/:moduleId"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomFieldList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/customfields"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomFieldList />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/netwisesalesreport"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <NetWiseSalesReport />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/netwisepurcashsereport"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <NetWisePurcahseReport />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/contra"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Contra />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/customfield/:moduleId/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomField />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/customfield"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomField />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/dbssummary"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <DayBookSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/suppliertcsreport"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <SupplierOrderAdjustmentList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/customertdsreport"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomerOrderAdjustmentList />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/quotationsummary/:from/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <QuotationSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/invoicesummary/:from/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <InvoiceSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/dcsummary/:from/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <DeliverychallansSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/salesordersummary/:from/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <SalesOrderSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/purchasesummary/:from/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PurchaseSummary />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/stockConversion"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <StockConversion />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/qtybasedPricing"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <QtyBasedPricingList />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/profitmargin"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProfitMargin />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/addstockconversion"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <AddStockConversion />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/gstr1filing"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <FileGSTReturns />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/GLAccountList"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <GLAccountList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/bulkUpload/:name"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BulkUploadSteper />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/orderlist/:entityName/:projectId"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <OrderList />
                  </Suspense>
                </ConfirmProvider>
              </Route>


              <Route path={"/salesbyuser/:id"}>
                <Suspense fallback={<Waiting />}>
                  <SalesByUser />
                </Suspense>
              </Route>
              <Route path={"/salesbyuser"}>
                <Suspense fallback={<Waiting />}>
                  <SalesByUser />
                </Suspense>
              </Route>
              <Route path={"/purchasebyuser/:id"}>
                <Suspense fallback={<Waiting />}>
                  <PurchaseByUser />
                </Suspense>
              </Route>
              <Route path={"/purchasebyuser"}>
                <Suspense fallback={<Waiting />}>
                  <PurchaseByUser />
                </Suspense>
              </Route>
              <Route path={"/scheduledrugsreport"}>
                <Suspense fallback={<Waiting />}>
                  <ScheduleDrugsReport />
                </Suspense>
              </Route>
              <Route path={"/recurringList"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <RecurringList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/bundles/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BundleList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/paymentlinks"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PaymentLinks />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/compositeitems"}>
                <Suspense fallback={<Waiting />}>
                  <CompositeItemList />
                </Suspense>
              </Route>
              <Route path={"/compositeitem/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CompositeItem />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/compositeitem"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CompositeItem />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* <Route path={"/transferOrder"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <TransferOrder />
                  </Suspense>
                </ConfirmProvider>
              </Route> */}
              <Route path={"/createbundle/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductBundle createMode={true} />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/bundle/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductBundle createMode={false} />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/productgroups"}>
                <Suspense fallback={<Waiting />}>
                  <ProductGroupList />
                </Suspense>
              </Route>
              <Route path={"/productgroup/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductGroup />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/productgroup"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductGroup />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/packages"}>
                <Suspense fallback={<Waiting />}>
                  <Packages showAddOns={false} />
                </Suspense>
              </Route>
              {/* <Route path={"/ChatbotScreen"}>
              <Suspense fallback={<Waiting />}>
                <ChatbotScreen showAddOns={false} />
              </Suspense>
            </Route> */}
              <Route path={"/productPriceList"}>
                <Suspense fallback={<Waiting />}>
                  <ProductPriceList />
                </Suspense>
              </Route>
              <Route path={"/createtransaction"}>
                <Suspense fallback={<Waiting />}>
                  <CreateTransaction />
                </Suspense>
              </Route>
              <Route path={"/createtransaction2/:transactionIdentifier"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <JournalEntries />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/createtransaction2"}>
                <Suspense fallback={<Waiting />}>
                  <JournalEntries />
                </Suspense>
              </Route>
              <Route path={"/createProductPriceList/:id"}>
                <Suspense fallback={<Waiting />}>
                  <CreateProductPriceList />
                </Suspense>
              </Route>
              <Route path={"/createProductPriceList"}>
                <Suspense fallback={<Waiting />}>
                  <CreateProductPriceList />
                </Suspense>
              </Route>
              <Route path={"/packageaddons"}>
                <Suspense fallback={<Waiting />}>
                  <Packages showAddOns={true} />
                </Suspense>
              </Route>
              <Route path="/linkedtype/ProductCategory/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <LinkedType typeName={"ProductCategory"} typeDescription={"Item category"} isModal={false} />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/itemcategories">
                <Suspense fallback={<Waiting />}>
                  <LinkedTypeList typeName={"ProductCategory"} />
                </Suspense>
              </Route>
              <Route path="/unitconversions/:code">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UnitConversionList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/ewaybills">
                <Suspense fallback={<Waiting />}>
                  <EwayBillList />
                </Suspense>
              </Route>
              <Route path="/einvoices">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <EInvoiceList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/purchasereport">
                <Suspense fallback={<Waiting />}>
                  <ItemWisePurchaseData />
                </Suspense>
              </Route>
              <Route path="/salesreport">
                <Suspense fallback={<Waiting />}>
                  <ItemWiseSalesData />
                </Suspense>
              </Route>
              <Route path="/notauthorised">
                <Suspense fallback={<Waiting />}>
                  <UserNotAuthorised />
                </Suspense>
              </Route>
              <Route path="/newsalesorder">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateSalesOrder /> : <NewSalesOrder />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/incomelist">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <IncomeList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/incometypelist">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <IncomeTypeList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/income/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Income />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/income">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Income />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/salesorders"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <SalesOrderList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/salesorder/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateSalesOrder /> : <SalesOrder />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/user/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <User />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/user"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <User />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/users"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UserList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/salesbycustomer/:id"}>
                <Suspense fallback={<Waiting />}>
                  <SalesByCustomer />
                </Suspense>
              </Route>
              <Route path={"/salesbycustomer"}>
                <Suspense fallback={<Waiting />}>
                  <SalesByCustomer />
                </Suspense>
              </Route>
              <Route path={"/purchasebysupplier/:id"}>
                <Suspense fallback={<Waiting />}>
                  <PurchaseBySupplier />
                </Suspense>
              </Route>
              <Route path={"/purchasebysupplier"}>
                <Suspense fallback={<Waiting />}>
                  <PurchaseBySupplier />
                </Suspense>
              </Route>
              <Route path={"/uomlist"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UOMList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/uom/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UOM />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/uom"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UOM />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/expensetypes"}>
                <Suspense fallback={<Waiting />}>
                  <ExpenseTypeList />
                </Suspense>
              </Route>
              <Route path={"/expensetype/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ExpenseType />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/expensetype"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ExpenseType />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/incometype/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <IncomeType />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/incometype"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <IncomeType />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/subscription">
                <Suspense fallback={<Waiting />}>
                  <Subscription />
                </Suspense>
              </Route>
              <Route path={"/gstr1"}>
                <Suspense fallback={<Waiting />}>
                  <GSTR1 />
                </Suspense>
              </Route>
              <Route path={"/gstr2a"}>
                <Suspense fallback={<Waiting />}>
                  <GSTR2A />
                </Suspense>
              </Route>
              <Route path={"/gstr3b"}>
                <Suspense fallback={<Waiting />}>
                  <GSTR3B />
                </Suspense>
              </Route>
              <Route path={"/trackreturns"}>
                <Suspense fallback={<Waiting />}>
                  <TrackReturns />
                </Suspense>
              </Route>
              <Route path={"/bankstatement/:id"}>
                <Suspense fallback={<Waiting />}>
                  <BankStatement />
                </Suspense>
              </Route>
              <Route path={"/bankstatement"}>
                <Suspense fallback={<Waiting />}>
                  <BankStatement />
                </Suspense>
              </Route>
              <Route path={"/accountinghistory/:accountNumber"}>
                <Suspense fallback={<Waiting />}>
                  <AccountTransactionHistory />
                </Suspense>
              </Route>
              <Route path={"/accountingsummary"}>
                <Suspense fallback={<Waiting />}>
                  {/*  <ChartOfAccounts /> */}
                  <NewChartOfAccounts />
                </Suspense>
              </Route>
              <Route path={"/chequeprintsettings"}>
                <Suspense fallback={<Waiting />}>
                  <ChequePrintSettings />
                </Suspense>
              </Route>
              <Route path={"/barcodeprintsettings/:id"}>
                <Suspense fallback={<Waiting />}>
                  <BarcodeTemplateSettings />
                </Suspense>
              </Route>
              <Route path={"/barcodeprintsettings"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BarcodeTemplateSettings />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/barcodetemplates">
                <Suspense fallback={<Waiting />}>
                  <BarcodeTemplates />
                </Suspense>
              </Route>
              <Route path={"/customerinvoices/:id"}>
                <InvoiceListByCustomer />
              </Route>
              <Route path={"/customergroupinvoices/:id"}>
                <Suspense fallback={<Waiting />}>
                  <InvoiceListByCustomerGroup />
                </Suspense>
              </Route>
              <Route path={"/salespersoninvoices/:id"}>
                <Suspense fallback={<Waiting />}>
                  <InvoiceListByEmployee />
                </Suspense>
              </Route>
              <Route path={"/tradingaccount"}>
                <Suspense fallback={<Waiting />}>
                  <ProfitAndLossStatement />
                </Suspense>
              </Route>
              <Route path={"/printcheque"}>
                <Suspense fallback={<Waiting />}>
                  <PrintCheque />
                </Suspense>
              </Route>
              <Route path={"/generatebarcodes"}>
                <Suspense fallback={<Waiting />}>
                  <GenerateBarcode />
                </Suspense>
              </Route>
              {/*     <Route path={"/chequeprintsettings"}>
              <ChequePrintSettings></ChequePrintSettings>
            </Route> */}
              <Route path={"/notificationslist/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <NotificationsList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path={"/linkedtypes/:typename/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <LinkedType />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/lowstockitems">
                <Suspense fallback={<Waiting />}>
                  <LowStockItems />
                </Suspense>
              </Route>
              <Route path="/outofstockitems">
                <Suspense fallback={<Waiting />}>
                  <OutOfStockItems />
                </Suspense>
              </Route>
              <Route path="/inventory">
                <Suspense fallback={<Waiting />}>
                  <Inventory />
                </Suspense>
              </Route>
              <Route path="/inventorybydate">
                <Suspense fallback={<Waiting />}>
                  <InventoryByDate />
                </Suspense>
              </Route>
              <Route path="/inventoryByProductBetwenDates">
                <Suspense fallback={<Waiting />}>
                  <InventoryByProductBetwenDates />
                </Suspense>
              </Route>
              <Route path="/addQtyBasedPricing">
                <Suspense fallback={<Waiting />}>
                  <AddQtyBasedPricing />
                </Suspense>
              </Route>
              <Route path="/shopifyinventorysync">
                <Suspense fallback={<Waiting />}>
                  <ShopifyInventorySync />
                </Suspense>
              </Route>
              <Route path="/pricelist">
                <Suspense fallback={<Waiting />}>
                  <PriceList />
                </Suspense>
              </Route>

              <Route path="/stockadjustment/:id">
                <Suspense fallback={<Waiting />}>
                  <StockAdjustment />
                </Suspense>
              </Route>

              <Route path="/stockadjustment">
                <Suspense fallback={<Waiting />}>
                  <StockAdjustment />
                </Suspense>
              </Route>

              <Route path="/stockadjustments">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <StockAdjustmentList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* If the current URL is /home, this route is rendered
              while the rest are ignored */}
              <Route path="/home">
                <Home></Home>
              </Route>

              <Route path="/add-company">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Company />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/user-profile">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <UserProfile />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              {/* <Route path="/appsettings">
              <ApplicationSettings></ApplicationSettings>
            </Route> */}


              {/* Note how these two routes are ordered. The more specific
              path="/customer/:id" comes before path="/customer" so that
              route will render when viewing an individual customer */}

              {/* Puchase order start */}
              <Route path="/purchaseorder/:id/:saveAndPrint">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdatePurchaseOrder /> : <PurchaseOrder />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/purchaseorder/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdatePurchaseOrder /> : <PurchaseOrder />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/convertpurchaseorder/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PurchaseBillNumber />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newpurchaseorder">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreatePurchaseOrder /> : <NewPurchaseOrder />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/purchaseorders">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      safeBool(displayDataGrid) ? <PurchaseOrderList /> : <OrdersListView entityName={PURCHASE_ORDER_ENTITY}
                        referenceType={SUPPLIER_ENTITY}
                        pageHeading={"Purchase orders"}
                        pageIcon={"PurchaseOrderIcon"}
                        key={7}
                        headingColor="#CCE7FF"
                      />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* Puchase order end */}


              {/* Puchase bills start */}
              <Route path="/purchasebill/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdatePurchaseBill /> : <PurchaseBill />
                    }
                  </Suspense>
                </ConfirmProvider>

              </Route>

              <Route path="/newpurchasebill">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreatePurchaseBill /> : <NewPurchaseBill />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/purchaseinvoices">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      safeBool(displayDataGrid) ? <PurchaseBillList /> : <OrdersListView entityName={PURCHASE_BILL_ENTITY}
                        referenceType={SUPPLIER_ENTITY}
                        pageHeading={"Purchase Invoices"}
                        pageIcon={"PurchaseInvoicesIcon"}
                        key={2}
                        headingColor="#CCE7FF"
                      />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* Puchase bills end */}

              {/* DebitNote start */}
              <Route path="/debitnote/:id/:saveAndPrint">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateDebitNote /> : <DebitNote />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/debitnote/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateDebitNote /> : <DebitNote />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newdebitnote/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateDebitNote /> : <NewDebitNote />
                    }
                    {/* <NewDebitNote /> */}
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/newdebitnote">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {/* <NewDebitNote /> */}
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateDebitNote /> : <NewDebitNote />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/debitnotes">
                <Suspense fallback={<Waiting />}>
                  {
                    safeBool(displayDataGrid) ? <DebitNoteList /> : <OrdersListView entityName={DEBIT_NOTE_ENTITY}
                      referenceType={SUPPLIER_ENTITY}
                      pageHeading={"Debit notes"}
                      pageIcon={"DebitNote"}
                      key={6}
                      headingColor="#FFEEBA"
                    />
                  }

                </Suspense>
              </Route>
              {/* DebitNote end */}

              {/* CreditNote start */}
              <Route path="/creditnote/:id/:saveAndPrint">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateCreditNote /> : <CreditNote />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/creditnote/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateCreditNote /> : <CreditNote />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newcreditnote/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateCreditNote /> : <NewCreditNote />
                    }
                    {/* <NewCreditNote /> */}
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newcreditnote">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateCreditNote /> : <NewCreditNote />
                    }
                    {/* <NewCreditNote /> */}
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/creditnotes">
                <Suspense fallback={<Waiting />}>
                  {
                    safeBool(displayDataGrid) ? <CreditNoteList /> : <OrdersListView entityName={CREDIT_NOTE_ENTITY}
                      referenceType={CUSTOMER_ENTITY}
                      pageHeading={"Credit notes"}
                      pageIcon={"CreditNote"}
                      key={5}
                      headingColor="#FFEEBA"
                    />
                  }

                </Suspense>
              </Route>
              {/* CreditNote end */}

              {/* quotation bills start */}
              <Route path="/quotation/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateQuotation /> : <Quotation />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newquotation">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateQuotation /> : <NewQuotation />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/quotations">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      safeBool(displayDataGrid) ? <QuotationList /> : <OrdersListView entityName={QUOTATION_ENTITY}
                        referenceType={CUSTOMER_ENTITY}
                        pageHeading={"Quotations"}
                        pageIcon={"QuotationIcon"}
                        key={3}
                        headingColor="#C8E6C9"
                      />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* quotation bills end */}

              {/* deliverychallan bills start */}
              <Route path="/deliverychallan/:id/:saveAndPrint">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateDeliveryChallan /> : <DeliveryChallan />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/deliverychallan/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateDeliveryChallan /> : <DeliveryChallan />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newdeliverychallan">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateDeliveryChallan /> : <NewDeliveryChallan />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/deliverychallans">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      safeBool(displayDataGrid) ? <DeliveryChallanList /> : <OrdersListView entityName={DELIVERY_CHALLAN_ENTITY}
                        referenceType={CUSTOMER_ENTITY}
                        pageHeading={"Delivery challans"}
                        pageIcon={"QuotationIcon"}
                        key={4}
                        headingColor="#C8E6C9"
                      />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/getDeliveryChallanListByProductId/:productId">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <GetDeliveryChallanListByProductId />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* quotation bills end */}

              {/* Invoice start */}
              <Route path="/invoice/:id/:saveAndPrint">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateInvoice /> : <Invoice />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/invoice/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <UpdateInvoice /> : <Invoice />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/newinvoice">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      (safeBool(authContext?.settings?.enableBetaFeatures) && !mobileDevice) ? <CreateInvoice /> : <NewInvoice />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/invoicestoday">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <SalesToday gridView={safeBool(displayDataGrid)} />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/invoices">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {
                      safeBool(displayDataGrid) ? <InvoiceList /> : <OrdersListView entityName={INVOICE_ENTITY}
                        referenceType={CUSTOMER_ENTITY}
                        pageHeading={"Sales Invoices"}
                        pageIcon={"InvoiceIcon"}
                        key={0}
                        headingColor="#C8E6C9"
                      />
                    }
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/deletedinvoices">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <InvoiceDeletedList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* Invoice end */}


              {/* Payments start */}
              <Route path="/customerrefund">
                <Suspense fallback={<Waiting />}>
                  <AdvanceRefund referenceType={CUSTOMER_ENTITY} />
                </Suspense>
              </Route>
              <Route path="/supplierrefund">
                <Suspense fallback={<Waiting />}>
                  <AdvanceRefund referenceType={SUPPLIER_ENTITY} />
                </Suspense>
              </Route>
              <Route path="/selectRefundType/:referenceType/:id">
                <Suspense fallback={<Waiting />}>
                  <SelectRefundType />
                </Suspense>
              </Route>
              <Route path="/refund/:referenceType/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Refund />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/refundAdvance/:referenceType/:id">
                <Suspense fallback={<Waiting />}>
                  <RefundAdvance />
                </Suspense>
              </Route>
              {/* receivepayment */}
              <Route path="/receiveadvance/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceiveAdvance />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/payadvance/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PayAdvance />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/receivepayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceivePayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/receivepayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceivePayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/makepayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <MakePayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/receiveadvance">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceiveAdvance />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/payadvance">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PayAdvance />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/selectparty/:referenceType">
                <Suspense fallback={<Waiting />}>
                  <SelectParty />
                </Suspense>
              </Route>
              <Route path="/batchdetail/:referenceType/:id">
                <Suspense fallback={<Waiting />}>
                  <BatchDetail />
                </Suspense>
              </Route>
              <Route path="/addpayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <InvoicePayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/advancepayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <AdvancePayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/receivables">
                <Suspense fallback={<Waiting />}>
                  <ReceivablesList />
                </Suspense>
              </Route>
              <Route path="/transactionList">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <TransactionList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/paymentsinlist">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PaymentsInList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/paymentsinlist/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PaymentsInList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/paymentHistory/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceivablesHistory />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/paymentHistory">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ReceivablesHistory />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/addpayable/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BillPayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/payables">
                <Suspense fallback={<Waiting />}>
                  <PayablesList />
                </Suspense>
              </Route>
              <Route path="/paymentsoutlist">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PaymentsOutList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/paymentsoutlist/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PaymentsOutList />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/payablesHistory/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PayablesHistory />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/payablesHistory">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <PayablesHistory />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              <Route path="/batchpayment/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BatchPayment />
                  </Suspense>
                </ConfirmProvider>
              </Route>
              {/* <Route path="/subscriberBranch">
              <ConfirmProvider>
                <Suspense fallback={<Waiting />}>
                  <SubscriberBranchList />
                </Suspense>
              </ConfirmProvider>
            </Route>
            <Route path="/newsubscriberbranch">
              <ConfirmProvider>
                <Suspense fallback={<Waiting />}>
                  <NewSubscriberBranch />
                </Suspense>
              </ConfirmProvider>
            </Route> */}
              {/* Payments end */}


              {/* Expense related start*/}
              <Route path="/expense/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Expense />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/expense">
                <Suspense fallback={<Waiting />}>
                  <Expense />
                </Suspense>
              </Route>

              <Route path="/expensestoday">
                <Suspense fallback={<Waiting />}>
                  <ExpensesToday />
                </Suspense>
              </Route>

              <Route path="/expensesthismonth">
                <Suspense fallback={<Waiting />}>
                  <ExpensesThisMonth />
                </Suspense>
              </Route>
              <Route path="/expenses">
                <Suspense fallback={<Waiting />}>
                  <ExpenseList />
                </Suspense>
              </Route>
              {/* Expense related end*/}

              <Route path="/salesregister" >
                <Suspense fallback={<Waiting />}>
                  <SalesRegister />
                </Suspense>
              </Route>
              <Route path="/purchaseregister">
                <Suspense fallback={<Waiting />}>
                  <PurchaseRegister />
                </Suspense>
              </Route>
              <Route path="/salesByProduct" >
                <Suspense fallback={<Waiting />}>
                  <SalesByProduct />
                </Suspense>
              </Route>
              <Route path="/salesByProductAndCategory/:categoryId">
                <Suspense fallback={<Waiting />}>
                  <SalesByProductAndCategory />
                </Suspense>
              </Route>
              <Route path="/salesByProductCategory" >
                <Suspense fallback={<Waiting />}>
                  <SalesByProductCategory />
                </Suspense>
              </Route>
              <Route path="/invoicepicklist/:employeeId">
                <Suspense fallback={<Waiting />}>
                  <InvoicePickList />
                </Suspense>
              </Route>
              <Route path="/invoicepicklist">
                <Suspense fallback={<Waiting />}>
                  <InvoicePickList />
                </Suspense>
              </Route>
              <Route path="/invoiceListByProductId/:id">
                <Suspense fallback={<Waiting />}>
                  <InvoiceListByProductId />
                </Suspense>
              </Route>
              <Route path="/purchaseBillByProductId/:id">
                <Suspense fallback={<Waiting />}>
                  <PurchaseBillListByProductId />
                </Suspense>
              </Route>
              <Route path="/purchaseByProductAndCategory/:categoryId" >
                <Suspense fallback={<Waiting />}>
                  <PurchaseByProductAndCategory />
                  {/* <PurchaseByProduct /> */}
                </Suspense>
              </Route>
              <Route path="/purchaseByProduct" >
                <Suspense fallback={<Waiting />}>
                  <PurchaseByProduct />
                </Suspense>
              </Route>
              <Route path="/purchaseByProductCategory" >
                <Suspense fallback={<Waiting />}>
                  <PurchaseByProductCategory />
                </Suspense>
              </Route>
              <Route path="/customertransactions/:id">
                <Suspense fallback={<Waiting />}>
                  <CustomerTransactionHistory />
                </Suspense>
              </Route>
              <Route path="/suppliertransactions/:id">
                <Suspense fallback={<Waiting />}>
                  <SupplierTransactionHistory />
                </Suspense>
              </Route>
              <Route path="/customertransactions">
                <Suspense fallback={<Waiting />}>
                  <CustomerTransactionHistory />
                </Suspense>
              </Route>
              <Route path="/suppliertransactions">
                <Suspense fallback={<Waiting />}>
                  <SupplierTransactionHistory />
                </Suspense>
              </Route>
              <Route path="/customerstatements/:id">
                <Suspense fallback={<Waiting />}>
                  <CustomerTransactionStatement />
                </Suspense>
              </Route>
              <Route path="/customerstatements">
                <Suspense fallback={<Waiting />}>
                  <CustomerTransactionStatement />
                </Suspense>
              </Route>
              <Route path="/supplierstatements/:id">
                <Suspense fallback={<Waiting />}>
                  <SupplierTransactionStatement />
                </Suspense>
              </Route>
              <Route path="/supplierstatements">
                <Suspense fallback={<Waiting />}>
                  <SupplierTransactionStatement />
                </Suspense>
              </Route>
              <Route path="/reports">
                {/* <Reports /> */}
                <Suspense fallback={<Waiting />}>
                  <NewReport />
                </Suspense>
              </Route>

              <Route path="/dashboard">
                <Suspense fallback={<Waiting />}>
                  <Dashboard />
                </Suspense>
              </Route>

              <Route path="/supplierBalanceList">
                <Suspense fallback={<Waiting />}>
                  <SupplierBalanceList />
                </Suspense>
              </Route>

              <Route path="/customerBalanceList">
                <Suspense fallback={<Waiting />}>
                  <CustomerBalanceList />
                </Suspense>
              </Route>

              {/* Bank account related start*/}
              <Route path="/bankaccount/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <BankAccount />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/bankaccount">
                <Suspense fallback={<Waiting />}>
                  <BankAccount />
                </Suspense>
              </Route>

              <Route path="/bankaccounts">
                <Suspense fallback={<Waiting />}>
                  <BankAccountList />
                </Suspense>
              </Route>
              {/* Bank account related end*/}

              {/* Customer related start*/}
              <Route path="/customer/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Customer />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/customer">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    {/* <ManageCustomer isModal={false} prefillName={""} /> */}
                    <Customer />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/customers">
                <Suspense fallback={<Waiting />}>
                  <CustomerList />
                  {/* <CustomerOrderList /> */}
                </Suspense>
              </Route>

              <Route path="/deliveryaddress/:type/:id">
                <Suspense fallback={<Waiting />}>
                  <DeliveryAddress />
                </Suspense>
              </Route>
              {/* Customer related end*/}

              {/* Customer group related start*/}
              <Route path="/customergroup/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <CustomerGroup />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/customergroup">
                <Suspense fallback={<Waiting />}>
                  <CustomerGroup />
                </Suspense>
              </Route>

              <Route path="/customergroups">
                <Suspense fallback={<Waiting />}>
                  <CustomerGroupList />
                </Suspense>
              </Route>
              {/* Customer group related end*/}


              {/* Supplier related start*/}
              <Route path="/supplier/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Supplier />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/supplier">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Supplier />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/suppliers">
                <Suspense fallback={<Waiting />}>
                  <SupplierList />
                  {/* <SupplierOrderList /> */}
                </Suspense>
              </Route>
              {/* Supplier related end*/}

              {/* Product related start*/}
              <Route path="/product/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Product />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/product">
                <Suspense fallback={<Waiting />}>
                  <Product />
                </Suspense>
              </Route>

              <Route path="/products">
                <Suspense fallback={<Waiting />}>
                  <ProductList />
                </Suspense>
              </Route>
              {/* Product related end*/}

              {/* Warehouse related start*/}
              <Route path="/warehouse/:id">
                <Suspense fallback={<Waiting />}>
                  <Warehouse />
                </Suspense>
              </Route>

              <Route path="/warehouse">
                <Suspense fallback={<Waiting />}>
                  <Warehouse />
                </Suspense>
              </Route>

              <Route path="/warehouses">
                <Suspense fallback={<Waiting />}>
                  <WarehouseList />
                </Suspense>
              </Route>
              {/* Warehouse related end*/}

              <Route path="/changePassword">
                <Suspense fallback={<Waiting />}>
                  <ChangePassword />
                </Suspense>
              </Route>

              <Route path="/invoice">
                <Suspense fallback={<Waiting />}>
                  <NewInvoice />
                </Suspense>
              </Route>
              <Route path="/pendingbills/:id" >
                <Suspense fallback={<Waiting />}>
                  <SupplierPendingStatement />
                </Suspense>
              </Route>
              <Route path="/pendinginvoices/:id" >
                <Suspense fallback={<Waiting />}>
                  <CustomerPendingStatement />
                </Suspense>
              </Route>
              <Route path="/partywisependingbills/:id" >
                <Suspense fallback={<Waiting />}>
                  <SupplierWisePendingBills />
                </Suspense>
              </Route>
              <Route path="/partywisependingInvoice/:id" >
                <Suspense fallback={<Waiting />}>
                  <CustomerWisePendingInvoices />
                </Suspense>
              </Route>
              <Route path="/partywisependingbills" >
                <Suspense fallback={<Waiting />}>
                  <SupplierWisePendingBills />
                </Suspense>
              </Route>
              <Route path="/partywisependingInvoice" >
                <Suspense fallback={<Waiting />}>
                  <CustomerWisePendingInvoices />
                </Suspense>
              </Route>
              <Route path="/importmasterdata">
                <Suspense fallback={<Waiting />}>
                  <ImportMasterData />
                </Suspense>
              </Route>

              <Route path="/productcatalog/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductImageController />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/productcatalog">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <ProductImageController />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/userList">
                <Suspense fallback={<Waiting />}>
                  <UserList />
                </Suspense>
              </Route>

              <Route path="/employees">
                <Suspense fallback={<Waiting />}>
                  <Employees />
                </Suspense>
              </Route>

              <Route path="/employee/:id">
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Employee />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path="/employee">
                <Suspense fallback={<Waiting />}>
                  <Employee />
                </Suspense>
              </Route>
              <Route path="/salesorderfullfillmentstatus">
                <Suspense fallback={<Waiting />}>
                  <SalesOrderFullfillmentStatus isList={true} />
                </Suspense>
              </Route>
              <Route path="/purchaseorderfullfillmentstatus">
                <Suspense fallback={<Waiting />}>
                  <PurchaseOrderFullfillmentStatus isList={true} />
                </Suspense>
              </Route>
              <Route path="/renewSubscription">
                <Suspense fallback={<Waiting />}>
                  <RenewSubscription />
                </Suspense>
              </Route>
              <Route path={"/projectlist"}>
                <Suspense fallback={<Waiting />}>
                  <ProjectList />
                </Suspense>
              </Route>

              <Route path={"/project/:id"}>
                <ConfirmProvider>
                  <Suspense fallback={<Waiting />}>
                    <Project />
                  </Suspense>
                </ConfirmProvider>
              </Route>

              <Route path={"/project"}>
                <Suspense fallback={<Waiting />}>
                  <Project />
                </Suspense>
              </Route>

              <Route path={"/projectpnlreport"}>
                <Suspense fallback={<Waiting />}>
                  <ProjectWiseTransactionSummary />
                </Suspense>
              </Route>

              <Route path={"/pnlsummary"}>
                <Suspense fallback={<Waiting />}>
                  <ProfitAndLossStatement />
                </Suspense>
              </Route>

              {/* <Route path="/digitalInvoice">
              <Suspense fallback={<Waiting />}>
                <DigitalInvoice />
              </Suspense>
            </Route> */}

              {/* If none of the previous routes render anything,
              this route acts as a fallback.
  
              Important: A route with path="/" will *always* match
              the URL because all URLs begin with a /. So that's
              why we put this one last of all */}
              <Route path="/">
                <Suspense fallback={<Waiting />}>
                  {
                    authContext.currentUser.changePasswordAtLogon === true ?
                      <ChangePassword />
                      :
                      (authContext.moduleAccess(APP_MODULE_Dashboard).allowView ?
                        <Dashboard />
                        : <Home />)
                  }

                </Suspense>

              </Route>
            </Switch>
          </ErrorBoundary>

        </Layout>
      </>

    );
  }
}
