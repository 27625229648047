import React from 'react';
import { Box, Button, ButtonBase, Divider, Grid, IconButton, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "../../../styles"
import { EBCheckBox, LockIcon, PageHeader } from "../../UI/Controls/Controls";
import { SelectInput, TextInput, NumberInput, PasswordInput, TextButtonInput, EmailInput, MobilePasswordInput } from "../../UI/Controls/InputFields";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useContext, useEffect, useState } from "react";
import AppIcon from "../../UI/Controls/AppIcons";
import AuthContext from "../../Auth/AuthContext";
import WebAPI from "../../Base/WebAPI";
import useStore from "../../Base/Store";
import axios from "axios";
import { safeBool, safeString, isNullOrUndefined, safeInt, filterOptions, ifNullOrEmpty, isNullOrEmpty, lowerCaseFirstLetter, isEmptyObjectArray, ifNull } from '../../Base/Utils';
import shallow from 'zustand/shallow';
import { quotationFormats, invoiceFormats, purchaseOrderFormats, INVOICE_ENTITY, QUOTATION_ENTITY, CREDIT_NOTE_ENTITY, creditNotesFormats, DEBIT_NOTE_ENTITY, debitNotesFormats, DELIVERY_CHALLAN_ENTITY, deliveryChallanFormats, APP_MODULE_ManageWarehouse, INVOICE_COPY_HEADINGS, TAX_REG_COMPOSITION_SCHEME, PURCHASE_BILL_ENTITY, purchaseBillFormats, CUSTOMER_ENTITY, DEFAULT_SCREEN, salesOrderFormats, SALES_ORDER_ENTITY } from '../../Base/Common';
import { Autocomplete } from '@material-ui/lab';
import DialogWindow from '../../UI/Controls/DialogWindow';
import CustomerGroup from '../../Customer/CustomerGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SettingsHeader } from './controls/SettingsHeader';
import PdfPreview from './controls/PDFPreview';
import { PURCHASE_ORDER_ENTITY } from './../../Base/Common';
import SignatureCapture from './controls/SignatureCapture';
import './style.css';
import ImageUploadController from '../../UI/Controls/ImageUploadController';
import Tour from 'reactour';
import VoucherSettings from './VoucherSettings';
import { getPartyLiteList } from '../../Business/Party/PartyBusiness';
import SelectCustomer from '../../UI/Controls/Business/SelectCustomer';
import Customer from '../../Customer/Customer';
import POSvoucherSettings from './POSvoucherSettings';
import {reloadSubscriberInfo} from '../Subscriber'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tabpanel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `settings-tab-${index}`,
        'aria-controls': `settings-tabpanel-${index}`,
    };
}

export default function ApplicationSettings(props) {
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const classes = useStyles();
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);
    const [isSignatureChanged, setIsSignatureChanged] = useState(false);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const toggleSettingsDrawer = useStore(state => state.toggleSettingsDrawer);
    const [selectedFormatEntity, setSelectedFormatEntity] = useState('');
    const [voucherFormats, setVoucherFormats] = useState();
    const [companyProfileTab, toggleCompanyProfileTab] = useStore(state => [state.companyProfileTab, state.toggleCompanyProfileTab], shallow);

    const [selectedTab, setSelectedTab] = React.useState(companyProfileTab ? 3 : 0);
    const onboardingSettingSteps = [
        {
            selector: '.OnboardingScreenPreFixed',
            content: 'Enter a Invoice Prefix of your choice. Example: INV/23-24/.',
        },
        {
            selector: '.OnboardingScreenSequence',
            content: 'Enter the invoice sequence that you want your invoice number to start from. Example 101.',
        },
        {
            selector: '.OnboardingScreenInvoiceFormat',
            content: 'Select an invoice format of your choice, by previewing the availbale invoice formats.',
        },
        {
            selector: '.OnboardingScreenTermsAndCondition',
            content: 'Review and update your terms and conditions as per your choice. Note: This will be printed on your invoice.',
        },
        {
            selector: '.onBoardingSave',
            content: 'Click Save to save your settings.',
        },
    ];

    const handleChange = (event, newValue) => {
        toggleCompanyProfileTab(false);
        setSelectedTab(newValue);
    };



    const warehouseAccess = authContext.moduleAccess(APP_MODULE_ManageWarehouse);

    const [fieldValues, setFieldValues] = useState({
        "typeOfBusiness": "",
        "quotationPrefix": "",
        "quotationSequence": 0,
        "quotationFormat": "1",
        "quotationFormatDesc": "",
        "quotationCopies": 0,
        "roundOffQuotationTotal": true,
        "useLastQuotationPrice": true,
        "includeBatchDetailsInQuotation": false,
        "quotationGreetingMessage": "",
        "quotationTermsAndConditions": "",

        "salesOrderPrefix": "",
        "salesOrderSequence": 0,
        "salesOrderFormat": "1",
        "salesOrderDesc": "",
        "salesOrderCopies": 0,
        "roundOffSalesOrderTotal": true,
        "useLastSalesOrderPrice": true,
        "includeBatchDetailsInSalesOrder": false,
        "salesOrderGreetingMessage": "",
        "salesOrderTermsAndConditions": "",
        "enableSalesOrderNumberEdit": true,
        "enableSalesOrderPrefixEdit": false,

        "invoicePrefix": "",
        "invoiceSequence": 0,
        "enableInvoiceNumberEdit": false,
        "invoiceFormat": "22",
        "invoiceFormatDesc": "",
        "invoiceCopies": 0,
        "deliveryChallanCopies": 0,
        "roundOffInvoiceTotal": true,
        "useLastInvoicePrice": true,
        "includeBatchDetailsInInvoice": "1",
        "invoiceGreetingMessage": "",
        "invoiceTermsAndConditions": "",
        "useBatchSerialNoTracking": true,
        "useBillOfSupply": false,
        "signatureAndSeal": "",
        "signatureAndSealPreview": "",

        "creditNotePrefix": "",
        "creditNoteSequence": 0,
        "creditNoteFormat": "1",
        "creditNoteFormatDesc": "",
        "creditNoteCopies": 0,
        "roundOffCreditNoteTotal": true,
        "useLastCreditNotePrice": true,
        "includeBatchDetailsInCreditNote": true,
        "creditNoteGreetingMessage": "",
        "creditNoteTermsAndConditions": "",

        "debitNotePrefix": "",
        "debitNoteSequence": 0,
        "debitNoteFormat": "1",
        "debitNoteFormatDesc": "",
        "debitNoteCopies": 0,
        "roundOffDebitNoteTotal": true,
        "useLastDebitNotePrice": true,
        "includeBatchDetailsInDebitNote": true,
        "debitNoteGreetingMessage": "",
        "debitNoteTermsAndConditions": "",

        "deliveryChallanPrefix": "",
        "deliveryChallanSequence": 0,
        "deliveryChallanFormat": "1",
        "deliveryChallanFormatDesc": "",
        "deliveryChallanCopies": 0,
        "roundOffDeliveryChallanTotal": true,
        "useLastDeliveryChallanPrice": true,
        "includeBatchDetailsInDeliveryChallan": true,
        "deliveryChallanGreetingMessage": "",
        "deliveryChallanTermsAndConditions": "",

        "stockMovementsPrefix": "",
        "stockMovementsSequence": 0,
        "stockMovementsFormat": "1",
        "stockMovementsCopies": 0,
        "roundOffStockMovementsTotal": true,
        "useLastStockMovementsPrice": true,
        "includeBatchDetailsInStockMovements": true,
        "stockMovementsGreetingMessage": "",
        "stockMovementsTermsAndConditions": "",

        "purchaseBillPrefix": "",
        "purchaseBillSequence": 0,
        "purchaseBillFormat": "1",
        "purchaseBillFormatDesc": "",
        "purchaseBillCopies": 0,
        "roundOffPurchaseBillTotal": true,
        "useLastPurchaseBillPrice": true,
        "includeBatchDetailsInPurchaseBill": "",
        "purchaseBillGreetingMessage": "",
        "purchaseBillTermsAndConditions": "",

        "purchaseOrderPrefix": "",
        "purchaseOrderSequence": 0,
        "purchaseOrderFormat": "1",
        "purchaseOrderFormatDesc": "",
        "purchaseOrderCopies": 0,
        "roundOffPurchaseOrderTotal": true,
        "useLastPurchaseOrderPrice": true,
        "includeBatchDetailsInPurchaseOrder": "",
        "purchaseOrderGreetingMessage": "",
        "purchaseOrderTermsAndConditions": "",

        "enableQuotationNumberEdit": true,
        "enableQuotationPrefixEdit": false,

        "smtpHostName": "",
        "smtpEmailAddress": "",
        "smtpPassword": "",
        "smtpPortNumber": "",
        "subscriberLogo": safeString(authContext.currentSubscriber.logo),
        "subscriberLogoPreview": safeString(authContext.currentSubscriber.logo),
        "enableInventory": true,
        "isMultiWarehouse": false,
        "suppressStockWarnings": true,
        "useMaximumRetailPrice": false,
        "enableNotifications": false,
        "authorisedSignature": "",
        "shopifyStores": "",
        "shopifyStoreName": "",
        "shopifyStoreAPIKey": "",
        "shopifyStoreCostCenterId": 0,
        "shopifyStoreCostCenterName": "",
        "subscriptionAmount": 0,
        "decimalPlaces": 2,
        "allowDuplicateProductNames": false,
        "validateHSNCode": false,

        "additionalDetails": "",
        "preventNegativeStock": false,
        "allowFreeQuantity": false,
        "includeReferencePrice": false,
        "taxInclusivePricing": false,
        "enableSalesPersonModule": false,
        "enableCess": false,
        "showOverrides": false,
        "paperSize": "A4",
        "orderSequenceLeadingZeroes": 5, //DO NOT USE
        "includeTaxSummaryQuotation": true,
        "deafultInvoiceCashBill": false,
        "enableInvoicePrefixEdit": false,
        "enableDeliveryChallanPrefixEdit": true,
        "enableDeliveryChallanNumberEdit": true,
        "includeTaxSummaryDeliveryChallan": false,
        "includeAmountDeliveryChallan": false,
        "printPaymentInfo": false,
        "printTotalPendingAmount": false,
        "watermarkLabel": "",
        "waterMarkAngle": 0,
        "invoiceCopyHeading1": "(ORIGINAL FOR RECIPIENT)",
        "invoiceCopyHeading2": "(DUPLICATE FOR TRANSPORTER)",
        "invoiceCopyHeading3": "(DUPLICATE FOR SUPPLIER)",
        "invoiceCopyHeading4": "(EXTRA COPY)",
        "invoiceCopyHeading5": "(EXTRA COPY)",
        "invoiceDocumentHeading": "",
        "quotationDocumentHeading": "",
        "enableBetaFeatures": true,
        "printItemCode": false,
        // "autoHideSideMenu": true,
        "showQuantityDescription": false,
        "showTotalQuantityDescription": false,
        "sendAutomaticSMS": true,
        "AllowTaxRateEdit": true,
        "hideSealAndSignature": false,

        // voucher length 
        "invoiceVoucherLength": 5,
        "quotationVoucherLength": 5,
        "salesOrderVoucherLength": 5,
        "deliveryChallanVoucherLength": 5,
        "creditNoteVoucherLength": 5,
        "debitNoteVoucherLength": 5,
        "purchaseOrderVoucherLength": 5,
        "purchaseBillVoucherLength": 5,
        "HSNCodeSize": "",
        "defaultCashBillCustomer": 0,

        "profitMarginPercentage": 0,

        "loginDisplayPage": "",
        "isLoginDisplay": false,
        "allowToChangeOrderDate": false,

        // voucher format setting 
        "invoiceFormatSettings": "",
        // invoice columns defination
        "invoiceFormatColumnDefination": "",

        "POSinvoiceSettings": "",

        // quotation format setting
        "quotationFormatSettings": "",
        // quotation columns defination
        "quotationFormatColumnDefination": "",


        // credit note format setting
        "creditNoteFormatSettings": "",
        // credit note columns defination
        "creditNoteFormatColumnDefination": "",


        // credit note format setting
        "debitNoteFormatSettings": "",
        // credit note columns defination
        "debitNoteFormatColumnDefination": ""

    });
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [customerGroupList, setCustomerGroupList] = useState([]);
    const [selectedCostCenter, setSelectedCostCenter] = useState(null);
    const dialogResponse = useStore(state => state.dialogResponse);
    const [isTourOpen, setIsTourOpen] = useState(true);
    const HSNCodeSize = authContext.masterData.filter(md => md.type === 'HSNCodeSize');
    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);

    const [selectedTabEntity, setSelectedTabEntity] = useState('');


    const handlePartySelectionChange = (val) => {
        setSelectedParty(val);
    }


    const handleDialogClosed = (dialogName, setFieldValue) => {
        try {
            if (dialogResponse != null) {
                switch (dialogName) {
                    case "customerDialog":
                        parties.push(dialogResponse);
                        handlePartySelectionChange(dialogResponse);
                        break;
                    case 'customerGroupDialog':
                        customerGroupList.push(dialogResponse);
                        setSelectedCostCenter(dialogResponse);
                        setFieldValue('shopifyStoreCostCenterId', dialogResponse.id);
                        setFieldValue('shopifyStoreCostCenterName', dialogResponse.name);
                        break;
                    case "pdfPreviewDialog":
                        setFieldValue(`${lowerCaseFirstLetter(selectedFormatEntity)}Format`, dialogResponse.value);
                        setFieldValue(`${lowerCaseFirstLetter(selectedFormatEntity)}FormatDesc`, dialogResponse.name);
                        break;
                    case "signatureDialog":
                        setIsSignatureChanged(true);
                        setFieldValue('authorisedSignature', safeString(dialogResponse));
                        break;
                    case "voucherSettingsDialog":
                        switch (dialogResponse.entityType) {
                            case "Invoice":
                                setFieldValue('invoiceFormatSettings', dialogResponse.invoiceFormatSettings);
                                setFieldValue('invoiceFormatColumnDefination', dialogResponse.invoiceFormatColumnDefination)
                                break;
                            case "Quotation":
                                setFieldValue('quotationFormatSettings', dialogResponse.quotationFormatSettings);
                                setFieldValue('quotationFormatColumnDefination', dialogResponse.quotationFormatColumnDefination)
                                break;
                            case "CreditNote":
                                setFieldValue('creditNoteFormatSettings', dialogResponse.creditNoteFormatSettings);
                                setFieldValue('creditNoteFormatColumnDefination', dialogResponse.creditNoteFormatColumnDefination);
                                break;
                            case "DebitNote":
                                setFieldValue('debitNoteFormatSettings', dialogResponse.debitNoteFormatSettings);
                                setFieldValue('debitNoteFormatColumnDefination', dialogResponse.debitNoteFormatColumnDefination);
                                break;
                            default:
                                showAlert('invalid format', "error");
                                break;
                        }
                        break;
                    case "POSvoucherSettingsDialog":
                        switch (dialogResponse.entityType) {
                            case "Invoice":
                                setFieldValue('POSinvoiceSettings', dialogResponse.POSvoucherSettingsData);
                                break;
                            default:
                                showAlert('invalid format', "error");
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
            toggleCompanyProfileTab(false);
        }
    };

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            if (authContext.token != null) {
                await fetchData(source);
            }
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);





    const fetchData = async (source) => {
        try {
            setWaiting(true);
            const costCenterReponse = await WebAPI.get('/CostCenter/GetCostCenterList/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
            if (!!costCenterReponse.data) {
                if (!!costCenterReponse.data.code && costCenterReponse.data.code > 0) {
                    showAlert(costCenterReponse.data.message, 'error');
                    return;
                }
                setCustomerGroupList(costCenterReponse.data);
            }

            const partyList = await getPartyLiteList(CUSTOMER_ENTITY, authContext, showAlert, source?.token);
            setParties(partyList);

            const response = await WebAPI.get('/Subscriber/GetSubscriberById/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
            if (!!response.data) {
                if (!!response.data.code && response.data.code > 0) {
                    showAlert(response.data.message, 'error');
                    return;
                }
                if (!!response.data.subscriberSettings) {
                    var settings = { ...fieldValues };
                    let currentSettings = response.data.subscriberSettings;
                    Object.getOwnPropertyNames(settings).map(p => {
                        if (p.includes("Sequence")) {
                            settings[p] = currentSettings[p] + 1;
                        } else {
                            settings[p] = currentSettings[p];
                        }
                    });

                    let quoteFormat = quotationFormats.find(f => f.value === ifNullOrEmpty(settings.quotationFormat, "1"));
                    if (!isNullOrUndefined(quoteFormat)) {
                        settings.quotationFormatDesc = safeString(quoteFormat.name);
                    }

                    let invFormat = invoiceFormats.find(f => f.value === ifNullOrEmpty(settings.invoiceFormat, "1"));
                    if (!isNullOrUndefined(invFormat)) {
                        settings.invoiceFormatDesc = safeString(invFormat.name);
                    }

                    if (isNullOrEmpty(settings.purchaseOrderFormat)) {
                        settings.purchaseOrderFormat = "1";
                    }

                    if (isNullOrEmpty(settings.purchaseBillFormat)) {
                        settings.purchaseBillFormat = "1";
                    }

                    let pbFormat = purchaseBillFormats.find(f => f.value === ifNullOrEmpty(settings.purchaseBillFormat, "1"));
                    if (!isNullOrUndefined(pbFormat)) {
                        settings.purchaseBillFormatDesc = safeString(pbFormat.name);
                    }


                    if (isNullOrEmpty(settings.salesOrderFormat)) {
                        settings.salesOrderFormat = "1";
                    }

                    let soFormat = salesOrderFormats.find(f => f.value === ifNullOrEmpty(settings.salesOrderFormat, "1"));
                    if (!isNullOrUndefined(soFormat)) {
                        settings.salesOrderDesc = safeString(soFormat.name);
                    }

                    let poFormat = purchaseOrderFormats.find(f => f.value === ifNullOrEmpty(settings.purchaseOrderFormat, "1"));
                    if (!isNullOrUndefined(poFormat)) {
                        settings.purchaseOrderFormatDesc = safeString(poFormat.name);
                    }

                    let cnFormat = creditNotesFormats.find(f => f.value === ifNullOrEmpty(settings.creditNoteFormat, "1"));
                    if (!isNullOrUndefined(cnFormat)) {
                        settings.creditNoteFormatDesc = safeString(cnFormat.name);
                    }

                    let dnFormat = debitNotesFormats.find(f => f.value === ifNullOrEmpty(settings.debitNoteFormat, "1"));
                    if (!isNullOrUndefined(dnFormat)) {
                        settings.debitNoteFormatDesc = safeString(dnFormat.name)
                    }

                    let dcFormat = deliveryChallanFormats.find(f => f.value === ifNullOrEmpty(settings.deliveryChallanFormat, "1"));
                    if (!isNullOrUndefined(dcFormat)) {
                        settings.deliveryChallanFormatDesc = safeString(dcFormat.name);
                    }

                    settings.subscriberLogo = safeString(authContext.currentSubscriber.logo);
                    settings.subscriberLogoPreview = safeString(authContext.currentSubscriber.logo);

                    if (!isNullOrEmpty(settings.shopifyStores)) {
                        const shopifyStoreData = JSON.parse(settings.shopifyStores);
                        // for (let i = 0; i < shopifyStoreData.stores.length; i++) {
                        const storeSetting = shopifyStoreData.stores[0];
                        settings.shopifyStoreName = safeString(storeSetting.storeName);
                        settings.shopifyStoreAPIKey = safeString(storeSetting.storeAPIKey);
                        settings.shopifyStoreCostCenterId = safeInt(storeSetting.storeCostCenterId);
                        if (settings.shopifyStoreCostCenterId > 0) {
                            if (!isEmptyObjectArray(costCenterReponse.data)) {
                                setSelectedCostCenter(costCenterReponse.data.find(c => c.id === settings.shopifyStoreCostCenterId));
                            }

                        }
                        // }
                    }
                    // settings into else coondtion but here it's override so checking and seeting the default value for first login user
                    if (isNullOrUndefined(settings.AllowTaxRateEdit)) {
                        settings.AllowTaxRateEdit = true
                    }

                    // if (isNullOrUndefined(settings.autoHideSideMenu)) {
                    //     settings.autoHideSideMenu = true
                    // }

                    if (isNullOrUndefined(settings.enableBetaFeatures)) {
                        settings.enableBetaFeatures = true
                    }


                    if (isNullOrUndefined(settings.invoiceDocumentHeading)) {
                        if (response.data.taxRegistrationTypeId === TAX_REG_COMPOSITION_SCHEME) {
                            settings.invoiceDocumentHeading = "Bill of Supply";
                        } else {
                            settings.invoiceDocumentHeading = "TAX INVOICE";
                        }
                    }

                    if (isNullOrUndefined(settings.quotationDocumentHeading)) {
                        settings.quotationDocumentHeading = "Quotation";
                    }

                    if (!isNullOrEmpty(settings.additionalDetails)) {
                        const additionalDetailData = JSON.parse(settings.additionalDetails);

                        settings.preventNegativeStock = safeBool(additionalDetailData.preventNegativeStock);
                        settings.allowFreeQuantity = safeBool(additionalDetailData.allowFreeQuantity);
                        settings.includeReferencePrice = safeBool(additionalDetailData.includeReferencePrice);
                        settings.taxInclusivePricing = safeBool(additionalDetailData.taxInclusivePricing);
                        settings.enableBetaFeatures = safeBool(additionalDetailData.enableBetaFeatures, true);
                        settings.printItemCode = safeBool(additionalDetailData.printItemCode);
                        // settings.autoHideSideMenu = safeBool(additionalDetailData.autoHideSideMenu, true);
                        settings.enableSalesPersonModule = safeBool(additionalDetailData.enableSalesPersonModule);
                        settings.sendAutomaticSMS = safeBool(additionalDetailData.sendAutomaticSMS, true);
                        settings.enableCess = safeBool(additionalDetailData.enableCess);
                        settings.showQuantityDescription = safeBool(additionalDetailData.showQuantityDescription);
                        settings.showTotalQuantityDescription = safeBool(additionalDetailData.showTotalQuantityDescription);
                        settings.defaultCashBillCustomer = safeInt(additionalDetailData.defaultCashBillCustomer, 0);
                        settings.profitMarginPercentage = safeInt(additionalDetailData.profitMarginPercentage, 0)

                        let selectedDefaultCashBill = partyList.find(ca => ca.id === safeInt(additionalDetailData.defaultCashBillCustomer));
                        if (!isNullOrUndefined(selectedDefaultCashBill)) {
                            setSelectedParty(selectedDefaultCashBill);
                        }

                        settings.showOverrides = safeBool(additionalDetailData.showOverrides);
                        settings.deafultInvoiceCashBill = safeBool(additionalDetailData.deafultInvoiceCashBill);
                        settings.enableInvoicePrefixEdit = safeBool(additionalDetailData.enableInvoicePrefixEdit);
                        settings.printPaymentInfo = safeBool(additionalDetailData.printPaymentInfo);
                        settings.allowToChangeOrderDate = safeBool(additionalDetailData.allowToChangeOrderDate);
                        settings.printTotalPendingAmount = safeBool(additionalDetailData.printTotalPendingAmount);
                        settings.enableQuotationPrefixEdit = safeBool(additionalDetailData.enableQuotationPrefixEdit);
                        settings.enableQuotationNumberEdit = safeBool(additionalDetailData.enableQuotationNumberEdit);

                        settings.enableSalesOrderPrefixEdit = safeBool(additionalDetailData.enableSalesOrderPrefixEdit);
                        settings.enableSalesOrderNumberEdit = safeBool(additionalDetailData.enableSalesOrderNumberEdit);

                        settings.enableDeliveryChallanPrefixEdit = safeBool(additionalDetailData.enableDeliveryChallanPrefixEdit, true);
                        settings.enableDeliveryChallanNumberEdit = safeBool(additionalDetailData.enableDeliveryChallanNumberEdit, true);
                        settings.watermarkLabel = safeString(additionalDetailData.watermarkLabel);
                        settings.waterMarkAngle = safeInt(additionalDetailData.waterMarkAngle);

                        if (response.data.taxRegistrationTypeId === TAX_REG_COMPOSITION_SCHEME) {
                            settings.invoiceDocumentHeading = ifNullOrEmpty(additionalDetailData.invoiceDocumentHeading, "Bill of Supply");
                        } else {
                            settings.invoiceDocumentHeading = ifNullOrEmpty(additionalDetailData.invoiceDocumentHeading, "TAX INVOICE");
                        }

                        settings.AllowTaxRateEdit = ifNullOrEmpty(additionalDetailData.AllowTaxRateEdit, true);

                        settings.validateHSNCode = ifNullOrEmpty(additionalDetailData.validateHSNCode, false);

                        settings.hideSealAndSignature = ifNullOrEmpty(additionalDetailData.hideSealAndSignature, false);

                        settings.HSNCodeSize = ifNullOrEmpty(additionalDetailData.HSNCodeSize, "");

                        settings.quotationDocumentHeading = ifNullOrEmpty(additionalDetailData.quotationDocumentHeading, "Quotation");

                        settings.invoiceCopyHeading1 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading1, INVOICE_COPY_HEADINGS[0]);

                        settings.invoiceCopyHeading2 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading2, INVOICE_COPY_HEADINGS[1]);

                        settings.invoiceCopyHeading3 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading3, INVOICE_COPY_HEADINGS[2]);

                        settings.invoiceCopyHeading4 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading4, INVOICE_COPY_HEADINGS[3]);

                        settings.invoiceCopyHeading5 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading5, INVOICE_COPY_HEADINGS[4]);


                        if (isNullOrUndefined(additionalDetailData.includeTaxSummaryQuotation)) {
                            settings.includeTaxSummaryQuotation = true;
                        } else {
                            settings.includeTaxSummaryQuotation = safeBool(additionalDetailData.includeTaxSummaryQuotation);
                        }

                        settings.includeAmountDeliveryChallan = safeBool(additionalDetailData.includeAmountDeliveryChallan);
                        settings.includeTaxSummaryDeliveryChallan = safeBool(additionalDetailData.includeTaxSummaryDeliveryChallan);

                        settings.orderSequenceLeadingZeroes = safeInt(additionalDetailData.orderSequenceLeadingZeroes);
                        if (isNullOrUndefined(additionalDetailData.orderSequenceLeadingZeroes) && settings.orderSequenceLeadingZeroes === 0) {
                            settings.orderSequenceLeadingZeroes = 5;
                        }
                        // Invoice

                        if (!isNullOrUndefined(additionalDetailData.invoiceFormatSettings) && additionalDetailData.invoiceFormatSettings !== "") {
                            settings.invoiceFormatSettings = JSON.parse(additionalDetailData.invoiceFormatSettings);
                        } else {
                            settings.invoiceFormatSettings = null;
                        }

                        if (!isNullOrUndefined(additionalDetailData.POSinvoiceSettings) && additionalDetailData.POSinvoiceSettings !== "") {
                            settings.POSinvoiceSettings = JSON.parse(additionalDetailData.POSinvoiceSettings);
                        } else {
                            settings.POSinvoiceSettings = null;
                        }

                        if (!isNullOrUndefined(additionalDetailData.invoiceFormatColumnDefination) && additionalDetailData.invoiceFormatColumnDefination !== "") {
                            settings.invoiceFormatColumnDefination = JSON.parse(additionalDetailData.invoiceFormatColumnDefination)
                        } else {
                            settings.invoiceFormatColumnDefination = null;
                        }

                        // quotation 
                        if (!isNullOrUndefined(additionalDetailData.quotationFormatSettings) && additionalDetailData.quotationFormatSettings !== "") {
                            settings.quotationFormatSettings = JSON.parse(additionalDetailData.quotationFormatSettings);
                        } else {
                            settings.quotationFormatSettings = null;
                        }

                        if (!isNullOrUndefined(additionalDetailData?.quotationFormatColumnDefination) && additionalDetailData.quotationFormatColumnDefination !== "") {
                            settings.quotationFormatColumnDefination = JSON.parse(additionalDetailData.quotationFormatColumnDefination)
                        } else {
                            settings.quotationFormatColumnDefination = null;
                        }

                        // creditNote

                        if (!isNullOrUndefined(additionalDetailData.creditNoteFormatSettings) && additionalDetailData.creditNoteFormatSettings !== "") {
                            settings.creditNoteFormatSettings = JSON.parse(additionalDetailData.creditNoteFormatSettings);
                        } else {
                            settings.creditNoteFormatSettings = null;
                        }


                        if (!isNullOrUndefined(additionalDetailData?.creditNoteFormatColumnDefination) && additionalDetailData.creditNoteFormatColumnDefination !== "") {
                            settings.creditNoteFormatColumnDefination = JSON.parse(additionalDetailData.creditNoteFormatColumnDefination)
                        } else {
                            settings.creditNoteFormatColumnDefination = null;
                        }


                        // debit Notes 
                        if (!isNullOrUndefined(additionalDetailData.debitNoteFormatSettings) && additionalDetailData.debitNoteFormatSettings !== "") {
                            settings.debitNoteFormatSettings = JSON.parse(additionalDetailData.debitNoteFormatSettings);
                        } else {
                            settings.debitNoteFormatSettings = null;
                        }

                        if (!isNullOrUndefined(additionalDetailData?.debitNoteFormatColumnDefination) && additionalDetailData.debitNoteFormatColumnDefination !== "") {
                            settings.debitNoteFormatColumnDefination = JSON.parse(additionalDetailData.debitNoteFormatColumnDefination)
                        } else {
                            settings.debitNoteFormatColumnDefination = null;
                        }

                    }

                    setFieldValues(settings);
                }
            }

        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('GetSubscriberById request cancelled.');
            } else {
                showAlert('Failed to get settings data. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
            toggleCompanyProfileTab(false);
        }
    }

    const uploadSubscrierLogo = async (subscriberLogo) => {
        try {
            setWaiting(true);
            var apiUrl = '/Subscriber/UpdateSubscriberLogo';
            const response = await WebAPI.post(apiUrl, safeString(subscriberLogo), { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                showAlert('Logo updated successfully. Please logout and login again for the changes to be updated.', 'success');
            }
        } catch (error) {
            showAlert('Failed to upload logo. Accepted file formats: JPG, JPEG & PNG', 'error');
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const uploadSubscrierSignature = async (signatureAndSeal) => {
        try {
            setWaiting(true);
            var apiUrl = '/Subscriber/UpdateSubscriberSignatureAndSeal';
            const response = await WebAPI.post(apiUrl, safeString(signatureAndSeal), { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                showAlert('Signature updated successfully. Please logout and login again for the changes to be updated.', 'success');
            }
        } catch (error) {
            showAlert('Failed to upload logo. Accepted file formats: JPG, JPEG & PNG', 'error');
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            var apiUrl = '/Subscriber/UpdateSubscriberSettings';
            let request = { ...values };
            request.invoiceCopies = safeInt(values.invoiceCopies);
            request.quotationCopies = safeInt(values.quotationCopies);
            request.creditNoteCopies = safeInt(values.creditNoteCopies);
            request.debitNoteCopies = safeInt(values.debitNoteCopies);
            request.deliveryChallanCopies = safeInt(values.deliveryChallanCopies);
            request.invoiceSequence = safeInt(values.invoiceSequence) - 1;
            request.creditNoteSequence = safeInt(values.creditNoteSequence) - 1;
            request.debitNoteSequence = safeInt(values.debitNoteSequence) - 1;
            request.quotationSequence = safeInt(values.quotationSequence) - 1;
            request.salesOrderSequence = safeInt(values.salesOrderSequence) - 1;
            request.purchaseOrderSequence = safeInt(values.purchaseOrderSequence) - 1;
            request.deliveryChallanSequence = safeInt(values.deliveryChallanSequence) - 1;
            request.decimalPlaces = safeInt(values.decimalPlaces);
            request.allowDuplicateProductNames = safeBool(values.allowDuplicateProductNames);
            var shopifySettings = {
                stores: [
                    {
                        storeName: values.shopifyStoreName,
                        storeAPIKey: values.shopifyStoreAPIKey,
                        storeCostCenterId: safeInt(values.shopifyStoreCostCenterId)
                    }
                ]
            }
            request.shopifyStores = JSON.stringify(shopifySettings);
            var invoiceFormatColumnDefination = null
            if (!isNullOrUndefined(values.invoiceFormatColumnDefination)) {
                invoiceFormatColumnDefination = JSON.stringify(values.invoiceFormatColumnDefination);
            }

            var quotationFormatColumnDefination = null
            if (!isNullOrUndefined(values?.quotationFormatColumnDefination)) {
                quotationFormatColumnDefination = JSON.stringify(values?.quotationFormatColumnDefination);
            }

            var invoiceFormatSettings = null
            if (!isNullOrUndefined(values?.invoiceFormatSettings)) {
                invoiceFormatSettings = JSON.stringify(values.invoiceFormatSettings);
            }

            var POSinvoiceSettings = null
            if (!isNullOrUndefined(values?.POSinvoiceSettings)) {
                POSinvoiceSettings = JSON.stringify(values.POSinvoiceSettings);
            }

            var quotationFormatSettings = null
            if (!isNullOrUndefined(values?.quotationFormatSettings)) {
                quotationFormatSettings = JSON?.stringify(values.quotationFormatSettings);
            }

            var creditNoteFormatSettings = null
            if (!isNullOrUndefined(values?.creditNoteFormatSettings)) {
                creditNoteFormatSettings = JSON?.stringify(values.creditNoteFormatSettings);
            }

            var creditNoteFormatColumnDefination = null
            if (!isNullOrUndefined(values?.creditNoteFormatColumnDefination)) {
                creditNoteFormatColumnDefination = JSON.stringify(values?.creditNoteFormatColumnDefination);
            }


            var debitNoteFormatSettings = null
            if (!isNullOrUndefined(values?.debitNoteFormatSettings)) {
                debitNoteFormatSettings = JSON?.stringify(values.debitNoteFormatSettings);
            }

            var debitNoteFormatColumnDefination = null
            if (!isNullOrUndefined(values?.debitNoteFormatColumnDefination)) {
                debitNoteFormatColumnDefination = JSON.stringify(values?.debitNoteFormatColumnDefination);
            }


            const additionalDetails = {
                preventNegativeStock: safeBool(values.preventNegativeStock),
                allowFreeQuantity: safeBool(values.allowFreeQuantity),
                includeReferencePrice: safeBool(values.includeReferencePrice),
                taxInclusivePricing: safeBool(values.taxInclusivePricing),
                enableBetaFeatures: safeBool(values.enableBetaFeatures),
                printItemCode: safeBool(values.printItemCode),
                // autoHideSideMenu: safeBool(values.autoHideSideMenu),
                sendAutomaticSMS: safeBool(values.sendAutomaticSMS),
                showQuantityDescription: safeBool(values.showQuantityDescription),
                showTotalQuantityDescription: safeBool(values.showTotalQuantityDescription),
                orderSequenceLeadingZeroes: safeInt(values.orderSequenceLeadingZeroes),
                enableSalesPersonModule: safeBool(values.enableSalesPersonModule),
                enableCess: safeBool(values.enableCess),
                showOverrides: safeBool(values.showOverrides),
                includeTaxSummaryQuotation: safeBool(values.includeTaxSummaryQuotation),
                deafultInvoiceCashBill: safeBool(values.deafultInvoiceCashBill),
                enableInvoicePrefixEdit: safeBool(values.enableInvoicePrefixEdit),
                enableQuotationPrefixEdit: safeBool(values.enableQuotationPrefixEdit),
                enableQuotationNumberEdit: safeBool(values.enableQuotationNumberEdit),
                enableSalesOrderPrefixEdit: safeBool(values.enableSalesOrderPrefixEdit),
                enableSalesOrderNumberEdit: safeBool(values.enableSalesOrderNumberEdit),
                enableDeliveryChallanPrefixEdit: safeBool(values.enableDeliveryChallanPrefixEdit),
                enableDeliveryChallanNumberEdit: safeBool(values.enableDeliveryChallanNumberEdit),
                includeTaxSummaryDeliveryChallan: safeBool(values.includeTaxSummaryDeliveryChallan),
                includeAmountDeliveryChallan: safeBool(values.includeAmountDeliveryChallan),
                printPaymentInfo: safeBool(values.printPaymentInfo),
                allowToChangeOrderDate: safeBool(values.allowToChangeOrderDate),
                printTotalPendingAmount: safeBool(values.printTotalPendingAmount),
                watermarkLabel: safeString(values.watermarkLabel),
                waterMarkAngle: safeInt(values.waterMarkAngle),
                invoiceCopyHeading1: safeString(values.invoiceCopyHeading1),
                invoiceCopyHeading2: safeString(values.invoiceCopyHeading2),
                invoiceCopyHeading3: safeString(values.invoiceCopyHeading3),
                invoiceCopyHeading4: safeString(values.invoiceCopyHeading4),
                invoiceCopyHeading5: safeString(values.invoiceCopyHeading5),
                invoiceDocumentHeading: safeString(values.invoiceDocumentHeading),
                quotationDocumentHeading: safeString(values.quotationDocumentHeading),
                invoiceFormatSettings: safeString(invoiceFormatSettings),
                POSinvoiceSettings: safeString(POSinvoiceSettings),
                quotationFormatSettings: safeString(quotationFormatSettings),
                creditNoteFormatSettings: safeString(creditNoteFormatSettings),
                debitNoteFormatSettings: safeString(debitNoteFormatSettings),
                invoiceFormatColumnDefination: invoiceFormatColumnDefination,
                quotationFormatColumnDefination: quotationFormatColumnDefination,
                creditNoteFormatColumnDefination: creditNoteFormatColumnDefination,
                debitNoteFormatColumnDefination: debitNoteFormatColumnDefination,
                AllowTaxRateEdit: values.AllowTaxRateEdit,
                validateHSNCode: values.validateHSNCode,
                hideSealAndSignature: values.hideSealAndSignature,
                HSNCodeSize: values.HSNCodeSize,
                defaultCashBillCustomer: safeInt(selectedParty?.id),
                loginDisplayPage: safeString(values.loginDisplayPage),
                profitMarginPercentage: safeInt(values.profitMarginPercentage)
            }

            request.additionalDetails = JSON.stringify(additionalDetails);

            request.subscriberLogo = ""; // This is not used so update it to empty

            if (isSignatureChanged) {
                request.authorisedSignature = safeString(values.authorisedSignature);
            } else {
                request.authorisedSignature = safeString(authContext.settings.authorisedSignature);
            }

            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                // authContext.updateSettings(values);
                await reloadSubscriberInfo(authContext.currentSubscriber.id, authContext, setWaiting, showAlert);
                showAlert('Settings updated successfully.', 'success');
            }

        } catch (error) {
            showAlert("Failed to save Settings. Inernal server error occured." + error.response, "error");
        } finally {
            setWaiting(false);
            toggleCompanyProfileTab(false);
        }
    }


    const tabStylesContainer = (index) => ({
        borderRight: selectedTab === index ? '3.6px solid #4caf50' : 'transparent',
        borderTop: '1px solid #C8E6C9',
        borderBottom: selectedTab === index ? '1.2px solid #c3bfbfa1' : 'transparent',
        color: '#0b3966',
        borderRadius: '0',
        backgroundColor: selectedTab === index ? '#d2e0f0' : 'transparent',
    });

    const MobiletabStylesContainer = (index) => ({
        borderBottom: selectedTab === index ? '2px solid #4caf50' : 'transparent',
        borderTop: '1px solid #C8E6C9',
        /*  borderTop: selectedTab === index ? '1.2px solid #c3bfbfa1' : 'transparent',
         borderBottom: selectedTab === index ? '1.2px solid #c3bfbfa1' : 'transparent', */
        color: '#0b3966',
        borderRadius: '0',
        backgroundColor: selectedTab === index ? '#d2e0f0' : 'transparent',
    });

    useEffect(() => {
        const onboardingCompletedSettings = localStorage.getItem('onboardingCompletedSettings');
        if (onboardingCompletedSettings === 'true') {
            setIsTourOpen(false);
        } else {

            setSelectedTab(2);
            setTimeout(() => {
                setIsTourOpen(true);
            }, 2000);
        }
    }, [])
    const closeTour = () => {
        localStorage.setItem('onboardingCompletedSettings', 'true');
        setIsTourOpen(false);
    };


    return (
        <>
            {
                (!mobileDevice && isTourOpen) && <>
                    <Tour
                        onRequestClose={closeTour}
                        steps={onboardingSettingSteps}
                        isOpen={isTourOpen}
                        maskSpace={10}
                        /*  maskClassName="mask" */
                        className="helper"
                        rounded={10}
                        showButtons={true}
                        accentColor="#007bff"
                        lastStepNextButton={<Button variant="outlined" onClick={() => closeTour()}>Finish</Button>}
                    />
                </>
            }
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                validationSchema={yup.object({
                    invoicePrefix: yup.string().matches("[/a-zA-Z-]$", "Invoice prefix cannot end with a numeric value."),
                    quotationPrefix: yup.string().matches("[/a-zA-Z-]$", "Quotation prefix cannot end with a numeric value."),
                    purchaseOrderPrefix: yup.string().matches("[/a-zA-Z-]$", "Purchase order prefix cannot end with a numeric value."),
                    purchaseBillPrefix: yup.string().matches("[/a-zA-Z-]$", "Purchase bill prefix cannot end with a numeric value."),
                    creditNotePrefix: yup.string().matches("[/a-zA-Z-]$", "Credit note prefix cannot end with a numeric value."),
                    debitNotePrefix: yup.string().matches("[/a-zA-Z-]$", "Debit note prefix cannot end with a numeric value."),
                    deliveryChallanPrefix: yup.string().matches("[/a-zA-Z-]$", "Challan prefix cannot end with a numeric value."),
                })}
                onSubmit={
                    async (values) => {
                        await submitFormData(values);
                    }
                }
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>

                        <div>
                            <Grid container>
                                {
                                    mobileDevice && <>
                                        <Tabs
                                            style={{ background: '#DBE9FA', width: '100vw' }}
                                            orientation="horizontal"
                                            variant="scrollable"
                                            scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            value={selectedTab}
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            className={classes.SettingsTabs}
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: "transparent"
                                                }
                                            }}
                                        >
                                            <Tab style={MobiletabStylesContainer(0)} label="General settings" {...a11yProps(0)} />
                                            <Tab style={MobiletabStylesContainer(1)} label="Inventory settings" {...a11yProps(1)} />
                                            <Tab style={MobiletabStylesContainer(2)} label="Sales invoice settings" {...a11yProps(2)} />
                                            <Tab style={MobiletabStylesContainer(3)} label="Company logo" {...a11yProps(3)} />
                                            <Tab style={MobiletabStylesContainer(4)} label="Upload Signature" {...a11yProps(4)} />
                                            <Tab style={MobiletabStylesContainer(5)} label="Quotation settings" {...a11yProps(5)} />
                                            <Tab style={MobiletabStylesContainer(6)} label="Sales Order settings" {...a11yProps(6)} />
                                            <Tab style={MobiletabStylesContainer(7)} label="Delivery challan settings" {...a11yProps(7)} />
                                            <Tab style={MobiletabStylesContainer(8)} label="Credit note settings" {...a11yProps(8)} />
                                            <Tab style={MobiletabStylesContainer(9)} label="Debit note settings" {...a11yProps(9)} />
                                            <Tab style={MobiletabStylesContainer(10)} label="Purchase Order settings" {...a11yProps(10)} />
                                            <Tab style={MobiletabStylesContainer(11)} label="Purchase Bill settings" {...a11yProps(11)} />
                                            <Tab style={MobiletabStylesContainer(12)} label="Shopify Integration" {...a11yProps(12)} />
                                            <Tab style={MobiletabStylesContainer(13)} label="Email Settings" {...a11yProps(13)} />
                                        </Tabs>
                                    </>
                                }
                                {
                                    !mobileDevice && <>
                                        <Grid item xs={2} style={{ background: '#DBE9FA', minHeight: '100vh', maxWidth: 210 }}>
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={selectedTab}
                                                onChange={handleChange}
                                                className={classes.SettingsTabs}
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "transparent"
                                                    }
                                                }}
                                            >
                                                <Tab
                                                    label="General settings"
                                                    {...a11yProps(0)}
                                                    value={selectedTab}

                                                    component={() => (
                                                        <Button style={tabStylesContainer(0)} onClick={() => {
                                                            setSelectedTab(0);

                                                        }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="Settings" color="primary" /> <span className={classes.SettingTabName}>General settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Inventory settings"
                                                    {...a11yProps(1)}
                                                    value={selectedTab}

                                                    component={() => (
                                                        <Button style={tabStylesContainer(1)} onClick={() => { setSelectedTab(1); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="Inventory" color="primary" /> <span className={classes.SettingTabName}>Inventory settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Sales invoice settings"
                                                    {...a11yProps(2)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(2)} onClick={() => {
                                                            setSelectedTab(2);
                                                            setSelectedTabEntity(INVOICE_ENTITY)
                                                        }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`} >
                                                            <AppIcon name="SalesIcon" color="primary" /> <span className={`${classes.SettingTabName} OnboardingScreenSalesInvoice`}>Sales invoice settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Company logo"
                                                    {...a11yProps(3)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(3)} onClick={() => {
                                                            setSelectedTab(3);
                                                        }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="CompanyLogo" color="primary" /> <span className={classes.SettingTabName}>Company logo</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Upload Signature"
                                                    {...a11yProps(4)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(4)} onClick={() => { setSelectedTab(4); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="Seal" color="primary" /> <span className={classes.SettingTabName}>Upload Signature</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Quotation settings"
                                                    {...a11yProps(5)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(5)} onClick={() => {
                                                            setSelectedTab(5); setSelectedTabEntity(QUOTATION_ENTITY)
                                                        }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="QuotationIcon" color="primary" /> <span className={classes.SettingTabName}>Quotation settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Sales Order settings"
                                                    {...a11yProps(6)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(6)} onClick={() => { setSelectedTab(6); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="SalesOrder" color="#115293" /> <span className={classes.SettingTabName}>SalesOrder settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Delivery challan settings"
                                                    {...a11yProps(7)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(7)} onClick={() => { setSelectedTab(7); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="DeliveryChallan" color="primary" /> <span className={classes.SettingTabName}>Delivery challan settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Credit note settings"
                                                    {...a11yProps(8)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(8)} onClick={() => { setSelectedTab(8); setSelectedTabEntity(CREDIT_NOTE_ENTITY) }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="CreditNote" color="primary" /> <span className={classes.SettingTabName}>Credit note settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Debit note settings"
                                                    {...a11yProps(9)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(9)} onClick={() => {
                                                            setSelectedTab(9); setSelectedTabEntity(DEBIT_NOTE_ENTITY)
                                                        }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="DebitNote" color="primary" /> <span className={classes.SettingTabName}>Debit note settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Purchase Order settings"
                                                    {...a11yProps(10)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(10)} onClick={() => { setSelectedTab(10); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="PurchaseOrderIcon" color="primary" /> <span className={classes.SettingTabName}>Purchase Order settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Purchase Bill settings"
                                                    {...a11yProps(11)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(11)} onClick={() => { setSelectedTab(11); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="PurchaseOrderIcon" color="primary" /> <span className={classes.SettingTabName}>Purchase Bill settings</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Shopify Integration"
                                                    {...a11yProps(12)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(12)} onClick={() => { setSelectedTab(12); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}>
                                                            <AppIcon name="Shopify" color={theme.palette.primary.main} /> <span className={classes.SettingTabName}>Shopify Integration</span>
                                                        </Button>
                                                    )}
                                                />
                                                <Tab
                                                    label="Email Settings"
                                                    {...a11yProps(13)}
                                                    value={selectedTab}
                                                    component={() => (
                                                        <Button style={tabStylesContainer(13)} onClick={() => { setSelectedTab(13); }} className={`${classes.SettingsTabContainerSettings} ${classes.SettingPullLeft}`}
                                                            startIcon={<AppIcon name="Email" color={theme.palette.primary.main} />}>
                                                            <span className={classes.SettingTabName}>Email Settings</span>
                                                        </Button>
                                                    )}
                                                />
                                            </Tabs>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} md={8} xl={10} style={{ marginBottom: '50px' }}>
                                    <TabPanel value={selectedTab} index={0}>
                                        <SettingsHeader heading={"General Settings"} subHeading={"Use this section to customize ezybill as per your business preferences."} isSearchVisiable={true} />
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid item container xs={12} spacing={2}>
                                            <EBCheckBox name="enableNotifications" caption="Show notifications" infotext="When this option is checked, ezybill will show you notifications for stock warning, payment reminders etc." checked={values.enableNotifications} />
                                            <EBCheckBox name="useMaximumRetailPrice" caption="Enable MRP?" infotext="Check this option to include MRP in your sales invoices, quotations etc." checked={values.useMaximumRetailPrice} />
                                            <EBCheckBox className="OnboardingScreenPreFixedNew" name="includeReferencePrice" caption="Include PTS & PTR?" infotext="Check this option to add a new fields for Price to Stockist/Price to Retailer" checked={values.includeReferencePrice} disabled={false} />
                                            <EBCheckBox name="enableSalesPersonModule" caption="Enable sales person module?" infotext="Check this option if you want to associate a sales person for during sales invoice creation." checked={values.enableSalesPersonModule} />
                                            <EBCheckBox name="enableCess" caption="Enable CESS?" infotext="Check this option to include CESS in your sales invoices, quotations etc." checked={values.enableCess} />
                                            <EBCheckBox name="showOverrides" caption="Show default overrides?" infotext="Allow default overrides for Bank A/c, Batches to print etc during voucher creation?" checked={values.showOverrides} disabled={false} />
                                            <EBCheckBox name="taxInclusivePricing" caption="Use tax inclusive pricing?" infotext="Check this option if you always enter amounts including the tax amount in sales invoice, quotations etc." checked={values.taxInclusivePricing} disabled={false} />
                                            <EBCheckBox name="enableBetaFeatures" caption="Enable Beta Features?" infotext="Check this option to enable beta Features." checked={values.enableBetaFeatures} disabled={false} />
                                            <EBCheckBox name="printItemCode" caption="Print Item Code?" infotext="Check this option to print item code in PDF output." checked={values.printItemCode} disabled={false} />
                                            {/* <EBCheckBox name="autoHideSideMenu" caption="Auto hide Side Menu?" infotext="Check this option to auto hide side menu while creating vouchers." checked={values.autoHideSideMenu} disabled={false} /> */}
                                            <EBCheckBox name="sendAutomaticSMS" caption="Send automatic SMS?" infotext="Check this option to send  automatically send SMS for Invoice" checked={values.sendAutomaticSMS} disabled={false} />
                                            <EBCheckBox name="AllowTaxRateEdit" caption="Enable Tax Rate?" infotext="Check this option to Enable Tax Rate in order screen" checked={values.AllowTaxRateEdit} disabled={false} />
                                            <EBCheckBox name="validateHSNCode" caption="Validate HSN Code?" infotext="Check this option to validate HSN code in item screen" checked={values.validateHSNCode} disabled={false} />
                                            {
                                                values.validateHSNCode && <>
                                                    <Grid item xs={12} sm={6}>
                                                        <SelectInput label="HSN Code Size" name="HSNCodeSize" required>
                                                            {
                                                                HSNCodeSize.map((item, index) => {
                                                                    return (
                                                                        <option key={safeInt(item.code)} value={safeInt(item.code)}>{item.code}</option>
                                                                    )
                                                                })
                                                            }
                                                        </SelectInput>
                                                        <Typography style={{ fontStyle: 'italic', color: 'gray', marginTop: '10px' }} variant="caption" display="block" gutterBottom>
                                                            {values.HSNCodeSize === "4" ? "For businesses whose turnover upto 5Cr." : "For businesses whose turnover is over 5Cr. and above."}
                                                        </Typography>

                                                    </Grid>
                                                </>
                                            }
                                            <EBCheckBox name="isLoginDisplay" caption="Login display page?" infotext="Default screen to display on login." checked={values.isLoginDisplay} disabled={false} />

                                            {
                                                values.isLoginDisplay && <>
                                                    <Grid item xs={12} sm={6}>
                                                        <SelectInput label="Default screen" name="loginDisplayPage">
                                                            {
                                                                DEFAULT_SCREEN.map((item, index) => {
                                                                    return (
                                                                        <option key={safeInt(item.id)} value={safeString(item.path)}>{item.displayName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </SelectInput>
                                                    </Grid>
                                                </>
                                            }

                                            <EBCheckBox name="hideSealAndSignature" caption="Hide Customer Seal & Signature?" infotext="Check this option to Hide Customer Seal & Signature?" checked={values.hideSealAndSignature} disabled={false} />

                                            <Grid item container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextInput label="Watermark Label" name="watermarkLabel" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <NumberInput label="Profit Margin (%)" name="profitMarginPercentage" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <SelectInput label="Water Mark Position" name="waterMarkAngle">
                                                        <option value={""}></option>
                                                        <option value={"0"}>Center</option>
                                                        <option value={"-45"}>Across</option>
                                                    </SelectInput>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ fontSize: '14px', fontWeight: '600' }}>Authorised Signature</div>
                                                    <div style={{ fontSize: '12px', fontStyle: 'italic', color: 'gray' }}>Digitally capture signature and print it on your sales invoice and quotations</div>
                                                    <Button size="large" color="secondary" variant="outlined" fullWidth endIcon={<AppIcon name="Pen" />}
                                                        onClick={() => openDialog("signatureDialog")}>Capture signature ____</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={1}>
                                        <SettingsHeader heading={"Inventory Settings"} subHeading={"Use this section to customize your inventory preferences."} isSearchVisiable={true} />
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid item container xs={12} spacing={2}>
                                            <EBCheckBox name="enableInventory" caption="Enable inventory" infotext="Enable Inventory?" checked={values.enableInventory} />

                                            <EBCheckBox name="isMultiWarehouse" caption="Enable Multi-Warehouse" infotext="Enable Multi-Warehouse?" checked={values.isMultiWarehouse} disabled={!warehouseAccess.allowAdd} isAuthorised={warehouseAccess.allowAdd} />
                                            <EBCheckBox name="preventNegativeStock" caption="Prevent negative stock?" infotext="Check this option to prevent creating sales invoices when the item/product is out of stock. " checked={values.preventNegativeStock} />
                                            <EBCheckBox name="suppressStockWarnings" caption="Suppress stock warnings" infotext="Check this option if you wan to hide out of stock warning while creating invoices, quotations etc." checked={values.suppressStockWarnings} />
                                            <EBCheckBox name="allowFreeQuantity" caption="Allow free quantity?" infotext="Check this option to add a new field for Free Qty in you sales invoices, quotations etc. " checked={values.allowFreeQuantity} />
                                            <EBCheckBox name="showQuantityDescription" caption="Show quantity description?" infotext="Check this option to add a new field for Qty Description in you sales invoices, quotations etc. " checked={values.showQuantityDescription} />

                                            <EBCheckBox name="showTotalQuantityDescription" caption="Show total quantity description?" infotext="Check this option to show total Qty Description in you sales invoices, quotations etc. " checked={values.showTotalQuantityDescription} />

                                            <EBCheckBox name="allowDuplicateProductNames" caption="Allow Duplicate Product Names?" infotext="Allow Duplicate Product Names?" checked={values.allowDuplicateProductNames} disabled={false} />
                                            <EBCheckBox name="useBatchSerialNoTracking" caption="Enable batch/serial tracking?" infotext="Check this option if you want to track items by either bacth/serial numbers." checked={values.useBatchSerialNoTracking} />
                                            {
                                                values.useBatchSerialNoTracking &&
                                                <>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <SelectInput label="Batch details to print" name="includeBatchDetailsInInvoice">
                                                            <option value={"0"}>None</option>
                                                            <option value={"1"}>Print Batch no. &amp; Exp. dt</option>
                                                            <option value={"2"}>Print Batch no., Mfg. &amp; Exp. dt</option>
                                                            {/* <option value={"3"}>Print Batch no., Mfg. &amp; Expiring. in X days/months</option> */}
                                                        </SelectInput>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={2}>
                                        <SettingsHeader heading={"Sales invoice settings"} subHeading={"Use this section to set sales invoice preferences."} isSearchVisiable={true} />
                                        {/* <SalesInvoiceSettings /> */}
                                        <Divider />
                                        <Grid container spacing={2} className={classes.mt2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Invoice prefix" className="OnboardingScreenPreFixed" name="invoicePrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <NumberInput label="Invoice sequence" className="OnboardingScreenSequence" name="invoiceSequence" placeholder=""
                                                    inputProps={{ step: 1, min: 0 }} />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <NumberInput label="Invoice number length" name="invoiceVoucherLength" placeholder="" title="The invoice number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextButtonInput
                                                    label="Invoice format"
                                                    className="OnboardingScreenInvoiceFormat"
                                                    name="invoiceFormatDesc"
                                                    disabled={true}
                                                    placeholder="Select format"
                                                    buttonTooltip={'Click to choose an Invoice PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'}
                                                    handleClick={() => {
                                                        setSelectedFormatEntity(INVOICE_ENTITY);
                                                        setVoucherFormats(values.invoiceFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }}
                                                >
                                                </TextButtonInput>
                                                {
                                                    // (values.invoiceFormat !== "13" && values.invoiceFormat !== "14" && values.invoiceFormat !== "19") &&
                                                    (values.invoiceFormat !== "13" && values.invoiceFormat !== "14" && values.invoiceFormat !== "19") &&
                                                    <>
                                                        <Button
                                                            onClick={() => openDialog('voucherSettingsDialog')}
                                                            variant='text' color='primary'
                                                            startIcon={<AppIcon name="Settings" />}
                                                        >
                                                            Customize format
                                                        </Button>
                                                    </>
                                                }
                                                {
                                                    (values.invoiceFormat === "13" || values.invoiceFormat === "14" || values.invoiceFormat === "19") &&
                                                    <>
                                                        <Button
                                                            onClick={() => openDialog('POSvoucherSettingsDialog')}
                                                            variant='text' color='primary'
                                                            startIcon={<AppIcon name="Settings" />}
                                                        >
                                                            POS customize format
                                                        </Button>
                                                    </>
                                                }

                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="Print paper size" name="paperSize">
                                                    <option value={"A4"}>A4</option>
                                                    <option value={"A5"}>A5</option>
                                                    <option value={"80"}>80mm Thermal Printer</option>
                                                </SelectInput>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="No. of copies to print" name="invoiceCopies">
                                                    <option value={0}>One copy (Original for Recipient)</option>
                                                    <option value={1}>Two copies</option>
                                                    <option value={2}>Three copies</option>
                                                    <option value={3}>Four copies</option>
                                                    <option value={4}>Five copies</option>
                                                </SelectInput>
                                            </Grid>

                                            <Grid item container xs={12} spacing={1}>
                                                {INVOICE_COPY_HEADINGS.map((item, index) => (
                                                    index <= values.invoiceCopies ?
                                                        <Grid item xs={12} sm={3} key={index}>
                                                            <TextInput
                                                                label={`Copy ${index + 1} heading`}
                                                                name={`invoiceCopyHeading${index + 1}`}
                                                                placeholder=""
                                                                inputProps={{}}
                                                            />
                                                        </Grid> : null
                                                ))}
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Document Heading" name="invoiceDocumentHeading" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Greeting message" name="invoiceGreetingMessage" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>

                                            <EBCheckBox name="enableInvoicePrefixEdit" caption="Allow invoice prefix edit?" infotext="Allow invoice prefix edit?" checked={values.enableInvoicePrefixEdit} />

                                            <EBCheckBox name="enableInvoiceNumberEdit" checked={values.enableInvoiceNumberEdit} caption="Allow invoice sequence edit?" infotext="Allow invoice sequence edit?" />

                                            <EBCheckBox name="roundOffInvoiceTotal" checked={values.roundOffInvoiceTotal} caption="Round-off invoice total?" infotext="Round-off invoice total?" />

                                            <EBCheckBox name="useLastInvoicePrice" checked={values.useLastInvoicePrice} caption="Remember last sale price for Customer?" infotext="Remember last sale price for Customer?" />

                                            <EBCheckBox name="deafultInvoiceCashBill" checked={values.deafultInvoiceCashBill} caption="Default to cash bill?"
                                                infotext="Check this option to set sales invoce to cash by default." />
                                            {
                                                values.deafultInvoiceCashBill &&
                                                <Grid item xs={12} sm={6}>
                                                    <SelectCustomer customers={parties} selectedCustomer={selectedParty} isRequired={false}
                                                        handleSelectionChanged={
                                                            (e, val) => {
                                                                if (typeof val === 'string') {
                                                                    // timeout to avoid instant validation of the dialog's form.
                                                                    setTimeout(() => {
                                                                        openDialog('customerDialog');
                                                                    });
                                                                } else if (val && val?.inputValue) {
                                                                    openDialog('customerDialog');
                                                                } else {
                                                                    handlePartySelectionChange(val);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </Grid>
                                            }


                                            <EBCheckBox name="printPaymentInfo" checked={values.printPaymentInfo} caption="Print payment info?"
                                                infotext="Check this option to print amount received and amount due in the invoice PDF print." />

                                            <EBCheckBox name="printTotalPendingAmount" checked={values.printTotalPendingAmount} caption="Print total pending amount?"
                                                infotext="Check this option to print total pending amount in the invoice PDF print." />

                                            <EBCheckBox name="allowToChangeOrderDate" checked={values.allowToChangeOrderDate} caption="Allow invoice date change?"
                                                infotext="Check this option to always Lock the Invoice Date to current date." />

                                            <TextInput className="OnboardingScreenTermsAndCondition" label="Terms &amp; Conditions" name="invoiceTermsAndConditions" placeholder=""
                                                multiline minRows={3} inputProps={{ className: `${classes.textarea} ${classes.mt1}` }} />

                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={3}>
                                        <SettingsHeader heading={"Company logo"} subHeading={"Upload your company logo here "} isSearchVisiable={true} />
                                        {/* <CompanyLogo /> */}
                                        <Divider style={{ marginBottom: "20px" }} />

                                        <ImageUploadController
                                            values={values}
                                            heading={"Company Logo"}
                                            setFieldValue={setFieldValue}
                                            buttonCaption={"Choose To Upload Company Logo"}
                                            fieldName={"subscriberLogo"}
                                            fieldNamePreview={"subscriberLogoPreview"}
                                            handleUploadImage={uploadSubscrierLogo}
                                            isFlage={false}
                                            buttonTxt={"Upload Logo"}
                                            altTxt={"Your logo"}
                                        />
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={4}>
                                        <SettingsHeader heading={"Upload Signature"} subHeading={"Manage Your Signature Here"} isSearchVisiable={true} />
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <ImageUploadController
                                            values={values}
                                            heading={"Upload Signature"}
                                            setFieldValue={setFieldValue}
                                            buttonCaption={"Choose To Upload Signature"}
                                            fieldName={"signatureAndSeal"}
                                            fieldNamePreview={"signatureAndSealPreview"}
                                            handleUploadImage={uploadSubscrierSignature}
                                            isFlage={true}
                                            buttonTxt={"Upload Signature"}
                                            altTxt={"Your signature"}
                                        />
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={5}>
                                        <SettingsHeader heading={"Quotation settings"} subHeading={"Manage Quotation settings Here "} isSearchVisiable={true} />
                                        {/* <QuotationPrefixSetting /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Quotation prefix" name="quotationPrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Quotation sequence" name="quotationSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Quotation number length" name="quotationVoucherLength" placeholder="" title="The Quotation number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextButtonInput label="Quotation format" name="quotationFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a quotation PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(QUOTATION_ENTITY);
                                                        setVoucherFormats(values.quotationFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }
                                                    }
                                                />
                                                {
                                                    (values.quotationFormat === "22") &&
                                                    <>
                                                        <Button
                                                            onClick={() => openDialog('voucherSettingsDialog')}
                                                            variant='text' color='primary'
                                                            startIcon={<AppIcon name="Settings"
                                                            />}
                                                        >Customize format</Button>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Greeting message" name="quotationGreetingMessage" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Document Heading" name="quotationDocumentHeading" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="No. of copies to print" name="quotationCopies">
                                                    <option value={0}>One copy (Original for Recipient)</option>
                                                    <option value={1}>Two copies</option>
                                                    <option value={2}>Three copies</option>
                                                    <option value={3}>Four copies</option>
                                                    <option value={4}>Five copies</option>
                                                </SelectInput>
                                            </Grid>
                                            <EBCheckBox name="useLastQuotationPrice" caption="Remember last sale price for Customer" infotext="Remember last sale price for Customer" size="small" checked={values.useLastQuotationPrice} />

                                            <EBCheckBox name="enableQuotationPrefixEdit" caption="Allow quotation prefix edit?" infotext="Allow quotation prefix edit?" checked={values.enableQuotationPrefixEdit} />

                                            <EBCheckBox name="enableQuotationNumberEdit" checked={values.enableQuotationNumberEdit} caption="Allow quotation sequence edit?" infotext="Allow quotation sequence edit?" />

                                            <EBCheckBox name="roundOffQuotationTotal" caption="Round-off Quotation total?" infotext="Round-off Quotation total?" size="small" checked={values.roundOffQuotationTotal} />

                                            <EBCheckBox name="includeTaxSummaryQuotation" caption="Print tax summary?" infotext="Print tax summary?" size="small" checked={values.includeTaxSummaryQuotation} />

                                            <Grid item xs={12}>
                                                <TextInput label="Terms &amp; Conditions" name="quotationTermsAndConditions" placeholder=""
                                                    multiline minRows={3} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={6}>
                                        <SettingsHeader heading={"SalesOrder settings"} subHeading={"Manage SalesOrder settings Here "} isSearchVisiable={true} />
                                        {/*   <SalesOrderSettings /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                {/* <SelectInput label="Purchase order format" name="purchaseOrderFormat">
                                                        <option value={1}>Classic</option>
                                                        <option value={2}>Classic (With logo)</option>
                                                    </SelectInput> */}
                                                <TextButtonInput label="Sales Order format" name="salesOrderDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Sales Order PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(SALES_ORDER_ENTITY);
                                                        setVoucherFormats(values.salesOrderFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Sales Order prefix" name="salesOrderPrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Sales Order sequence" name="salesOrderSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="sales Order number length" name="salesOrderVoucherLength" placeholder="" title="The sales Order  number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>

                                            <EBCheckBox name="enableSalesOrderPrefixEdit" caption="Allow sales order prefix edit?" infotext="Allow sales order prefix edit?" checked={values.enableSalesOrderPrefixEdit} />

                                            <EBCheckBox name="enableSalesOrderNumberEdit" checked={values.enableSalesOrderNumberEdit} caption="Allow sales order sequence edit?" infotext="Allow sales order sequence edit?" />


                                            <EBCheckBox name="roundOffSalesOrderTotal" caption="Round-off SalesOrder total?"
                                                checked={values.roundOffSalesOrderTotal} infotext="Round-off SalesOrder total?" />
                                            <EBCheckBox name="useLastSalesOrderPrice" caption="Remember last sale price for Customer" checked={values.useLastSalesOrderPrice} />
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={7}>
                                        <SettingsHeader heading={"Delivery challan settings"} subHeading={"Manage Delivery challan settings Here"} isSearchVisiable={true} />
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Challan prefix" name="deliveryChallanPrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Challan sequence" name="deliveryChallanSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="delivery Challan number length" name="deliveryChallanVoucherLength" placeholder="" title="The delivery Challan  number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextButtonInput label="Delivery Challan format" name="deliveryChallanFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Delivery Challan PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(DELIVERY_CHALLAN_ENTITY);
                                                        setVoucherFormats(values.deliveryChallanFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="No. of copies to print" name="deliveryChallanCopies">
                                                    <option value={0}>One copy</option>
                                                    <option value={1}>Two copies</option>
                                                    <option value={2}>Three copies</option>
                                                    <option value={3}>Four copies</option>
                                                    <option value={4}>Five copies</option>
                                                </SelectInput>
                                            </Grid>

                                            <EBCheckBox name="enableDeliveryChallanPrefixEdit" caption="Allow prefix edit?" infotext="Allow prefix edit?"
                                                checked={values.enableDeliveryChallanPrefixEdit} />
                                            <EBCheckBox name="enableDeliveryChallanNumberEdit" caption="Allow sequence edit?" infotext="Allow sequence edit?"
                                                checked={values.enableDeliveryChallanNumberEdit} />
                                            <EBCheckBox name="roundOffDeliveryChallanTotal" caption="Round-off Delivery Challan total?" infotext="Round-off Delivery Challan total?" size="small" checked={values.roundOffDeliveryChallanTotal} />
                                            <EBCheckBox name="includeAmountDeliveryChallan" caption="Print amounts?" infotext="Check this option to print item amounts and totals in PDF output." size="small" checked={values.includeAmountDeliveryChallan} />
                                            <EBCheckBox name="includeTaxSummaryDeliveryChallan" caption="Print Tax summary?" infotext="Check this option to print Tax summary in the PDF output." size="small" checked={values.includeTaxSummaryDeliveryChallan} />
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={selectedTab} index={8}>
                                        <SettingsHeader heading={"Credit note settings"} subHeading={"Manage Credit note settings Here"} isSearchVisiable={true} />
                                        {/* <CreditNotes /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Credit note prefix" name="creditNotePrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Credit note sequence" name="creditNoteSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="credit Note number length" name="creditNoteVoucherLength" placeholder="" title="The credit Note number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextButtonInput label="Credit Notes format" name="creditNoteFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Credit Notes PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'}
                                                    handleClick={() => {
                                                        setSelectedFormatEntity(CREDIT_NOTE_ENTITY);
                                                        setVoucherFormats(values.creditNoteFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }}
                                                />
                                                {
                                                    (values.creditNoteFormat == "22") &&
                                                    <>
                                                        <Button
                                                            onClick={() => openDialog('voucherSettingsDialog')}
                                                            variant='text' color='primary'
                                                            startIcon={<AppIcon name="Settings"
                                                            />}
                                                        >Customize format</Button>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="No. of copies to print" name="creditNoteCopies">
                                                    <option value={0}>One copy (Original for Recipient)</option>
                                                    <option value={1}>Two copies</option>
                                                    <option value={2}>Three copies</option>
                                                    <option value={3}>Four copies</option>
                                                    <option value={4}>Five copies</option>
                                                </SelectInput>
                                            </Grid>
                                            <EBCheckBox name="roundOffCreditNoteTotal" caption="Round-off CreditNote total?"
                                                infotext="Round-off CreditNote total" checked={values.roundOffCreditNoteTotal} />
                                            <Grid item xs={12}>
                                                <TextInput label="Terms &amp; Conditions" name="creditNoteTermsAndConditions" placeholder=""
                                                    multiline minRows={3} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={9}>
                                        <SettingsHeader heading={"Debit note settings"} subHeading={"Manage Debit note settings Here"} isSearchVisiable={true} />
                                        {/*  <DeditNotes /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Debit note prefix" name="debitNotePrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Debit note sequence" name="debitNoteSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="debit Note number length" name="debitNoteVoucherLength" placeholder="" title="The debit Note number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextButtonInput label="Debit Notes format" name="debitNoteFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Debit Notes PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(DEBIT_NOTE_ENTITY);
                                                        setVoucherFormats(values.debitNoteFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }}
                                                />
                                                {
                                                    (values.debitNoteFormat == "22") &&
                                                    <>
                                                        <Button
                                                            onClick={() => openDialog('voucherSettingsDialog')}
                                                            variant='text' color='primary'
                                                            startIcon={<AppIcon name="Settings"
                                                            />}
                                                        >Customize format</Button>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput label="No. of copies to print" name="debitNoteCopies">
                                                    <option value={0}>One copy (Original for Recipient)</option>
                                                    <option value={1}>Two copies</option>
                                                    <option value={2}>Three copies</option>
                                                    <option value={3}>Four copies</option>
                                                    <option value={4}>Five copies</option>
                                                </SelectInput>
                                            </Grid>
                                            <EBCheckBox name="roundOffDebitNoteTotal" caption="Round-off DebitNote total?" infotext="Round-off DebitNote total?" size="small" checked={values.roundOffDebitNoteTotal} />
                                            <Grid item xs={12}>
                                                <TextInput label="Terms &amp; Conditions" name="debitNoteTermsAndConditions" placeholder=""
                                                    multiline minRows={3} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={10}>
                                        <SettingsHeader heading={"Purchase Order settings"} subHeading={"Manage Purchase settings here"} isSearchVisiable={true} />
                                        {/* <PurchaseOrderSettings /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Purchase order prefix" name="purchaseOrderPrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Purchase bill sequence" name="purchaseBillSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Purchase Order number length" name="purchaseOrderVoucherLength" placeholder="" title="The purchase Order number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {/* <SelectInput label="Purchase order format" name="purchaseOrderFormat">
                                                        <option value={1}>Classic</option>
                                                        <option value={2}>Classic (With logo)</option>
                                                    </SelectInput> */}
                                                <TextButtonInput label="Purchase order format" name="purchaseOrderFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Purchase Order PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(PURCHASE_ORDER_ENTITY);
                                                        setVoucherFormats(values.purchaseOrderFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Greeting message" name="purchaseOrderGreetingMessage" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>

                                            <EBCheckBox name="useLastPurchaseBillPrice" caption="Remember last buy price from Supllier?" infotext="Remember last buy price from Supllier?" checked={values.useLastPurchaseBillPrice} />


                                            <EBCheckBox name="roundOffPurchaseBillTotal" caption="Round-off Purchase Bill total?" infotext="Round-off PurchaseBill total?"
                                                checked={values.roundOffPurchaseBillTotal} />

                                            <Grid item xs={12}>
                                                <TextInput label="Terms &amp; Conditions" name="purchaseOrderTermsAndConditions" placeholder=""
                                                    multiline minRows={3} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={11}>
                                        <SettingsHeader heading={"Purchase Bill settings"} subHeading={"Manage Purchase settings here"} isSearchVisiable={true} />
                                        {/* <PurchaseOrderSettings /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Purchase Bill prefix" name="purchaseBillPrefix" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Purchase Bill sequence" name="purchaseBillSequence" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <NumberInput label="Purchase Bill number length" name="purchaseBillVoucherLength" placeholder="" title="The purchase Bill number will be padded with leading 0's to make it of the specified size. Use 0 to not pad leading zeros. Default is 5!"
                                                    inputProps={{ step: 1, min: 0, max: 9 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {/* <SelectInput label="Purchase order format" name="purchaseOrderFormat">
                                                        <option value={1}>Classic</option>
                                                        <option value={2}>Classic (With logo)</option>
                                                    </SelectInput> */}
                                                <TextButtonInput label="Purchase Bill format" name="purchaseBillFormatDesc" disabled={true} placeholder="Select format"
                                                    buttonTooltip={'Click to choose a Purchase Order PDF format.'}
                                                    buttonCaption="Select Format"
                                                    buttonColor="secondary"
                                                    buttonVariant='contained'
                                                    buttonIconName={'PDF'} handleClick={() => {
                                                        setSelectedFormatEntity(PURCHASE_BILL_ENTITY);
                                                        setVoucherFormats(values.purchaseBillFormat);
                                                        openDialog('pdfPreviewDialog');
                                                    }
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Greeting message" name="purchaseBillGreetingMessage" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>

                                            <EBCheckBox name="useLastPurchaseBillPrice" caption="Remember last buy price from Supllier?" infotext="Remember last buy price from Supllier?" checked={values.useLastPurchaseBillPrice} />


                                            <EBCheckBox name="roundOffPurchaseBillTotal" caption="Round-off Purchase Bill total?" infotext="Round-off PurchaseBill total?"
                                                checked={values.roundOffPurchaseBillTotal} />

                                            <Grid item xs={12}>
                                                <TextInput label="Terms &amp; Conditions" name="purchaseBillTermsAndConditions" placeholder=""
                                                    multiline minRows={3} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={12}>
                                        <SettingsHeader heading={"Shopify Integration"} subHeading={"Manage Shopify Integration settings Here"} isSearchVisiable={true} />
                                        {/* <ShopifyItntegration /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2} alignContent="center" alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="Store name" name="shopifyStoreName" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {
                                                    mobileDevice ? <MobilePasswordInput label="API Key" name="shopifyStoreAPIKey" placeholder=""
                                                        inputProps={{}} /> : <PasswordInput label="API Key" name="shopifyStoreAPIKey" placeholder=""
                                                            inputProps={{}} />
                                                }

                                            </Grid>
                                            <Grid container item xs={12} sm={6}>
                                                <Grid item xs={10} sm={10}>
                                                    <Autocomplete
                                                        filterOptions={filterOptions}
                                                        size="small"
                                                        name="shopifyStoreCostCenterId"
                                                        options={customerGroupList}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option) => option.id}
                                                        value={selectedCostCenter}
                                                        onChange={(e, value) => {
                                                            if (!isNullOrUndefined(value) && safeInt(value.id) > 0) {
                                                                setSelectedCostCenter(value);
                                                                setFieldValue('shopifyStoreCostCenterId', value.id);
                                                                setFieldValue('shopifyStoreCostCenterName', value.name);
                                                            } else {
                                                                setSelectedCostCenter(null);
                                                                setFieldValue('shopifyStoreCostCenterId', -1);
                                                                setFieldValue('shopifyStoreCostCenterName', "");
                                                            }
                                                        }}
                                                        renderInput={(props) =>
                                                            <TextInput {...props} name="shopifyStoreCostCenterName" label="Customer group"
                                                                className={classes.inputGroupField} variant="outlined" />
                                                        }
                                                    >
                                                    </Autocomplete>
                                                </Grid>
                                                <Grid item container xs={2} sm={2} className={`${classes.inputGroup} ${props.isModal ? classes.hidden : null}`} alignContent="center" justifyContent="center" alignItems="center">
                                                    <Grid item container xs={2} sm={2} alignContent="center" justifyContent="center" alignItems="center">
                                                        <Grid item>
                                                            <Tooltip title="Add new customer group">
                                                                <IconButton onClick={(e) => openDialog('customerGroupDialog')} className={classes.mr1}>
                                                                    <AppIcon name="Add" color="primary" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={13}>
                                        <SettingsHeader heading={"Email Settings"} subHeading={"Manage Email settings here"} isSearchVisiable={true} />
                                        {/* <PurchaseOrderSettings /> */}
                                        <Divider style={{ marginBottom: '20px' }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="SMTP host" name="smtpHostName" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextInput label="SMTP port number" name="smtpPortNumber" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EmailInput label="SMTP email address" name="smtpEmailAddress" placeholder=""
                                                    inputProps={{}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {
                                                    mobileDevice ? <MobilePasswordInput label="Password" name="smtpPassword" placeholder=""
                                                        inputProps={{}} /> : <PasswordInput label="Password" name="smtpPassword" placeholder=""
                                                            inputProps={{}} />
                                                }

                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    {

                                        mobileDevice ?
                                            <Grid item container xs={12} sm={12} style={{ padding: '15px' }}>
                                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary"
                                                    startIcon={<AppIcon name="Save" size="small" />}
                                                >Save settings</Button>
                                                <Button variant="outlined" color="primary" className={classes.ml2} onClick={() => toggleSettingsDrawer(false)}>Close</Button>
                                            </Grid>
                                            : <span className={`${classes.mt2} ${classes.submitBar} `}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Button className='onBoardingSave' type="submit" disabled={isSubmitting} variant="contained" color="primary"
                                                            startIcon={<AppIcon name="Save" size="small" />}
                                                        >Save settings</Button>
                                                        <Button variant="outlined" color="primary" className={classes.ml1}
                                                            onClick={() => toggleSettingsDrawer(false)}>Close </Button>
                                                    </Grid>
                                                </Grid>
                                            </span>
                                    }
                                </Grid>

                            </Grid>
                            <DialogWindow name="customerGroupDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('customerGroupDialog', setFieldValue)}
                                width="md" component={<CustomerGroup isModal={true} />} />

                            <DialogWindow name="customerDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('customerDialog', setFieldValue, values)}
                                width="md" component={<Customer isModal={true} />} />

                            <DialogWindow name="pdfPreviewDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('pdfPreviewDialog', setFieldValue)}
                                width="sm" component={<PdfPreview isModal={true} selectedFormat={voucherFormats} entityType={selectedFormatEntity} />} />
                            <DialogWindow name="signatureDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('signatureDialog', setFieldValue)}
                                width="sm" component={<SignatureCapture authorisedSignature={values.authorisedSignature} />} />

                            <DialogWindow name="voucherSettingsDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('voucherSettingsDialog', setFieldValue)}
                                width="md" component={<VoucherSettings entityType={selectedTabEntity} selectedFormatValue={values} />} />

                            <DialogWindow name="POSvoucherSettingsDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('POSvoucherSettingsDialog', setFieldValue)}
                                width="xs" component={<POSvoucherSettings entityType={selectedTabEntity} />} />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
};
